import {
  Colors,
  TextStyles,
} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {FormContext} from 'components/form/FormContext';
import {FormInputLabel} from 'components/form/FormInputLabel';
import {FormInputTooltip} from 'components/form/FormInputTooltip';
import {Text} from 'components/general';
import PropTypes from 'prop-types';
import React from 'react';

export const RadioButton = (props) => {

  const formContext = React.useContext(FormContext);

  const onClick = () => {

    if (props.disabled) {
      return;
    }

    if (props.onChange) {

      props.onChange(props.name);
      return;
    }

    formContext.setState((prev) => {

      return {
        ...prev,
        [props.name]: !checked,
      };
    });
  };

  const checked = props.value ?? formContext.state?.[props.name];

  React.useEffect(() => {

    if (formContext.state[props.name] === undefined) {

      if (props.preventDefault || !props.name) {
        return;
      }

      formContext.setState((prev) => ({
        ...prev,
        [props.name]: props.default ?? false,
      }));
    }
  }, [
    formContext.state[props.name],
    props.default,
    props.name,
  ]);

  return (
    <Container
      css={`
        ${props.css};
      `}
    >
      <HorizontalContainer
        css={`
          position: relative;
          align-items: center;
        `}
      >

        <Container
          onClick={onClick}
          css={`
            width: 12px;
            height: 12px;
            margin-bottom: 2px;
            border-radius: ${props.borderRadius ?? '50%'};
            background-color: ${checked ? (props.disabled ? Colors.TRANSPARENT.GREEN.LIME : Colors.GREEN.LIME) : Colors.TRANSPARENT.TRANSPARENT};
            border: 3px solid ${checked ? Colors.GREEN.DARK : Colors.TRANSPARENT.TRANSPARENT};
            outline: 2px solid ${props.disabled ? Colors.GREY.LIGHT : Colors.GREEN.LIME};
            transition: filter 100ms;

            ${!props.disabled ? `
              &:hover {
                filter: brightness(110%) contrast(110%);
              }
            ` : 'cursor: default;'}
          `}
        />

        <FormInputLabel
          text={props.label}
          optionalText={props.required === false}
          css={`
            margin-left: 12px;
            margin-right: ${props.tooltip ? '12px' : '0'};
            ${TextStyles.BODY}; // Re-enable 'clamp()' property that gets disabled in 'FormInputLabel' component

            ${props.labelCss}
          `}
        />

        {props.tooltip &&
          <FormInputTooltip
            text={props.tooltip}
            css={`
              margin-left: 0;
              margin-top: -4px;
            `}
          />
        }

      </HorizontalContainer>

      {props.errorText &&
        <Text
          text={props.errorText}
          css={`
            color: ${Colors.RED};
            margin: 6px 0 0 4px;
          `}
        />
      }

      {props.hint &&
        <Text
          text={props.hint}
          css={`
            color: ${Colors.GREY.LIGHT};
            margin: 6px 0 0 4px;
          `}
        />
      }

    </Container>
  );
};

RadioButton.displayName = 'RadioButton';

RadioButton.propTypes = {
  borderRadius: PropTypes.string,
  css: PropTypes.string,
  default: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hint: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelCss: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  optionalText: PropTypes.string,
  preventDefault: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};
