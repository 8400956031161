import React from 'react';
export const FormContext = React.createContext({});

export const FormProvider = (props) => {

  const [state, setState] = React.useState({});

  const clear = () => {
    setState({});
  };

  return (
    <FormContext.Provider
      displayName={'Form Context'}
      value={{
        state,
        setState,
        clear,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};
