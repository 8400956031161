import {
  Colors,
  TextStyles,
} from 'appConstants';
import {Container} from 'components/containers';
import {FullScreenLoader} from 'components/general/FullScreenLoader';
import {Text} from 'components/general/Text';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Table_ = styled.table`
  border: ${Colors.GREEN.LIGHT} 2px solid;
  border-spacing: 0;
  table-layout: fixed;

  ${(props)=> props.css};
`;

const TableRow=styled.tr`
  ${(props)=> props.css};

  ${(props) => props.onClick && `
    cursor: pointer;
    &:hover {
      background: ${Colors.TRANSPARENT.GREEN.LIGHT};
    }
  `}
`;

const TableHeader = styled.th`
  border: ${Colors.GREEN.LIGHT} 1px solid;

  ${(props)=> props.css};
`;

const TableItem = styled.td`
  padding: 4px 4px 4px 8px;
  border: ${Colors.GREEN.LIGHT} 0.25px solid;
  word-wrap:break-word;

  ${(props)=> props.css};
`;

export const Table = (props) => {

  const [loading, setLoading] = React.useState(false);

  const headings = [];
  const rows = [];

  for(const item of (props.headings ?? [])) {

    headings.push(
      <TableHeader key={`${item}`}>
        <Text
          text={item}
          css={`
            font-weight: 450;
            word-wrap: break-word;
          `}
        />
      </TableHeader>,
    );
  }

  rows.push(
    <thead key={'headings'}>
      <TableRow key={'headings'}>
        {headings}
      </TableRow>
    </thead>,
  );

  const allRows = [];

  for (const row of (props.rows ?? [])) {

    const rowBuild = [];
    const pos = allRows.length;
    for (let i = 0;  i < row.length; i++) {

      const item = row[i];
      let text, css;
      if (item && typeof item === 'object') {

        text = item.text;
        css = item.css;
      }

      rowBuild.push(
        <TableItem
          key={`${props.tableName}-item-${pos}-${i}`}
          css={css}
        >
          <Text
            css={`
              font-weight: 350;
            `}
            text={text ?? item}
          />
        </TableItem>,
      );
    }

    const onClick = props.onRowClick && (() => {

      setLoading(true);

      const onClickRes = props.onRowClick
        ? props.onRowClick(pos)
        : undefined;

      if (typeof onClickRes === 'object' && typeof onClickRes.then === 'function') {

        onClickRes.finally(() => {
          setLoading(false);
        });

        return;
      }

      setLoading(false);
    });

    allRows.push(
      <TableRow
        key={`${props.tableName}-row-${pos}`}
        onClick={onClick}
      >
        {rowBuild}
      </TableRow>,
    );
  }
  rows.push(<tbody key={'rows'}>{allRows}</tbody>);

  return(
    <Container
      css={`
        overflow-x: auto;
        ${props.css}
      `}
    >

      {loading &&
        <FullScreenLoader />
      }

      {props.title &&
        <Text
          text={props.title}
          css={TextStyles.SMALL_HEADING}
        />
      }

      {props.subTitle &&
        <Text
          text={props.subTitle}
          css={'padding-bottom: 4px;'}
        />
      }

      <Table_
        css={props.tableCss}
      >
        {rows}
      </Table_>

    </Container>
  );
};

Table.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]))),
  subTitle: PropTypes.string,
  tableCss: PropTypes.string,
  tableName: PropTypes.string,
  title: PropTypes.string,
  onRowClick: PropTypes.func,
};

Table.defaultProps = {tableName: 'table'};
