import {
  Colors,
  ScreenSize,
} from 'appConstants';
import {Container} from 'components/containers';
import {Text} from 'components/general';
import {ModalContext} from 'components/modal/ModalContext';
import {ScreenContext} from 'context';
import React from 'react';

export const Modal = () => {

  const modalContext = React.useContext(ModalContext);
  const {currentView} = React.useContext(ScreenContext);
  const isMobile = currentView === ScreenSize.MOBILE;

  const closeModal = () => {

    modalContext.state.onClose && modalContext.state.onClose();
    modalContext.close();
  };

  if (!modalContext.state) {
    return undefined;
  }

  let content = modalContext.state;

  if (!React.isValidElement(content)) {
    content = content.content;
  }

  return (
    <Container
      onClick={(e) =>{
        e.stopPropagation();
      }}
      css={`
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background: ${Colors.TRANSPARENT.DARK};
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >

      <Container
        css={`
          position: relative;
          left: 0;
          top: 0;
          max-width: ${isMobile ? '96vw' : '80vw'};
          max-height: ${isMobile ? '90vh' : '80vh'};
          overflow-y: scroll;
          background: ${Colors.GREEN.DARK};
          padding: 8px;
          border-radius: 10px;
            
          ::-webkit-scrollbar {
            background: transparent;
          }
        `}
      >
        {content.hideClose !== true &&
          <Container
            css={`
            align-items: flex-end;
            position: sticky;
            left: 0;
            top: 0;
          `}
          >

            <Container
              css={`
              cursor: pointer;
              background: ${Colors.GREEN.LIGHT};
              width: 25px;
              height: 25px;
              border-radius: 50%;
              line-height: 20px;
              text-align: center;
              border: 1px solid ${Colors.GREEN.DARK};
              font-size: 20px;
              align-items: center;
              justify-content: center;
              position: absolute;
              margin: 8px;
            `}
              onClick={closeModal}
            >
              <Text
                css={`
              color: ${Colors.GREEN.DARK};
              padding: 0;
              margin: 0;
            `}
                text={'x'}
              />
            </Container>

          </Container>}

        <Container
          css={'padding-top: 32px'}
        >

          {content}
        </Container>

      </Container>

    </Container>
  );
};