import React from 'react';

export const useInsideClick = (ref, callback) => {

  React.useEffect(() => {

    const handleClick = (e) => {

      if (ref.current && ref.current.contains(e.target)) {

        callback({
          ...e,
          inside: true,
        });
      }
    };

    document.addEventListener('mouseup', handleClick);

    return () => {
      document.removeEventListener('mouseup', handleClick);
    };
  }, [ref, callback]);
};