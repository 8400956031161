import {
  Colors,
  makeTransparent,
} from 'appConstants';
import {Container} from 'components/containers';
import {FormContext} from 'components/form/FormContext';
import {TextInput} from 'components/form/TextInput';
import {Text} from 'components/general';
import PropTypes from 'prop-types';
import React from 'react';

export const DropDown = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [filterText, setFilterText] = React.useState(null);

  const optionsContainerRef = React.useRef(null);

  const formContext = React.useContext(FormContext);

  const handleSelectValue = (value) => {

    setIsOpen(false);
    optionsContainerRef.current.scrollTop = 0;

    if (props.onChange) {

      props.onChange({
        name: props.name,
        value,
      });
      return;
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: value,
    }));
  };

  const handleOpenDropdown = () => {

    setIsOpen(true);
    setFilterText('');
  };

  const handleCloseDropdown = ()  => {

    if (!optionsContainerRef.current) {
      return;
    }

    if (dropdownOptions.length === 1) {
      handleSelectValue(firstDropDownOption.value);
    }

    setIsOpen(false);
    setFilterText(null);
    optionsContainerRef.current.scrollTop = 0;
  };

  const handleFilterOptions = (input) => {

    setFilterText(input.value ?? '');
    optionsContainerRef.current.scrollTop = 0;
  };

  const dropdownOptions = [];
  let displayText = '';
  let firstDropDownOption;
  const currentValue = props.value ?? formContext.state[props.name];

  // Create dropdown options
  for (const option of props.options) {

    if (dropdownOptions.length === 0) {
      firstDropDownOption = option;
    }

    if (currentValue === option.value) {

      displayText = option.displayText ?? option.label;
    }

    if (filterText && !(option.label.toLowerCase().includes(filterText.toLowerCase()) || String(option.value).toLowerCase().includes(filterText.toLowerCase()))) {

      continue;
    }

    const isSelected = (props.value ?? formContext.state[props.name]) === option.value;

    dropdownOptions.push(
      <Container
        onMouseDown={() => {

          handleSelectValue(option.value);
          setFilterText(null);
        }}
        css={`
          padding: 10px 12px;
          border-bottom: 1px solid ${Colors.TRANSPARENT.WHITE};
          margin: 0;
          cursor: pointer;
          background-color: ${isSelected ? makeTransparent(Colors.GREEN.LIGHT, 0.2) : 'transparent'};

          &:hover {
            background-color: ${makeTransparent(Colors.GREEN.LIGHT, 0.1)};
          }

          &:last-of-type {
            border-bottom: none;
          }
        `}
        key={option.key ?? option.value}
      >
        <Text
          text={option.label}
          key={option.key ?? option.value}
        />
      </Container>,
    );
  }

  // Handle default values
  React.useEffect(() => {

    // If a value has already been set
    if (currentValue) {

      return;
    }

    // If a dynamic default has been set
    if (props.default) {

      formContext.setState((prev) => ({
        ...prev,
        [props.name]: props.default,
      }));
      return;
    }

    // Check whether an option in 'props.options' has been set as default
    for (const option of (props.options ?? [])) {

      if (option.default) {

        formContext.setState((prev) => ({
          ...prev,
          [props.name]: option.value,
        }));
        return;
      }
    }
  }, [props.default]);

  return (
    <Container
      css={`
        position: relative;
        ${props.css}
      `}
    >

      <TextInput
        value={filterText ?? displayText}
        label={props.label}
        placeholder={props.placeholder ?? 'Please select'}
        hint={props.hint}
        errorText={props.errorText}
        tooltip={props.tooltip}
        disabled={props.disabled}
        inputContainerCss={`
          ${isOpen ? 'border-bottom-left-radius: 0;' : ''}
          ${props.inputContainerCss}
        `}
        onChange={handleFilterOptions}
        onFocus={handleOpenDropdown}
        onBlur={handleCloseDropdown}
        required={props.required}
        suffixImage={'ArrowDown'}
        suffixImageProps={{
          height: '16px',
          width: '16px',
          alt: 'Dropdown arrow',
          css: `
            fill: ${Colors.WHITE};
            transition: transform 100ms;
            transform: rotate(${isOpen ? '-180deg' : '1deg'}); /* 1deg default rotation is to prevent icon from shifting before and after transition */
          `,
        }}
      />

      <Container
        ref={optionsContainerRef}
        css={`
          position: absolute;
          transform: translateY(42px);
          z-index: 9999;
          max-height: ${isOpen ? '240px' : '0'};
          max-width: 95%;
          pointer-events: none;
          overflow: hidden;
          overflow-y: ${isOpen ? 'auto' : 'hidden'};
          transition: max-height 100ms;

          ${props.dropdownOptionsContainerCss}
        `}
      >
        <Container
          css={`
            background-color: ${Colors.GREEN.OFF_DARK};
            border: 2px solid ${Colors.TRANSPARENT.WHITE};
            border-top: none;
            border-radius: 0 0 8px 8px;
            width: 100%;
            pointer-events: auto;
            transition: transform 100ms;
            transform: translateY(${isOpen ? '0' : '-105%'});
          `}
        >
          {dropdownOptions.length > 0 ? dropdownOptions : (
            <Text
              text={'No results'}
              css={`
                padding: 0 16px;
                color: ${Colors.GREY.TRANS_LIGHT};
              `}
            />
          )}
        </Container>
      </Container>

    </Container>
  );
};

DropDown.displayName = 'DropDown';

DropDown.propTypes = {
  css: PropTypes.string,
  default: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownOptionsContainerCss: PropTypes.string,
  errorText: PropTypes.string,
  hint: PropTypes.string,
  inputContainerCss: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};