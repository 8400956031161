import {Colors} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {FormContext} from 'components/form/FormContext';
import {FormInputLabel} from 'components/form/FormInputLabel';
import {FormInputTooltip} from 'components/form/FormInputTooltip';
import {MultiPieceText} from 'components/general';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const typeToExtension = {
  'application/pdf': 'pdf',
  'image/jpeg': 'image',
  'image/png': 'image',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'text/csv': 'csv',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
};

const RotatingContainer = styled(Container)`

    flex: 1;
    padding: 64px 0;
    margin: 4px;

    justify-content: center;
    align-items: center;

    @keyframes linearGradientMove {
        100% {
            background-position: 16px 0, -16px 100%, 0 -16px, 100% 16px;
        }
    }

    ${(props) => {

    return `
      background:
            linear-gradient(90deg, ${props.dragging ? Colors.GREEN.LIME : props.invalid ? Colors.RED : Colors.TRANSPARENT.WHITE} 50%, transparent 0) repeat-x,
            linear-gradient(90deg, ${props.dragging ? Colors.GREEN.LIME : props.invalid ? Colors.RED : Colors.TRANSPARENT.WHITE} 50%, transparent 0) repeat-x,
            linear-gradient(0deg, ${props.dragging ? Colors.GREEN.LIME : props.invalid ? Colors.RED : Colors.TRANSPARENT.WHITE} 50%, transparent 0) repeat-y,
            linear-gradient(0deg, ${props.dragging ? Colors.GREEN.LIME : props.invalid ? Colors.RED : Colors.TRANSPARENT.WHITE} 50%, transparent 0) repeat-y;
            
      background-size: 16px 2px, 16px 2px, 2px 16px, 2px 16px;
      background-position: 0 0, 0 100%, 0 0, 100% 0;
      
      ${props.css};
    
      ${props.dragging ? `
        animation: linearGradientMove 300ms linear infinite;
      ` : ''}
      `;
  }};
`;

export const UploadDropper = (props) => {

  const [dragging, setDragging] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const fileUploadRef = React.useRef(null);

  const formContext = React.useContext(FormContext);

  const inputChange = (e, file, accepts) => {

    setDragging(false);

    if (!file || !accepts.includes(typeToExtension[file.type])) {

      if (file) {
        setInvalid(true);
      }
      return;
    }

    e.preventDefault();

    if(props.disabled) {
      return;
    }

    if (props.onChange) {

      props.onChange({
        value: file,
        name: props.name,
      });
      return;
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: file,
    }));
  };

  const clearFile = () => {

    if (fileUploadRef.current) {
      fileUploadRef.current.value = null;
    }
    formContext.setState((prev) => ({
      ...prev,
      [props.name]: undefined,
    }));
  };

  const accepts = props.accept ?? 'application/pdf';

  const file = formContext.state[props.name] || props.value;

  const label = (
    <HorizontalContainer>

      {props.label &&
        <FormInputLabel
          text={props.label}
          optionalText={props.required === false}
          css={`
                margin-right: ${props.tooltip ? '12px' : '0'};
                margin-bottom: 4px;
            `}
        />
      }

      {props.tooltip &&
        <FormInputTooltip
          text={props.tooltip}
          position={props.label ? 'right' : 'left'}
          css={`
                margin-left: ${props.label ? '0' : '-4px'};
                margin-top: -4px;
            `}
        />
      }

    </HorizontalContainer>
  );

  if (file) {

    return (
      <>
        {label}
        <MultiPieceText
          texts={[
            `${file.name}`,
            {
              text: '  -  ',
              skip: props.disabled,
              css: '',
            },
            {
              text: 'Remove',
              onClick: clearFile,
              skip: props.disabled,
              css: `
                  color: ${Colors.RED};
                `,
            },
          ]}
          css={`
              margin-top: 8px;
          `}
        />
      </>
    );
  }

  return (
    <Container
      css={`
          padding: 8px;
          ${props.css};
      `}
    >

      {label}

      <RotatingContainer
        invalid={invalid}
        dragging={dragging}
        onDragEnter={() => {

          setDragging(true);
          setInvalid(false);
        }}
        onDragLeave={() => setDragging(false)}
        onDragOver={(e) => {

          e.preventDefault();
          e.stopPropagation();
        }}
        css={`
          position: relative;
        `}
      >
        <MultiPieceText
          texts={[invalid ? 'Invalid file type, please try again' : 'Click to upload file, or drag & drop file here', `\n(Accepted formats include: ${accepts})`]}
          css={`
              text-align: center;
          `}
        />

        <input
          key={'file'}
          type={'file'}
          ref={fileUploadRef}
          style={{
            position: 'absolute',
            backgroundColor: 'red',
            opacity: 0,
            border: '1px solid white',
            width: '100%',
            height: '100%',
          }}
          onChange={(e) => inputChange(e, e.target.files[0], accepts)}
          accept={accepts}
        />

      </RotatingContainer>
    </Container>
  );
};

UploadDropper.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};