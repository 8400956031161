import {Storage} from 'providers/Storage';

export const ndviNotification = ({
  companyId,
  notificationContext,
}) => {

  const currentStorages = Storage.get('ndviNotifications') ?? {};

  if(currentStorages[companyId]) {
    return;
  }

  Storage.set('ndviNotifications', {[companyId]: true}, true);


  const socket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}/notifications/${companyId}/ndvi/`);
  socket.onmessage =(e) => {

    const data = JSON.parse(e.data);
    switch (data.message) {
      case 'complete':
        notificationContext.setMessage(`NDVI generation completed for company ID: ${companyId}`);
        break;

      case 'noFields':
        notificationContext.setError(`No fields found for NDVI generation for company ID: ${companyId}`);
        break;

      case 'update':
        notificationContext.setMessage(`NDVI notification for: ${companyId}\nStarted: ${data.data}`);
        return;

      case 'failure':
        notificationContext.setError(`An error occurred during NDVI generation for company ID: ${companyId} \n Please contact your friendly neighbourhood developer.\n TRYING AGAIN WON'T WORK`);
        break;

      default:
        notificationContext.setError('An error occurred during NDVI generation, catered notification type: ', data.message);
    }

    Storage.set('ndviNotifications', {[companyId]: false}, true);
    socket.close();
  };
};