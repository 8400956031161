import {
  CheckBox,
  DropDown,
  formLabel,
  TextArea,
  TextInput,
} from 'components';
import {getSeasonDropDownOptions} from 'utils/getSeasonDropDownOptions';

export const mainActivityOptions = [
  {
    label: 'Cropland - dry',
    value: 'croplandDry',
  },
  {
    label: 'Cropland - pivot/irrigation',
    value: 'croplandIrrigation',
  },
  {
    label: 'Pastures/Grazing - Planted',
    value: 'pasturesPlanted',
  },
  {
    label: 'Pastures/Grazing - Natural',
    value: 'pasturesNatural',
  },
  {
    label: 'Forestry Plantations',
    value: 'forestry',
  },
  {
    label: 'Orchards',
    value: 'orchards',
  },
  {
    label: 'Vineyards',
    value: 'vineyards',
  },
  {
    label: 'Vegetables',
    value: 'vegetables',
  },
  {
    label: 'Dam/River/Wetland area',
    value: 'wetland',
  },
  {
    label: 'Other (protected/farmyard)',
    value: 'other',
  },
];

export const mapFieldsDataConfig = [
  formLabel('Field Details:'),
  {
    name: 'notIncluded',
    label: 'This field is not included in the carbon crop programme (you will be able to include it at a later date)',
    component: CheckBox,
    required: false,
  },
  [
    {
      component: DropDown,
      label: 'Reason for not including this field',
      name: 'notIncludedReason',
      options: [
        {
          label: 'Future Inclusions',
          value: 'FUTURE',
        },
        {
          label: 'Wetland',
          value: 'WETLAND',
        },
        {
          label: 'Grassland Conversion',
          value: 'GRASSLAND',
        },
        {
          label: 'Non-stable Lease',
          value: 'LEASE_NON_STABLE',
        },
        {
          label: 'Mining Lease',
          value: 'LEASE_MINING',
        },
        {
          label: 'Native Land Conversion',
          value: 'CONVERSION_NATIVE',
        },
        {
          label: 'Requested by Farmer',
          value: 'FARMER_REQUEST',
        },
        {
          label: 'Other Non-Permanence Risk',
          value: 'OTHER_NON_PERMANENCE_RISK',
        },
        {
          label: 'Other (Explain in description)',
          value: 'OTHER',
        },
      ],
      visible: (context) => context.notIncluded,
    },
    {
      component: DropDown,
      label: 'How long until you would like to include this field?',
      name: 'notIncludedTime',
      options: [
        {
          label: 'Unknown inclusion date',
          value: -1,
        },
        ...getSeasonDropDownOptions({
          toYear: new Date().getFullYear() + 3,
          preOption: true,
        }),
      ],
      suffix: 'years',
      visible: (context) => context.notIncludedReason === 'FUTURE',
    },
  ],
  {
    component: DropDown,
    label: 'Farm name',
    name: 'farmId',
  },
  {
    component: TextInput,
    label: 'Field name',
    name: 'name',
  },
  {
    component: DropDown,
    label: 'Main field activity',
    name: 'mainActivity',
    options: mainActivityOptions,
    required: (context) => {
      return !context.notIncluded;
    },
  },
  {
    component: TextArea,
    label: 'Optional field description (for your own purposes)',
    name: 'description',
    required: false,
    maxLength: 500,
  },
  {...formLabel('Field Eligibility (please confirm the following):')},
  {
    component: CheckBox,
    label: 'Select all',
    name: 'eligibility.all',
    required: (context) => {
      return !context.notIncluded;
    },
  },
  {
    component: CheckBox,
    label: 'This field is not situated on a wetland',
    name: 'eligibility.wetland',
    required: (context) => {
      return !context.notIncluded;
    },
  },
  {
    component: CheckBox,
    label: 'This field has not been cleared of its native ecosystem after 2007',
    name: 'eligibility.ecosystemCleared',
    required: (context) => {
      return !context.notIncluded;
    },
  },
  {
    component: CheckBox,
    label: 'No BioChar has been applied to this field in the past',
    name: 'eligibility.biochar',
    required: (context) => {
      return !context.notIncluded;
    },
  },
  {
    component: CheckBox,
    label: 'This field has remained cropland / grassland since July 2018 (in other words, it has not changed from cropland to grassland or vice versa)',
    name: 'eligibility.cropland',
    required: (context) => {
      return !context.notIncluded;
    },
  },
  {
    component: CheckBox,
    label: 'Additional sustainable/regenerative practices have been implemented on this field since 1 July 2018',
    name: 'eligibility.sustainable',
    required: (context) => {
      return !context.notIncluded;
    },
  },
];