import {Colors} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {
  DropDown,
  FormContext,
  TextInput,
} from 'components/form';
import {Text} from 'components/general';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import {
  AsYouType,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
  parsePhoneNumber,
  isValidNumberForRegion,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import PropTypes from 'prop-types';
import React from 'react';

countries.registerLocale(enLocale);
const countryNamesObject = countries.getNames('en', {select: 'official'});
const countriesArr = getCountries();

const dropDownOptions = [];
for (const countryCode of countriesArr) {

  const callingCode = `+${getCountryCallingCode(countryCode)}`;
  const countryName = countryNamesObject[countryCode];

  if (countryName) {

    dropDownOptions.push({
      label: `${countryName} (${callingCode})`,
      value: countryCode,
      displayText: countryCode,
    });
  }
}
dropDownOptions.push({
  label: 'N/A',
  value: 'N/A',
});

const getNumberWithSpaces = (phoneNumber, countryCode) => {

  return new AsYouType(countryCode).input(phoneNumber).split(' ').slice(1).join(' '); // Returns number without calling code
};

const getExamplePhoneNumber = (countryCode) => {

  return getNumberWithSpaces(getExampleNumber(countryCode, examples).number, countryCode);
};

export const PhoneNumberInput = (props) => {

  const formContext = React.useContext(FormContext);

  const [selectedCountry, setSelectedCountry] = React.useState('ZA');
  const [numberInputValue, setNumberInputValue] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [exampleNumber, setExampleNumber] = React.useState(getExamplePhoneNumber('ZA'));
  const errorText = errorMessage ?? props.errorText;
  const callingCode = React.useMemo(() => selectedCountry !== 'N/A' ? `+${getCountryCallingCode(selectedCountry)}` : null, [selectedCountry]);

  const validateNumber = (phoneNumber, countryCode) => {

    if (phoneNumber.length === 0 || countryCode === 'N/A') {

      setErrorMessage(null);
      return;
    }

    const isValid = isValidNumberForRegion(phoneNumber, countryCode);

    if (!isValid) {

      setErrorMessage('Invalid phone number for selected country');
    }

    if (isValid && errorMessage) {
      setErrorMessage(null);
    }
  };

  const handleChangeCountry = ({value}) => {

    const newCallingCode = value !== 'N/A' ? `+${getCountryCallingCode(value)}` : null;

    setSelectedCountry(value);
    setExampleNumber(newCallingCode ? getExamplePhoneNumber(value) : '');

    if (numberInputValue) {

      const newPhoneNumber = numberInputValue ? (newCallingCode ? `${newCallingCode} ${numberInputValue}` : numberInputValue) : null;

      if (props.onChange) {

        props.onChange({
          name: props.name,
          value: newPhoneNumber,
        });
        return;
      }

      formContext.setState((prev) => ({
        ...prev,
        [props.name]: newPhoneNumber,
      }));
    }
  };

  const handleChangeNumberInput = ({value}) => {

    if (value && !value.match(/([0-9]* ?)*/g)) {
      return;
    }

    if (value && selectedCountry !== 'N/A') {

      validateNumber(`${callingCode}${value}`, selectedCountry);
    }

    const newValue = callingCode ? getNumberWithSpaces(`${callingCode}${value}`, selectedCountry) : value;
    const formattedPhoneNumber = value ? (`${callingCode ? `${callingCode} ` : ''}${value.replaceAll(' ', '')}`) : null;

    setNumberInputValue(newValue);

    if (props.onChange) {

      props.onChange({
        name: props.name,
        value: formattedPhoneNumber,
      });
      return;
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: formattedPhoneNumber,
    }));
  };

  // Handle initial value
  React.useEffect(() => {

    const initialValue = props.value ?? formContext.state[props.name];

    if (!initialValue) {
      return;
    }

    try {

      const phoneNumber = parsePhoneNumber(initialValue, {defaultCountry: 'ZA'});

      validateNumber(phoneNumber.number, phoneNumber.country);

      setSelectedCountry(phoneNumber.country); //! Could be the wrong country if multiple countries have the same calling code
      setNumberInputValue(getNumberWithSpaces(phoneNumber.number, phoneNumber.country));
      setExampleNumber(getExamplePhoneNumber(phoneNumber.country));

    } catch {

      let value = initialValue;
      if (typeof initialValue === 'number') {
        value = `${initialValue}`;
      }

      //! If parsing with 'parsePhoneNumber()' fails, the calling code (if included in value) gets discarded because there's no place for it to be used. i.e. '+27 1234' gets turned into '1234'
      setNumberInputValue(value?.split(' ')[1] ?? value);
      setSelectedCountry('N/A');
    }
  }, []);

  // Handle props.value changes
  React.useEffect(() => {

    let value = props.value;
    if(typeof props.value === 'number') {
      value = `${props.value}`;
    }

    if (value && !numberInputValue) {
      setNumberInputValue(value?.split(' ')[1] ?? props.value);
    }
  }, [props.value]);

  return (
    <Container
      css={props.css}
    >
      <HorizontalContainer>
        <DropDown
          value={selectedCountry}
          options={dropDownOptions}
          onChange={handleChangeCountry}
          placeholder={''}
          disabled={props.disabled}
          css={`
            width: 72px;
          `}
          dropdownOptionsContainerCss={`
            min-width: 360px;
          `}
          inputContainerCss={`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-width: 1px;
          `}
        />
        <TextInput
          value={numberInputValue}
          label={props.label}
          onChange={handleChangeNumberInput}
          disabled={props.disabled}
          prefix={callingCode}
          placeholder={exampleNumber}
          tooltip={props.tooltip}
          maxLength={15}
          css={`
            flex: 1;
          `}
          inputContainerCss={`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-width: 1px;
          `}
        />
      </HorizontalContainer>

      {errorText &&
        <Text
          text={errorText}
          css={`
            color: ${Colors.RED};
            margin: 6px 0 0 4px;
          `}
        />
      }

      {props.hint &&
        <Text
          text={props.hint}
          css={`
            color: ${Colors.GREY.LIGHT};
            margin: 6px 0 0 4px;
          `}
        />
      }
    </Container>
  );
};

PhoneNumberInput.displayName = 'PhoneNumberInput';

PhoneNumberInput.propTypes = {
  css: PropTypes.string,
  disabled: PropTypes.any,
  errorText: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.shape({
    phoneNumber: PropTypes.string,
    selectedCountry: PropTypes.string,
  }),
  onChange: PropTypes.func,
};