import {Colors} from 'appConstants';
import {Container} from 'components/containers';
import {
  useInsideClick,
  useOutsideClick,
} from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';

const arrowSize = 20;

export const Tooltip = (props) => {

  const [showTooltip, setShowTooltip] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const fullRef = React.useRef(null);

  const clickTooltipComponent = () => {

    props.onShowTooltip && props.onShowTooltip();
    setShowTooltip((prev) => !prev);
  };

  const onOutsideClick = (e) => {

    if (e.inside) {

      if (props.closeOnInsideClick) {

        setShowTooltip(false);
        return;
      }

      return;
    }

    setShowTooltip(false);
  };
  useOutsideClick(fullRef, onOutsideClick);
  useInsideClick(fullRef, onOutsideClick);

  let borderRadius = '';

  switch (props.position) {
    case 'left':
      borderRadius = 'border-radius: 0 10px 10px 10px';
      break;
    case 'right':
      borderRadius = 'border-radius: 10px 0 10px 10px';
      break;
    case 'center':
      borderRadius  = 'border-radius: 10px 10px 10px 10px';
  }

  return (
    <Container
      css={`
        justify-content: center;
        align-items: center;
        position: relative;
        margin: auto;
        ${props.css}
      `}
      ref={fullRef}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >

      <Container
        onClick={clickTooltipComponent}
        css={'z-index: 3;'}
      >
        {props.component}
      </Container>

      {(showTooltip || (props.openOnHover && hover)) &&
        <Container
          css={`
            position: absolute;
            z-index: 2;
            bottom: -${arrowSize + 4}px; // Extra 4px is for slight seperation between tooltip and 'props.component'
            border: ${arrowSize}px solid transparent;
            border-bottom-color: ${props.tooltipBorder ?? props.tooltipBackground ?? Colors.TRANSPARENT.GREEN.DARK};
          `}
        >
          <Container
            css={`
              position: absolute;
              top: calc(100% + ${arrowSize}px);
              ${props.position === 'center' ? 'transform: translateX(-50%);' : props.position === 'left' ? `transform: translateX(calc(-100% + ${arrowSize}px));` : `${props.position ?? 'right'}: -${arrowSize}px;`}
              width: max-content;
              max-width: ${props.tooltipWidth}px;
              background-color: ${props.tooltipBackground ?? Colors.TRANSPARENT.GREEN.DARK};
              border: 2px solid ${props.tooltipBorder ?? props.tooltipBackground ?? Colors.TRANSPARENT.GREEN.DARK};
              padding: 8px;
              align-items: flex-start;
              ${props.addRadius ? borderRadius : ''}
            `}
          >
            {props.children}
          </Container>
        </Container>
      }

    </Container>
  );
};

Tooltip.propTypes = {
  addRadius: PropTypes.bool,
  closeOnInsideClick: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  css: PropTypes.string,
  openOnHover: PropTypes.bool,
  position: PropTypes.string,
  tooltipBackground: PropTypes.string,
  tooltipBorder: PropTypes.string,
  tooltipWidth: PropTypes.number,
  onShowTooltip: PropTypes.func,
};

Tooltip.defaultProps = {
  position: 'left',
  tooltipWidth: 200,
};