import {Colors} from 'appConstants';
import {NotificationContext} from 'components';
import {Form} from 'components/form';
import {FormContext} from 'components/form/FormContext';
import {signUpFormConfig} from 'compositeComponents/signUpForm/signUpFormConfig';
import {
  UserContext,
  useSecurity,
} from 'context';
import {CompanyContext} from 'context/CompanyContext';
import {postUser} from 'providers/UserProvider';
import React from 'react';
import {routes} from 'routes';
import {useNav} from 'routes';

export const SignUpForm = (props) => {

  const formContext = React.useContext(FormContext);
  const notificationContext = React.useContext(NotificationContext);
  const {
    userInfo,
    setUserInfo,
  } = React.useContext(UserContext);
  const {
    activeCompany,
    setActiveCompany,
  } = React.useContext(CompanyContext);
  const {user} = useSecurity();
  const navigate = useNav();

  const [errorState, setErrorState] = React.useState(null);

  const submit = (formState) => {

    if (props.overrideSubmit) {
      return props.overrideSubmit(formState);
    }

    return postUser({
      data: formState,
      companyId: activeCompany.id,
      userId: userInfo.id,
    })
      .then((res) => {

        if(res.status === 400 && res.data.message === 'Too many characters') {

          notificationContext.setError('Too many characters. Please correct the highlighted field(s)');
          setErrorState(res.data.errors);
          return;
        }

        if (!res.ok) {

          console.error('An error occurred', res);
          if (res.status === 409) {

            formContext.clear?.();
            notificationContext.setMessage('A user has already signed up with this email! Please sign in again');
          }

          return;
        }

        notificationContext.setMessage('Sign up complete!');
        formContext.clear?.();

        if (props.finaliseSubmission) {

          props.finaliseSubmission();
          return;
        }

        setActiveCompany(res.data.company);
        setUserInfo(res.data.user);
      });
  };

  React.useEffect(() => {

    formContext.setState((prev) => ({
      ...prev,
      email: user.email,
    }));
  }, []);

  //make sure signed up users can't access this page
  React.useEffect(() => {

    const locationArr = location.pathname.split('/');

    if (locationArr[locationArr.length-1] === routes.Profile.nested.SignUp.path && userInfo?.email) {
      navigate(routes.Profile.nested.Home);
    }
  }, [userInfo.id]);

  return (
    <Form
      title={'Create Your Profile'}
      items={signUpFormConfig}
      onSubmit={submit}
      css={`background: ${Colors.GREEN.DARK}`}
      prePopulateValues={props.prePopulateValues}
      unacceptedValues={errorState}
    />
  );
};