import {RadioButton} from 'components/form/RadioButton';
import React from 'react';

export const CheckBox = (props) => {

  return (
    <RadioButton
      {...props}
      borderRadius={'1%'}
    />
  );
};

CheckBox.displayName = 'CheckBox';