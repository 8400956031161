import {round} from '@orizonagriculture/orizonutils';
import {fieldStatusText} from 'appConstants';
import {
  Container,
  Table,
} from 'components';
import React from 'react';

export const FarmFieldAreas = (props) => {

  const summaryFarmRows = [];
  const summaryFieldRows = [];

  for(const farm of props.farms) {

    const arr = [
      farm.name,
      0,
      0,
      0,
      0,
    ];
    arr.id = farm.id;
    summaryFarmRows.push(arr);
  }

  summaryFarmRows.push([
    'Unassigned',
    0,
    0,
    0,
    0,
  ]);

  let boundaryArea = 0;
  for (const bound of (props.boundaries ?? [])) {

    let farmRow = summaryFarmRows.find((row) => row.id === bound.farmId);
    if(!farmRow) {

      summaryFarmRows[summaryFarmRows.length-1][1] = round((summaryFarmRows[summaryFarmRows.length-1][1] + bound.area), 2);
      continue;
    }

    farmRow[1] = round((farmRow[1] + bound.area), 2);
    boundaryArea += bound.area;
  }
  boundaryArea = round(boundaryArea, 2);

  let totalArea = 0;
  let totalAreaCalc = 0;
  let noUnnamedFields = 1;
  for(const field of (props.fields ?? [])) {

    const fieldName = (field.name && field.name !== '') ? field.name : `Unnamed field - ${noUnnamedFields++}`;

    const row = [
      fieldName,
      field.area,
      '',
      fieldStatusText[field.status],
    ];
    row.id = field.id;
    row.field = field;

    if (field.status !== 'NOT_INCLUDED') {
      totalArea = Math.round((totalArea + field.area)*100)/100;
    }
    totalAreaCalc = Math.round((totalAreaCalc + field.area)*100)/100;

    let farmRow = summaryFarmRows.find((row) => row.id === field.farmId);

    if(!farmRow) {

      farmRow = summaryFarmRows[summaryFarmRows.length-1];
      row[2] = 'Unassigned';
    } else {
      row[2] = farmRow[0];
    }

    farmRow[2] = Math.round((farmRow[2] + field.area)*100)/100;
    farmRow[4] = farmRow[4] + 1;

    if (field.status !== 'NOT_INCLUDED') {
      farmRow[3] = Math.round((farmRow[3] + field.area)*100)/100;
    }

    summaryFieldRows.push(row);
  }

  summaryFarmRows.push([
    'Total',
    boundaryArea,
    totalAreaCalc,
    totalArea,
    summaryFieldRows.length,
  ]);

  const fieldClick = (pos) => {

    const row = summaryFieldRows[pos];
    return props.onFieldClick(row.field);

  };

  const farmClick = (pos) => {

    const row = summaryFieldRows[pos];
    return props.onFarmClick(row.farm);

  };

  return (

    <Container
      css={`
        padding: 8px 0;
        gap: 16px;
      `}
    >

      <Table
        title={'Farm(s)'}
        rows={summaryFarmRows}
        headings={[
          'Name',
          'Boundary Ha.',
          'Field ha. (total)',
          'Field ha. (included)',
          'Number of fields',
        ]}
        onRowClick={props.onFarmClick ? farmClick : undefined}
        tableName={'farm'}
      />

      {summaryFieldRows.length > 0 && !props.hideFields &&
        <Table
          title={'Field(s)'}
          rows={summaryFieldRows}
          headings={[
            'Name',
            'ha. (calculated)',
            'Farm name',
            'Field Status',
          ]}
          onRowClick={props.onFieldClick ? fieldClick : undefined}
          tableName={'field'}
        />
      }

    </Container>

  );
};