import {
  Colors,
  downloadFromRes,
  ScreenSize,
} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {
  Image,
  MultiPieceText,
} from 'components/general';
import {ScreenContext} from 'context';
import {downloadFile} from 'providers';
import React from 'react';

export const Footer = () => {

  const {currentView} = React.useContext(ScreenContext);

  const download = () => {

    return downloadFile({fileName: 'grievanceProcess.pdf'})
      .then((res) => {
        downloadFromRes(res, 'Orizon Agriculture Grievance Process.pdf');
      })
      .catch((err) => console.error('An error occurred downloading the file', err));
  };

  return (
    <Container
      css={`
        width: 100%;
        padding: 32px ${currentView === ScreenSize.MOBILE ? 12 : 64}px;
      `}
    >

      <Image
        src={'OrizonLogo'}
        alt={'Orizon logo'}
        height={'50px'}
        width={'170px'}
      />

      <HorizontalContainer
        css={`
          display: ${currentView === ScreenSize.DESKTOP ? 'flex' : 'block'};
        `}
      >
        <MultiPieceText
          textCss={`color: ${Colors.WHITE};`}
          texts={[
            {
              link: 'https://pixelvillage.co.za/',
              text: '© Orizon 2022 Designed by Pixel Village',
            },
            ' | ',
            {
              link: '/privacy-policy',
              text: 'Privacy Policy',
            },
            ' | ',
            {
              link: '/terms-and-conditions',
              text: 'Terms & Conditions',
            },
            ' | ',
            {
              text: 'Grievance Process',
              onClick: download,
              css: `
                color: ${Colors.WHITE};
              `,
            },
          ]}
        />

      </HorizontalContainer>

    </Container>
  );
};