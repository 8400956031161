export const animalList = [
  {
    value: 'beef',
    label: 'Cattle - Beef',
  },
  {
    value: 'dairy',
    label: 'Cattle - Dairy',
  },
  {
    value: 'sheep',
    label: 'Sheep',
  },
  {
    value: 'goats',
    label: 'Goats',
  },
  {
    value: 'pigs',
    label: 'Pigs',
  },
  {
    value: 'chickenBroilers',
    label: 'Chicken Broilers',
  },
  {
    value: 'chickenLaying',
    label: 'Chicken Laying',
  },
  {
    value: 'other',
    label: 'Other (indicate in comment)',
  },
];