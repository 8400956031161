import {
  Colors,
  makeTransparent,
} from 'appConstants';
import {Container} from 'components';
import {MenuItem} from 'compositeComponents';
import PropTypes from 'prop-types';
import React from 'react';

export const Menu = (props) => {

  const buildMenuItems = (config = []) => {

    const menu = [];
    for (const item of config) {

      if (item.off) {
        continue;
      }

      // Handle input components seperately
      if (item.onChange) {

        menu.push(
          <Container
            css={`
            ${!props.isOpen ? `box-shadow: inset 0 0 0 2px ${Colors.GREY.TRANS_LIGHT}` : ''};
            border-radius: 8px;
            margin: 4px 0;
          `}
          >
            <Container
              css={`
              width: ${props.isOpen ? '100%' : 0};
              opacity: ${props.isOpen ? 1 : 0};
            `}
            >
              <item.component
                {...item}
              />
            </Container>
          </Container>,
        );

        continue;
      }

      menu.push(
        <MenuItem
          item={item}
          active={item.key === props.activeSection}
          isMenuOpen={props.isOpen}
        />,
      );
    }

    return menu;
  };

  const menu = buildMenuItems(props.config);
  const bottomMenu = buildMenuItems(props.bottomConfig);

  return (
    <Container
      css={`
        background-color: ${makeTransparent(Colors.GREEN.DARK, 0.8)};
        backdrop-filter: blur(4px);
        border-right: 1px solid ${Colors.TRANSPARENT.WHITE};
        padding: 16px;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;  /* Safari and Chrome */
        }
        ${props.css}
      `}
    >

      <Container
        css={`
          height: 100%;
          justify-content: space-between;
        `}
      >
        <Container>
          {menu}
        </Container>

        <Container>
          {bottomMenu}
        </Container>

      </Container>

    </Container>
  );
};

Menu.propTypes = {
  activeSection: PropTypes.string,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      css: PropTypes.string,
      icon: PropTypes.string,
      iconHeight: PropTypes.string,
      iconWidth: PropTypes.string,
      isOpen: PropTypes.bool,
      key: PropTypes.string,
      subItems: PropTypes.array,
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

Menu.defaultProps = {isOpen: true};