/**
 * @description Returns an array of season options to be used with a 'DropDown' component
 * @param {Object} options - Config options
 * @param {number} options.toYear - Year that options should be calculated to
 * @param {boolean} options.naOption - Whether or not 'N/A' dropdown option should be included
 * @param {boolean} options.preOption - Whether or not 'Pre-<toYear>' dropdown option should be included
 * @param {'earliestFirst'} options.sortBy - Order in which dropdown options should be sorted
 * @returns {array}
 */
export const getSeasonDropDownOptions = (options) => {

  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentSeasonStart = currentMonth >= 7 ? currentYear : currentYear - 1;

  const dropDownOptions = [];

  if (options.naOption) {

    dropDownOptions.push(    {
      label: 'N/A',
      value: -1,
    });
  }

  let i = 0;
  let offSet = 0;
  while (i < 100) { // Limit max number of options to 100 to prevent infinite loop if something goes wrong

    offSet = options.toYear > currentYear ? -i : i;
    const value = currentSeasonStart - offSet;

    // Example option: {label: '2020/21', value: 2020}
    dropDownOptions.push({
      label: `${currentSeasonStart - offSet}/${String(currentSeasonStart - offSet + 1).slice(2)}`,
      value,
    });

    i++;

    if (value === options.toYear) {
      break;
    }
  }

  if (options.preOption && options.toYear < currentYear) {

    dropDownOptions.push({
      label: `Pre-${options.toYear}`,
      value: currentSeasonStart - offSet - 1,
    });
  }

  if (options.sortBy === 'earliestFirst') {

    dropDownOptions.sort((option1, option2) => option1.value - option2.value);
  }

  return dropDownOptions;
};