import {
  Colors,
  displayDate,
  MONTHS,
} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {FormContext} from 'components/form/FormContext';
import {FormInputLabel} from 'components/form/FormInputLabel';
import {FormInputTooltip} from 'components/form/FormInputTooltip';
import {
  Image,
  Text,
} from 'components/general';
import {useOutsideClick} from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';

const weekDays = [
  'M',
  'T',
  'W',
  'T',
  'F',
  'S',
  'S',
];

export const DatePicker = (props) => {

  const formContext = React.useContext(FormContext);

  const [selected, setSelected] = React.useState(formContext.state?.[props.name] ?? new Date());
  const [isOpen, setIsOpen] = React.useState(false);

  const ref = React.useRef(null);

  const valueSelect = (day) => {

    setIsOpen(false);
    const selectedDate = new Date(selected);
    selectedDate.setDate(day);
    selectedDate.setHours(12);
    setSelected(selectedDate);

    if (props.onChange) {

      props.onChange({
        name: props.name,
        value: selectedDate,
      });
      return;
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: selectedDate,
    }));
  };

  const onClick = () => {

    if (props.disabled) {
      return;
    }
    setIsOpen(!isOpen);
  };

  const onOutsideClick = () => {
    setIsOpen(false);
  };
  useOutsideClick(ref, onOutsideClick);

  const options = [];

  for (const day in weekDays) {

    options.push(
      <Text
        key={`${day}-${weekDays[day]}`}
        css={`
          padding: 6px;
          margin: 2px;
          width: 32px;
          text-align: center;
          box-sizing: border-box;
        `}
        text={weekDays[day]}
      />,
    );
  }

  const month = selected.getMonth();
  const year = selected.getFullYear();

  const dateCopy = new Date(selected);
  dateCopy.setDate(1);
  const weekOffset = dateCopy.getDay();

  for (let i = 1; i<weekOffset; i++) {

    options.push(
      <Text
        key={`offset-${i}`}
        css={`
          padding: 6px;
          border: 1px solid ${Colors.TRANSPARENT.WHITE};
          margin: 2px;
          width: 32px;
          text-align: center;
          box-sizing: border-box;
        `}
        text={''}
      />,
    );
  }

  const noDays = MONTHS[month].daysCalc
    ? MONTHS[month].daysCalc(year)
    : MONTHS[month].days;

  for (let i=1; i <= noDays; i++) {

    const dateOfDay = new Date(selected);
    dateOfDay.setDate(i);

    const isTooEarly = dateOfDay < props.initialDate || dateOfDay < props.minDate;

    options.push(
      <Text
        key={`day-${i}`}
        onClick={isTooEarly ? undefined : () => valueSelect(i)}
        css={`
          padding: 6px;
          border: 1px solid ${Colors.TRANSPARENT.WHITE};
          margin: 2px;
          width: 32px;
          text-align: center;
          box-sizing: border-box;
          ${selected.getDate() === i ? `background: ${Colors.TRANSPARENT.GREEN.LIGHT};`: ''}
          ${isTooEarly ? `background: ${Colors.GREY.TRANS_LIGHT};`: ''}
          ${!isTooEarly && `
            &:hover {
              border: 1px solid ${Colors.GREEN.LIGHT};
            }
          `}

        `}
        text={`${i}`}
      />,
    );
  }

  const increaseDate = () => {

    setSelected( (prev) => {

      const prevDate = new Date(prev);
      const newDay = new Date(prevDate.setDate(1));
      const newDate = new Date(newDay.setMonth(prev.getMonth()+1));

      if (newDate > props.maxDate) {
        return prev;
      }

      return newDate;
    });
  };

  const decreaseDate = () => {

    setSelected((prev) => {

      const prevDate = new Date(prev);
      const newDay = new Date(prevDate.setDate(1));
      const newDate = new Date(newDay.setMonth(prev.getMonth()-1));

      if (newDate < props.minDate) {
        return prev;
      }

      return newDate;

    });
  };

  const displayText = props.value ?? formContext.state[props.name]
    ? displayDate(props.value ?? formContext.state[props.name])
    : '';

  // Handle intial date
  React.useEffect(() => {

    let value = props.value ?? formContext.state[props.name];

    if (value && !(value instanceof Date)) {
      value = new Date(value);
    }

    if (selected?.toISOString?.() === value?.toISOString?.()) {
      return;
    }

    const initialDate = props.initialDate ?? value;

    if (initialDate) {

      setSelected(initialDate);
      return;
    }

    if (selected < props.minDate) {
      setSelected(props.minDate);
    }

    if (selected > props.maxDate) {

      if (props.minDate) {

        setSelected(props.minDate);
        return;
      }

      setSelected(props.maxDate);
    }

  }, [
    props.initialDate,
    props.minDate,
    props.maxDate,
    formContext.state[props.name],
  ]);

  return (
    <Container
      css={`
        position: relative;
        ${props.css};
      `}
      ref={ref}
    >

      <HorizontalContainer
        css={`
          position: relative;
          background: ${props.disabled ? Colors.TRANSPARENT.WHITE : Colors.TRANSPARENT.GREEN.DARK};
          height: 42px;
          align-items: center;
          border: ${Colors.TRANSPARENT.WHITE} solid 2px;
          padding: 4px;
          margin-top: ${props.disabled && displayText ? '10px' : '0'};
          border-radius: 8px;
          color: ${Colors.WHITE};
          justify-content: space-between;
        `}
        onClick={onClick}
      >

        <Text
          text={displayText}
          css={`
            margin: 6px 0 0 4px;
          `}
        />
        <FormInputLabel
          text={props.label}
          optionalText={props.required === false}
          css={`
            color: ${Colors.GREY.LIGHT};
            background-color: ${props.disabled ? 'transparent' : Colors.GREEN.DARK};
            padding: 0 4px;
            max-width: calc(100% - 56px);
            border-radius: 6px;

            position: absolute;
            top: 9px;
            pointer-events: none;
            transition-duration: 150ms;
            transition-property: color, font-size, transform;
            justify-self: start;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            /* Expand label if hovered */
            &:hover {
              overflow: visible;
              white-space: normal;
              background-color: ${Colors.GREEN.DARK};
            }

            ${displayText ? `
              pointer-events: auto;
              transform: translate(2px, ${props.disabled ? '-28px' : '-18px'});
              color: ${Colors.GREEN.LIME};
              font-size: 14px;
            ` : ''}

            ${props.labelCss}
          `}
        />

        <Image
          src={'ArrowDown'}
          alt={'Down arrow'}
          css={`
            fill: ${Colors.WHITE};
            margin-right: 3px;
            transition: rotate 100ms;
            rotate: ${isOpen ? '-180deg' : '0deg'};
          `}
        />

        {props.tooltip &&
          <FormInputTooltip
            text={props.tooltip}
            css={`
              position: absolute;
              top: 2px;
              right: 0;
            `}
          />
        }

      </HorizontalContainer>

      <Container
        css={`
          position: absolute;
          top: 52px;
          margin-left: 8px;
          overflow: hidden;
          max-height: ${isOpen ? '400px' : '0'};
          transition: max-height 100ms;
          `}
      >
        <Container
          css={`
            border: 1px solid ${Colors.TRANSPARENT.WHITE};
            background: ${Colors.GREEN.DARK};
            padding: 8px;
            border-radius: 4px;
            top: 64px;
            z-index: 2;
            &::-webkit-scrollbar {
              background: transparent;
            }
          `}
        >

          <HorizontalContainer
            css={`
              justify-content: space-between;
              padding-bottom: 8px;
            `}
          >

            <Text
              text={'<'}
              onClick={decreaseDate}
              css={`
                border: 1px solid ${Colors.TRANSPARENT.TRANSPARENT};
                padding: 2px 4px;
                &:hover {
                  border: 1px solid ${Colors.GREEN.LIGHT};
                }
              `}
            />

            <Text
              text={`${MONTHS[selected.getMonth()].name} ${selected.getFullYear()}`}
            />

            <Text
              text={'>'}
              onClick={increaseDate}
              css={`
                border: 1px solid ${Colors.TRANSPARENT.TRANSPARENT};
                padding: 2px 4px;
                &:hover {
                  border: 1px solid ${Colors.GREEN.LIGHT};
                }
              `}
            />
          </HorizontalContainer>
          <HorizontalContainer
            css={`
              width: 260px; //32px * 7 + padding
              flex-wrap: wrap;
            `}
          >
            {options}
          </HorizontalContainer>

        </Container>
      </Container>

      {props.errorText &&
        <Text
          text={props.errorText}
          css={`
            color: ${Colors.RED};
            margin: 6px 0 0 4px;
          `}
        />
      }

      {props.hint &&
        <Text
          text={props.hint}
          css={`
            color: ${Colors.GREY.LIGHT};
            margin: 6px 0 0 4px;
          `}
        />
      }

    </Container>
  );
};

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
  css: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hint: PropTypes.string,
  initialDate: PropTypes.instanceOf(Date),
  invalid: PropTypes.bool,
  label: PropTypes.string,
  labelCss: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
};