import {fetcher} from 'providers/fetcher';

export const getEvents = async () => {

  return fetcher('/api/v2/event');
};

export const deleteEvent = async (eventId) => {

  return fetcher(
    `/api/v2/event?eventId=${eventId}`,
    {method: 'delete'},
  );
};

export const addEvent = async (data) => {

  return fetcher(
    '/api/v2/event',
    {
      method: 'POST',
      body: data,
    },
  );
};