import {Colors} from 'appConstants';
import {Button} from 'components/buttons/Button';
import React from 'react';

export const TextButton = (props) => {

  return(
    <Button
      {...props}
      css={`
        background: none;
        padding: unset;

        &:hover {
          text-decoration-color: ${Colors.GREEN.LIGHT};
        }

        ${props.css};
      `}
      textCss={`
        color: ${props.disabled ? Colors.GREY.LIGHT : Colors.WHITE};
        text-decoration: underline ${Colors.TRANSPARENT.TRANSPARENT};
        transition: text-decoration 75ms;

        &:hover {
          text-decoration-color: inherit;
        }

        ${props.textCss};
      `}
    />
  );
};