import { Colors } from 'appConstants';
import {
  Container,
  HorizontalContainer,
  Image,
} from 'components';
import React from 'react';

export const MenuItem = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const item = {...props.item};
  const onClick = item.onClick;
  const disabled = item.disabled;

  delete item.onClick;

  return (
    <Container
      css={`
        border-bottom: 1px solid ${props.isMenuOpen || (item.icon && !props.isMenuOpen) ? Colors.GREY.TRANS_LIGHT : Colors.TRANSPARENT.TRANSPARENT};
        padding: 2px 0;
        overflow: hidden;
      `}
    >
      <HorizontalContainer
        css={`
          border-radius: 8px;
          ${props.active && !props.isMenuOpen ? `background-color: ${Colors.TRANSPARENT.GREEN.LIME};` : ''}

          &:hover {
            background-color: ${disabled ? 'none' : Colors.TRANSPARENT.GREEN.LIME};
          }
        `}
      >

        <HorizontalContainer
          onClick={disabled ? undefined : onClick}
          title={props.isMenuOpen ? '' : item.text}
          css={`
            gap: ${props.isMenuOpen ? '8px' : '0'};
            transition: gap 200ms;
            align-items: center;
          `}
        >
          {item.icon &&
            <Image
              src={item.icon}
              height={item.iconHeight ?? '40px'}
              width={item.iconWidth ?? '40px'}
              css={`
                height: 40px;
                width: 40px;
                align-items: center;
                justify-content: center;
              `}
            />
          }

          <item.component
            {...item}
            css={`
              text-decoration: underline;
              text-decoration-color: ${props.active ? Colors.GREEN.LIGHT : Colors.TRANSPARENT.TRANSPARENT};
              max-width: ${props.isMenuOpen ? '50vw' : '0'};
              transition: max-width 100ms;
              white-space: nowrap;
              margin-right: ${props.isMenuOpen ? '8px' : '0'};

              &:hover {
                text-decoration-color: ${props.active ? Colors.GREEN.LIGHT : Colors.TRANSPARENT.TRANSPARENT};
              }

              ${item.css}
            `}
          />
        </HorizontalContainer>

        {props.isMenuOpen && item.subItems &&
          <Container
            onClick={() => setIsOpen((prev) => !prev)}
            css={`
              margin-right: 8px;
              margin-left: auto;
              height: 100%;
              justify-content: center;
              flex: 1;
            `}
          >
            <Image
              src={'ArrowDown'}
              height={'16px'}
              width={'16px'}
              css={`
                margin-left: auto;
                fill: ${Colors.WHITE};
                transition: transform 100ms;
                transform: rotate(${isOpen ? '-180deg' : '0deg'});
              `}
            />
          </Container>
        }

      </HorizontalContainer>

      {props.isMenuOpen &&
        <Container
          css={`
          overflow: hidden;
        `}
        >
          <Container
            css={`
              max-height: ${isOpen ? '50vh' : '0'};
              transition: max-height 150ms;
            `}
          >
            {item.subItems}
          </Container>
        </Container>
      }
    </Container>
  );
};
