export const getCsgFarmData = ({
  xmin,
  xmax,
  ymin,
  ymax,
}) => {

  return fetch(`https://csggis.drdlr.gov.za/server/rest/services/CSGSearch/MapServer/5/query?where=&text=&objectIds=&time=&geometry=%7B%0D%0A%22xmin%22%3A${xmin}%2C%22ymin%22%3A${ymin}%2C%0D%0A%22xmax%22%3A${xmax}%2C%22ymax%22%3A${ymax}%2C%0D%0A%22spatialReference%22%3A%7B%22wkid%22%3A4326%7D%0D%0A%7D&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=OBJECTID%2CGID%2CPRCL_KEY%2CPRCL_TYPE%2CLSTATUS%2CWSTATUS%2CGEOM_AREA%2CCOMMENTS%2CTAG_X%2CTAG_Y%2CTAG_VALUE%2CTAG_SIZE%2CTAG_ANGLE%2CTAG_JUST%2CID%2CDATE_STAMP%2CPROVINCE%2CMAJ_REGION%2CMAJ_CODE%2CPARCEL_NO%2CSHAPE_Length%2CSHAPE_Area%2CDATE_PROCESSED&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=4326&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=json`);
};

export const getCsgFarmPortionData = ({
  xmin,
  xmax,
  ymin,
  ymax,
}) => {

  return fetch(`https://csggis.drdlr.gov.za/server/rest/services/CSGSearch/MapServer/3/query?where=&text=&objectIds=&time=&geometry=%7B%0D%0A%22xmin%22%3A${xmin}%2C%22ymin%22%3A${ymin}%2C%0D%0A%22xmax%22%3A${xmax}%2C%22ymax%22%3A${ymax}%2C%0D%0A%22spatialReference%22%3A%7B%22wkid%22%3A4326%7D%0D%0A%7D&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=OBJECTID%2CSHAPE%2CGID%2CPRCL_KEY%2CPRCL_TYPE%2CLSTATUS%2CWSTATUS%2CGEOM_AREA%2CCOMMENTS%2CTAG_X%2CTAG_Y%2CTAG_VALUE%2CTAG_SIZE%2CTAG_ANGLE%2CTAG_JUST%2CID%2CDATE_STAMP%2CDATE_PROCESSED%2CSHAPE_Length%2CSHAPE_Area%2CPROVINCE%2CMAJ_REGION%2CMAJ_CODE%2CPARCEL_NO%2CPORTION%2CSS_NAME%2CDSG_NO%2CSR_NO%2CSS_NO%2CLINK_ID&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=4326&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=json`);
};