import {Colors} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {Image} from 'components/general/Image';
import {Text} from 'components/general/Text';
import React from 'react';

export const InfoGraphicTextMobile = (props) => {

  return (

    <Container
      css={`
        padding: 8px 0;
        border-bottom: 1px solid ${Colors.TRANSPARENT.WHITE};
        align-items: center;
        ${props.css};
      `}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >

      <HorizontalContainer
        css={`
          gap: 12px;
          max-width: 95%;
          padding-bottom: 8px;
          align-items: center;
          overflow-wrap: break-word;
        `}
      >
        <Image
          height={props.imgHeight ?? '25px'}
          width={props.imgWidth ?? '25px'}
          src={props.src}
          alt={props.alt}
        />

        <Text
          css={`
            font-weight: 400;
            white-space: normal;
            max-width: 80%;
            ${props.textCss}
          `}
          text={props.title}
        />

      </HorizontalContainer>

      <Text
        css={`
          color: ${Colors.WHITE};
          font-weight: 300;
          word-break: break-word;
        `}
        text={props.description}
      />

    </Container>
  );
};