
export const Colors = {
  BLACK: '#000000',
  GREY: {
    VERY_LIGHT: '#DDDDDD',
    LIGHT: '#888888',
    TRANS_LIGHT: '#FFFFFF30',
    TRANS_DARK: '#88888860',
    DARK: '#BBBBBB',
  },
  GREEN: {
    LIME: '#89c43f',
    LIGHT: '#a0e060',
    DARK: '#0A5151',
    OFF_DARK: '#1b6277',
  },
  TRANSPARENT: {
    LIGHT: '#00000010',
    DARK: '#00000060',
    GREEN: {
      DARK: '#0A5151D0',
      LIGHT: '#a0e06044',
      LIME: '#89c43f55',
    },
    WHITE: '#FFFFFF28',
    VERY_WHITE: '#FFFFFF10',
    ORANGE: '#FFA50099',
    TRANSPARENT: '#00000000',
  },
  WHITE: '#FFFFFF',
  TRANS_WHITE: '#FFFFFFE0',
  RED: '#FF5757',
  ORANGE: '#E8BB4C',
  BLUE: {
    DARK: '#0000FF',
    LIGHT: '#ADD8E6',
  },
};

/**
 * Converts a hex color to its RGB components
 * @param {string} hex - The hex color code
 * @returns {Array} - Array containing RGB values
 */
const hexToRgb =(hex) => {

  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse r, g, b values
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [
    r,
    g,
    b,
  ];
};

/**
 * Converts RGB components to a hex color
 * @param {Array} rgb - Array containing RGB values
 * @returns {string} - The hex color code
 */
const rgbToHex = (rgb) => {

  const hex = rgb.map((value) => {

    const hex = value.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }).join('');
  return `#${hex}`;
};

/**
 * Generates a color gradient between two colors
 * @param {string} startColor - The starting color in hex format
 * @param {string} endColor - The ending color in hex format
 * @param {number} n - The number of intermediate colors to generate
 * @returns {Array} - Array of hex color codes
 */
export const generateColorGradient = (startColor, endColor, n) => {

  const startRgb = hexToRgb(startColor);
  const endRgb = hexToRgb(endColor);
  const colorGradient = [];

  for (let i = 0; i < n; i++) {

    const interpolatedColor = startRgb.map((startComponent, index) => {
      return Math.round(startComponent + (endRgb[index] - startComponent) * (i / (n - 1)));
    });
    colorGradient.push(rgbToHex(interpolatedColor));
  }

  return colorGradient;
};

export const makeTransparent = (color, trans) => {

  if (typeof trans === 'number') {

    trans = Math.round(trans * 255);

    if (trans > 255)
      trans = 255;
    if (trans < 0)
      trans = 0;
    trans = trans.toString(16);
  }

  return color+trans;
};

export const COLOR_PALETTE = [
  '#8AC440',
  '#079247',
  '#545454',
  '#A6A6A6',
  '#FFBD59',
  '#C1FF72',
  '#5CE1E6',
  '#0097B2',
];