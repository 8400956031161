import {Container} from 'components/containers';
import React from 'react';

export const HalfScroll = (props) => {

  return(
    <Container
      css={`
        width: 100%;
        height: 100%;
        ${props.css}
      `}
    >

      {/*wrapper*/}
      <Container css={`
        flex-direction: ${props.reverse ? 'row-reverse' : 'row'};
      `}
      >

        {/*sticky*/}
        <Container css={`
          flex: 1;
          ${props.stickyCss};
        `}
        >
          <Container
            css={`
              position: sticky;
              top: 64px;
            `}
          >
            {props.stickyComponent}
          </Container>
        </Container>

        {/*normal scroll*/}
        <Container css={`
          flex: 1;
          ${props.scrollCss};
        `}
        >
          {props.scrollItems}
        </Container>

      </Container>

    </Container>
  );
};