import {
  Auth0Provider,
  useAuth0,
} from '@auth0/auth0-react';
import {
  addDefaultHeader,
  Storage,
} from 'providers';
import React from 'react';

export const SecurityProvider = (props) => {

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}/profile`}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope={process.env.REACT_APP_AUTH0_SCOPE}
      useRefreshTokens={true}
      displayName={'Auth0 Context'}
    >
      <TokenRefresher />
      {props.children}
    </Auth0Provider>
  );
};

const TokenRefresher = () => {

  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();

  React.useEffect(() => {

    if(!user || !isAuthenticated || isLoading) {
      return;
    }

    const refresh = () => {

      getAccessTokenSilently()
        .then((res) => {

          addDefaultHeader('Authorization', `Bearer ${res}`);

          const exp = JSON.parse(atob(res.split('.')[1])).exp;
          setTimeout(refresh, new Date(exp * 1000) - new Date() - 30000);
        });
    };

    refresh();
  }, [
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  ]);

  return null;
};

export const useSecurity = () => {

  const {
    loginWithRedirect,
    logout,
    user,
    isAuthenticated,
    isLoading,
  } = useAuth0();
  const [authLoaded, setAuthLoaded] = React.useState(false);
  const [token, setToken] = React.useState(Storage.get('defaultHeaders')?.Authorization);

  React.useEffect(() => {

    if(!isLoading && (token || !user)) {

      setAuthLoaded(true);
      return;
    }

    //This beautiful piece of code is because the Auth header is loaded into Storage, and we need to ensure
    // that it is still loading if we're waiting for the TokenRefresher function to load the token into storage
    const reloadToken = () => {

      if(!Storage.get('defaultHeaders')?.Authorization) {

        setTimeout(reloadToken, 1000);
        return;
      }

      setToken(Storage.get('defaultHeaders').Authorization);
    };

    if(!token && user) {

      setAuthLoaded(false);
      setTimeout(reloadToken, 1000);
    }

  }, [
    token,
    user,
    isLoading,
  ]);

  const logoutFunc = () => {

    Storage.clear();
    return logout({returnTo: window.location.origin});
  };

  return {
    login: loginWithRedirect,
    logout: logoutFunc,
    user,
    isAuthenticated,
    isLoading: isLoading || !authLoaded,
  };
};