import {Colors} from 'appConstants';
import {Container} from 'components/containers';
import {NotificationContext} from 'components/modal/NotificationContext';
import React from 'react';

export const Notification = () => {

  const notificationContext = React.useContext(NotificationContext);

  if (!notificationContext.state) {
    return undefined;
  }

  return (
    <Container
      css={`
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 10000;
        display: flex;
        align-items: center;
        padding-top: 16px;
        background: ${Colors.TRANSPARENT.WHITE};
      `}
      onClick={notificationContext.clear}
    >
      <Container
        css={`
          width: 60%;
          height: 15vh;
        `}
      >

        {notificationContext.state}
      </Container>

    </Container>
  );
};