import {Colors} from 'appConstants';
import {
  LinkTextButton,
  TextButton,
} from 'components/buttons';
import {HorizontalContainer} from 'components/containers';
import {Text} from 'components/general/Text';
import React from 'react';

export const MultiPieceText = (props) => {

  const texts = [];
  let i = 0;
  for (const text of props.texts) {

    if (text.skip) {
      continue;
    }

    i++;

    if (typeof text === 'string') {

      texts.push(
        <Text
          text={text}
          key={`text-${i}`}
          css={`
            ${props.textCss};
            white-space: pre-wrap;
            display: inline;
          `}
        />,
      );
      continue;
    }

    if (text.link) {

      texts.push(
        <LinkTextButton
          textCss={`
            color: ${Colors.GREEN.LIGHT};
            ${props.textCss};
            ${text.css};
            display: inline;
          `}
          css={`
            margin: 0;
            padding: 0;
            display: inline;
          `}
          key={`text-${i}`}
          text={text.text}
          link={text.link}
          spinnerColor={Colors.GREEN.LIGHT}
        />,
      );

      continue;
    }

    if (!text.onClick) {

      texts.push(
        <Text
          text={text.text}
          key={`text-${i}`}
          css={`
            ${props.textCss};
            ${text.css};
            white-space: pre-wrap;
            display: inline;
          `}
        />,
      );
      continue;
    }

    texts.push(
      <TextButton
        textCss={`
          ${props.textCss};
          color: ${Colors.GREEN.LIGHT};
          ${text.css};
          display: inline;
        `}
        css={`
          margin: 0;
          padding: 0;
          display: inline;
        `}
        key={`text-${i}`}
        text={text.text}
        onClick={text.onClick}
        spinnerColor={Colors.GREEN.LIGHT}
      />,
    );
  }

  return (
    <HorizontalContainer
      {...props}
      css={`
        display: inline;
        flex-wrap: wrap;
        ${props.css}
      `}
    >
      {texts}
    </HorizontalContainer>
  );
};

export const MultiPieceTextOld = (props) => {

  const texts = [];
  let i = 0;
  for (const text of props.texts) {

    if (text.skip) {
      continue;
    }

    i++;

    if (typeof text === 'string') {

      texts.push(
        <Text
          text={text}
          key={`text-${i}`}
          css={`
            ${props.textCss};
            white-space: pre-wrap;
            display: inline;
          `}
        />,
      );
      continue;
    }

    if (text.link) {

      texts.push(
        <LinkTextButton
          textCss={`
            color: ${Colors.GREEN.LIGHT};
            ${props.textCss};
            ${text.css};
            display: inline;
          `}
          css={`
            margin: 0;
            padding: 0;
            display: inline;
          `}
          key={`text-${i}`}
          text={text.text}
          link={text.link}
          spinnerColor={Colors.GREEN.LIGHT}
        />,
      );

      continue;
    }

    if (!text.onClick) {

      texts.push(
        <Text
          text={text.text}
          key={`text-${i}`}
          css={`
            ${props.textCss};
            ${text.css};
            white-space: pre-wrap;
            display: inline;
          `}
        />,
      );
      continue;
    }

    texts.push(
      <TextButton
        textCss={`
          ${props.textCss};
          color: ${Colors.GREEN.LIGHT};
          ${text.css};
          display: inline;
        `}
        css={`
          margin: 0;
          padding: 0;
          display: inline;
        `}
        key={`text-${i}`}
        text={text.text}
        onClick={text.onClick}
        spinnerColor={Colors.GREEN.LIGHT}
      />,
    );
  }

  return (
    <HorizontalContainer
      {...props}
      css={`
        ${props.css}
      `}
    >
      {texts}
    </HorizontalContainer>
  );
};