import {
  Colors,
  TextStyles,
} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {FormContext} from 'components/form/FormContext';
import {FormInputLabel} from 'components/form/FormInputLabel';
import {FormInputTooltip} from 'components/form/FormInputTooltip';
import {
  Image,
  Text,
} from 'components/general';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  ${(props)=> props.css};
`;

const NUM_REGEX = /[0-9]+\.?[0-9]*/g;
const TEL_REGEX = /(^\+[0-9]{0,3}[0-9]{0,9}|[0-9]{0,10})/g; // Matches '+[0-3 numbers][0-9 numbers]' OR '[0-10 numbers]'

export const TextInput = (props) => {

  const formContext = React.useContext(FormContext);

  const inputRef = React.useRef(null);

  const handleChange = (e) => {

    let value = e.target.value;

    // Max length
    if (props.maxLength && value.length > props.maxLength) {

      return;
    }

    // Number input
    if (props.type === 'number') {

      value = value.match(NUM_REGEX)?.[0];

      //! As long as input ends with '.' the value will be a string
      if (value && !props.stringify && (String(value).slice(-1) !== '.')) {

        value = parseFloat(value);
      }

      if (props.maxValue && value > props.maxValue) {

        return;
      }
    }

    // Telephone number
    if (props.type === 'tel') {

      value = value.match(TEL_REGEX)?.[0];
    }

    if (value === '' || value === undefined) {

      value = null;
    }

    if (props.stringify && value !== null) {

      value = `${value}`;
    }

    if (props.onChange) {

      props.onChange({
        name: props.name,
        value,
      });
      return;
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: value,
    }));
  };

  return (
    <Container
      css={`
        ${props.css}
      `}
    >

      <HorizontalContainer
        css={`
          position: relative;
          padding: 0 4px;
          align-items: center;
          border-radius: 8px;
          height: 42px;
          transition: border-color 100ms;
          border: 2px solid ${Colors.TRANSPARENT.WHITE};
          background-color: ${Colors.TRANSPARENT.GREEN.DARK};

          /* Container styles when input is focused */
          &:has(input:focus) {
            border-color: ${Colors.GREEN.LIME};

            /* Show prefix and suffix */
            .affix {
              display: inline-block;
            }
          }

          /* Show prefix and suffix when input is not empty */
          &:has(input:not(:placeholder-shown)) .affix {
            display: inline-block;
          }

          /* Container styles when input is disabled */
          &:has(input:disabled) {

            background-color: ${Colors.TRANSPARENT.WHITE};

            /* Only move label to top if input is not empty */
            &:has(input:not(:placeholder-shown)) label {
              transform: translateY(-24px);
            }
          }

          ${props.inputContainerCss}
        `}
        onClick={props.onClick}
      >

        {props.prefix &&
          <Text
            text={props.prefix}
            className={'affix'}
            css={`
              white-space: nowrap;
              display: none;
              padding: 4px 2px 1px 4px;
              margin-right: 2px;
            `}
          />
        }

        <Input
          value={props.value ?? formContext.state[props.name] ?? ''}
          onChange={handleChange}
          onKeyDown={props.onKeyDown}
          placeholder={props.placeholder ?? ''}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          disabled={props.disabled}
          ref={inputRef}
          css={`
            ${TextStyles.BODY}
            font-size: 18px; // Prevent font size from shrhinking because of 'clamp()'
            font-family: "Josefin Sans", sans-serif;
            color: ${Colors.WHITE};

            transform: translateY(2px);
            background-color: transparent;
            border: none;
            padding: 2px 0 0 4px;
            outline: none;
            width: 100%;

            &::placeholder {
              color: ${Colors.GREY.TRANS_LIGHT};
            }

            &:not(:focus)::placeholder {
              color: transparent;
            }

            /* Set label styles when input element is focused or contains a value */
            &:focus ~ label, &:not(:placeholder-shown) ~ label {

              pointer-events: auto;
              transform: translate(2px, -16px);
              color: ${Colors.GREEN.LIME};
              font-size: 14px;

              &:before {
                position: absolute;
                left: 0;
                top: 7px;
                content: "";
                height: 4px;
                width: 100%;
                z-index: -1;
                background-color: ${Colors.GREEN.DARK};
                border-radius: 1px;
              }
            }

            &:disabled {
              background-color: transparent;
            }

            ${props.inputCss}
          `}
        />

        <FormInputLabel
          text={props.label}
          optionalText={props.required === false}
          css={`
            color: ${Colors.GREY.LIGHT};
            padding: 0 4px;
            max-width: calc(100% - (24px + ${props.suffixImage ? '18px' : '0px'}));
            border-radius:6px;

            position: absolute;
            top: 7px;
            transform: translateY(2px);
            pointer-events: none;
            transition-duration: 150ms;
            transition-property: color, font-size, transform;
            justify-self: start;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            /* Expand label if hovered */
            &:hover {
              overflow: visible;
              white-space: normal;
              background-color: ${Colors.GREEN.DARK};
            }

            ${props.labelCss}
          `}
        />

        {props.suffix &&
        <Text
          text={props.suffix}
          className={'affix'}
          css={`
            white-space: nowrap;
            display: none;
            padding: 2px 4px 0;
          `}
        />
        }

        {props.suffixImage &&
          <Image
            {...props.suffixImageProps}
            src={props.suffixImage}
            onClick={() => inputRef.current.focus()}
            css={`
              padding: 2px;
              margin-right: 2px;
              ${props.suffixImageProps?.css};
            `}
          />
        }

        {props.tooltip &&
          <FormInputTooltip
            text={props.tooltip}
            css={`
              position: absolute;
              top: -8px;
              right: -10px;
            `}
          />
        }

      </HorizontalContainer>

      {props.errorText &&
        <Text
          text={props.errorText}
          css={`
            color: ${Colors.RED};
            margin: 6px 0 0 4px;
          `}
        />
      }

      {props.hint &&
        <Text
          text={props.hint}
          css={`
            color: ${Colors.GREY.LIGHT};
            margin: 6px 0 0 4px;
          `}
        />
      }

    </Container>
  );
};

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
  css: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hint: PropTypes.string,
  inputContainerCss: PropTypes.string,
  inputCss: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  labelCss: PropTypes.string,
  maxLength: PropTypes.number,
  maxValue: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  required: PropTypes.bool,
  stringify: PropTypes.bool,
  suffix: PropTypes.string,
  suffixImage: PropTypes.string,
  suffixImageProps: PropTypes.object,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
};