
export const keysToObject = (form) => {

  let obj = {};

  for(const [key, value] of Object.entries(form)) {

    const keyArr = key.split('.');
    obj = addValue(keyArr, value, obj);
  }

  return obj;
};

const addValue = (keyArr, value, obj) => {

  if(keyArr.length === 1) {

    obj[keyArr[0]] = value;
    return obj;
  }

  const oldKey = keyArr.shift();

  if(obj[oldKey] === undefined){
    obj[oldKey] = {};
  }

  obj[oldKey] = addValue(keyArr, value, obj[oldKey]);
  return obj;
};
