import {
  TextStyles,
  Colors,
  displayDate,
} from 'appConstants';
import {
  Container,
  Text,
} from 'components';
import React from 'react';

export const Events = (props) => {

  const events = [];

  for (const item of props.events) {

    const onClick = props.onClickOverride
      ? props.onClickOverride
      : item.link
        ? () => window.open(item.link, '_blank', 'noopener,noreferrer')
        : undefined;
    const date = new Date(item.date);

    events.push(
      <Container
        key={item.id}
        css={`
          border-radius: 4px;
          border: ${Colors.WHITE} 1px solid;
          margin: 8px;
          padding: 16px;

          &:hover {
            border: 1px solid ${onClick ? Colors.GREEN.LIGHT : Colors.WHITE};
          }
        `}
        onClick={() => onClick(item)}
      >
        <Text
          text={item.title}
          css={`
            ${TextStyles.HEADING};
          `}
        />

        {item.description &&
          <Text
            text={item.description}
          />
        }

        <Text
          text={`Date: ${displayDate(date)}`}
          css={`
            padding: 8px 0;
          `}
        />

        <Text
          text={'Location:'}
        />

        <Text
          text={item.location}
        />

      </Container>,
    );
  }

  return (
    <Container>
      {events}
    </Container>
  );
};