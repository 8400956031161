import {
  Colors,
  TextStyles,
} from 'appConstants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  ${(props)=> props.css};
`;

const Span = styled.span`
  ${(props)=> props.css};
`;

export const FormInputLabel = (props) => {

  return (
    <Label
      css={`
        font-family: "Josefin Sans", sans-serif;
        color: ${Colors.WHITE};
        ${TextStyles.BODY}
        font-size: 18px; // Prevent font size from shrinking because of 'clamp()'

        ${props.css}
      `}
    >
      {props.text}
      {props.optionalText &&
        <Span
          css={`
            color: ${Colors.GREY.TRANS_LIGHT};
            ${props.optionalTextCss}
          `}
        >
          &nbsp;(optional)
        </Span>
      }
    </Label>
  );
};

FormInputLabel.propTypes = {
  css: PropTypes.string,
  optionalText: PropTypes.bool,
  optionalTextCss: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
