
import {Colors} from 'appConstants';
import {Button} from 'components/buttons';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {FormContext} from 'components/form/FormContext';
import {FormInputLabel} from 'components/form/FormInputLabel';
import {FormInputTooltip} from 'components/form/FormInputTooltip';
import {
  MultiPieceText,
  Text,
} from 'components/general';
import PropTypes from 'prop-types';
import React from 'react';

export const UploadButton = (props) => {

  const formContext = React.useContext(FormContext);
  const fileUploadRef = React.useRef(null);

  const upload = () => {
    fileUploadRef.current.click();
  };

  const inputChange = (e) => {

    e.preventDefault();

    if(props.onChange) {

      props.onChange({
        value: e.target.files[0],
        name: props.name,
      });
      return;
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: e.target.files[0],
    }));
  };

  const clearFile = () => {

    fileUploadRef.current.value = null;
    formContext.setState((prev) => ({
      ...prev,
      [props.name]: undefined,
    }));
  };

  return (
    <Container
      css={`
        ${props.css}
      `}
    >
      <HorizontalContainer>

        {props.label &&
          <FormInputLabel
            text={props.label}
            optionalText={props.required === false}
            css={`
              margin-right: ${props.tooltip ? '12px' : '0'};
              margin-bottom: 4px;
            `}
          />
        }

        {props.tooltip &&
          <FormInputTooltip
            text={props.tooltip}
            position={props.label ? 'right' : 'left'}
            css={`
              margin-left: ${props.label ? '0' : '-4px'};
              margin-top: -4px;
            `}
          />
        }

      </HorizontalContainer>

      {!formContext.state[props.name] && !props.value &&
        <Button
          text={props.buttonText}
          onClick={upload}
          disabled={props.disabled}
        />
      }

      {(formContext.state[props.name] || props.value) &&
        <MultiPieceText
          texts={[
            `${props.value?.name ?? formContext.state[props.name]?.name}`,
            {
              text: '  -  ',
              skip: props.disabled,
              css: '',
            },
            {
              text: 'Remove',
              onClick: clearFile,
              skip: props.disabled,
              css: `
                color: ${Colors.RED};
              `,
            },
          ]}
          css={`
            margin-top: 8px;
          `}
        />
      }
      <input
        key={'file'}
        type={'file'}
        ref={fileUploadRef}
        style={{display: 'none'}}
        onChange={inputChange}
        accept={props.accept ?? 'application/pdf'}
      />

      {props.errorText &&
        <Text
          text={props.errorText}
          css={`
            color: ${Colors.RED};
            margin: 6px 0 0 4px;
          `}
        />
      }

      {props.hint &&
        <Text
          text={props.hint}
          css={`
            color: ${Colors.GREY.LIGHT};
            margin: 6px 0 0 4px;
          `}
        />
      }

    </Container>
  );
};

UploadButton.displayName = 'UploadButton';

UploadButton.propTypes = {
  accept: PropTypes.string,
  buttonText: PropTypes.string,
  css: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hint: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.shape({
    lastModified: PropTypes.number,
    lastModifiedDate: PropTypes.any,
    name: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    webkitRelativePath: PropTypes.string,
  }),
  onChange: PropTypes.func,
};