import {
  Colors,
  makeTransparent,
} from 'appConstants';
import {Text} from 'components/general/Text';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Ul = styled.ul`
  ${(props)=> props.css};
`;
const Li = styled.li`
  color:#FFF;
  display:block;
  ${(props) => `background: ${props.current ? Colors.GREEN.LIME : props.completed ? makeTransparent(Colors.GREEN.LIME, 0.7) :makeTransparent(Colors.GREEN.LIME, 0.3)}`};
  text-decoration: none;
  position: relative;
  height: 40px;
  max-height: 40px;
  padding: 0 10px 0 5px;
  text-align: center;
  margin-right: 32px;
  margin-top: 8px;
  float: left;

  :before,
  :after{
    content: "";
    position: absolute;
    top: 0;
    ${(props) => `border: 0 solid ${props.current ? Colors.GREEN.LIME : props.completed ? makeTransparent(Colors.GREEN.LIME, 0.7) :makeTransparent(Colors.GREEN.LIME, 0.3)}`};
    border-width: 20px 10px;
    width: 0;
    height: 0;
  }
  
  :before{
    left: -20px;
    border-left-color: transparent;
  }
  :after{
    left: 100%;
    border-color: transparent;
    ${(props) => `border-left-color: ${props.current ? Colors.GREEN.LIME : props.completed ? makeTransparent(Colors.GREEN.LIME, 0.7) :makeTransparent(Colors.GREEN.LIME, 0.3)}`};
  }
  ${(props)=> props.css};
`;

export const BreadCrumbs = (props) => {

  const items = [];

  const currentItem = props.items.find((item) => {
    return item.current;
  });
  let completed = !!currentItem;

  for (const item of props.items) {

    items.push(
      <Li
        key={item.text}
        current={item.current}
        completed={completed}
        onClick={props.clickable ? item.onClick : undefined}
      >
        <Text
          text={item.text}
          css={`
            margin: 8px 0 0;
          `}
        />
      </Li>,
    );

    if (item.current) {
      completed = false;
    }
  }

  return (
    <Ul
      css={`
        list-style:none;
        display: inline-block;
      `}
    >
      {items}

    </Ul>
  );
};

BreadCrumbs.propTypes = {
  clickable: PropTypes.bool,
  completed: PropTypes.bool,
  current: PropTypes.bool,
  items: PropTypes.array,
};