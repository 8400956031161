import {TextStyles} from 'appConstants';
import {Container} from 'components/containers';
import {FormSummary} from 'components/form/FormSummary';
import {Text} from 'components/general';
import PropTypes from 'prop-types';
import React from 'react';

export const FormSummaryMulti = (props) => {

  const summaries = [];

  for(const [key, items] of Object.entries(props.items)) {

    const values = props.values?.[key] ?? props.values ?? {};

    summaries.push(
      <FormSummary
        key={key}
        items={items}
        title={props.titles?.[key]}
        values={values}
        canEdit={props.canEdit}
        onEdit={(formState) => props.onEdit({
          ...formState,
          type: key,
        })}
        dynamicOpts={props.dynamicOpts}
        css={props.css}
      />,
    );
  }

  return (
    <Container
      css={`
        padding: 16px 32px 32px;
        overflow: hidden;
        flex: 1;
        gap: 8px;
        ${props.css}
      `}
    >

      {props.title &&
        <Text
          text={props.title}
          css={`
            ${TextStyles.HEADING};
            padding-bottom: 16px;
          `}
        />
      }

      {summaries}

    </Container>
  );
};

FormSummaryMulti.propTypes = {
  canEdit: PropTypes.bool,
  css: PropTypes.string,
  dynamicOpts: PropTypes.object,
  items: PropTypes.array,
  title: PropTypes.string,
  titles: PropTypes.array,
  values: PropTypes.object,
  onEdit: PropTypes.func,
};

FormSummaryMulti.defaultTypes = { title: 'Summary' };