import {Colors} from 'appConstants';
import {ScreenSize} from 'appConstants/ScreenSize';
import {HorizontalContainer} from 'components';
import React from 'react';

const validators = {email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/};

const isInputValid = ({
  item,
  data,
}) => {

  let invalid = false;

  // pattern
  if (
    item.pattern
    && data[item.name]
    && !validators[item.pattern].test(data[item.name])
  ) {
    invalid = true;
  }

  // minLength
  if (
    item.minLength
    && data[item.name]
    && data[item.name].length < item.minLength
  ) {
    invalid = true;
  }

  // maxLength
  if (
    item.maxLength
    && data[item.name]
    && data[item.name].length > item.maxLength
  ) {
    invalid = true;
  }

  // Function resolved in above for-loop over [key, val] in 'addItem' function
  if (item.valid === false) {
    invalid = true;
  }

  let errorText;
  if (typeof item.valid === 'string') {

    invalid = true;
    errorText = item.valid;
  }

  if(!item.displayName) {

    return {};
  }

  return {
    invalid,
    errorText,
  };
};

export const processFormItem = ({
  item,
  data,
  dynamicOpts = {},
  checkVals = {},
  prependName,
}) => {

  if(dynamicOpts?.[item.name]) {

    item = {
      ...item,
      ...dynamicOpts?.[item.name],
    };
  }

  let visible = true;
  if (
    (typeof item.visible === 'function' && !item.visible(data))
    || item.visible === false
  )  {

    visible = false;
    return {visible};
  }

  // Resolve functions
  const newItem = {...item};
  for(const [key, val] of Object.entries(item)) {

    if(key === 'component' || key === 'onClick') {
      continue;
    }

    if (typeof val === 'function')  {
      newItem[key] = val(data);
    }
  }

  // Update name if relevant
  if (newItem.name && prependName) {
    newItem.name = `${prependName}.${newItem.name}`;
  }

  if (newItem.name
    && newItem.required !== false
    && (
      data[newItem.name] === null
      || data[newItem.name] === undefined
    )
  ) {
    checkVals.requiredCompleted = false;
  }

  const {
    invalid,
    errorText,
  } = isInputValid({
    item: newItem,
    data,
  });

  if (invalid) {

    checkVals.invalid = true;
    checkVals.requiredCompleted = false;
  }

  return ({
    visible,
    processedItem: newItem,
    invalid,
    errorText,
    checkVals,
  });
};

const addItem = ({
  item,
  formItems,
  formContext,
  dynamicOpts,
  disabled,
  checkVals,
  prependName,
}) => {

  const {
    visible,
    processedItem,
    invalid,
    errorText,
  } = processFormItem({
    item,
    data: formContext.state,
    dynamicOpts,
    checkVals,
    prependName,
  });

  if (!visible) {
    return;
  }

  const Component = item.component;

  formItems.push(
    <Component
      key={processedItem.name ?? processedItem.text ?? processedItem.texts?.[0]?.text ?? processedItem.texts?.[0]}
      {...processedItem}
      disabled={disabled ?? processedItem.disabled ?? false}
      required={processedItem.required ?? true}
      invalid={invalid}
      errorText={errorText}
      css={`
          padding: 8px;
          font-weight: 300;
          border-radius: 12px;
          transition: margin 100ms;
          flex : 1;

          ${invalid ? `
            box-shadow: 0 0 0 1px ${Colors.RED};
            margin: 4px 0;
          ` : ''}

          ${processedItem.css};
        `}
    />,
  );
};

const addInnerItems = ({
  item,
  formItems,
  currentView,
  formContext,
  dynamicOpts,
  disabled,
  checkVals,
  prependName,
}) => {

  const innerItems = [];

  for (const innerItem of item) {

    addItem({
      item: innerItem,
      formItems: innerItems,
      formContext,
      dynamicOpts,
      disabled,
      checkVals,
      prependName,
    });
  }

  formItems.push(
    <HorizontalContainer
      key={`row-${item[0].name}`}
      css={`
        ${currentView === ScreenSize.MOBILE && 'flex-direction: column;'};
        align-items: start;
        gap: 8px;
      `}
    >
      {innerItems}
    </HorizontalContainer>,
  );
};

const addGroupItems = ({
  items,
  formItems,
  formContext,
  dynamicOpts,
  disabled,
  checkVals,
  currentView,
  prependName,
}) => {

  for (const item of items ?? []) {

    if (Array.isArray(item)){

      addInnerItems({
        item,
        formItems,
        formContext,
        dynamicOpts,
        disabled,
        checkVals,
        currentView,
        prependName,
      });
      continue;
    }

    if (item.isGroup && item.visible(formContext.state)) {

      addGroupItems({
        items: item.items,
        formItems,
        formContext,
        dynamicOpts,
        disabled,
        checkVals,
        prependName,
      });
      continue;
    }

    addItem({
      item,
      formItems,
      formContext,
      dynamicOpts,
      disabled,
      checkVals,
      prependName,
    });
  }
};

export const processFormConfig = ({
  items,
  formItems,
  prePopVals = {},
  formContext,
  prePopulateValues,
  dynamicOpts,
  disabled,
  checkVals,
  currentView,
  prependName,
}) => {

  addGroupItems({
    items,
    formItems,
    prePopVals,
    formContext,
    prePopulateValues,
    dynamicOpts,
    disabled,
    checkVals,
    currentView,
    prependName,
  });
};