import {fetcher} from 'providers/fetcher';

export const generateNdvi = ({
  companyId,
  userId,
  startYear,
}) => {

  return fetcher(`/api/v2/admin/dataprocessing/ndvi/generate/${companyId}`, {
    removeCachingHeaders: true,
    query: {
      requestedBy: userId,
      initialYear: startYear,
    },
  });
};