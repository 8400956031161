import {
  Colors,
  ScreenSize,
  TextStyles,
} from 'appConstants';
import {Container} from 'components/containers';
import {ScreenContext} from 'context';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const P = styled.p`
  box-sizing: border-box;
  ${(props)=> props.onClick ? 'cursor: pointer;' : ''}
  ${(props)=> props.css};
`;

const listItemRegex = /\n-.*\n/g;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  ${(props) => props.css};
`;

export const Text = React.forwardRef(function Text(props, ref) {

  let newStr = props.text;
  const listItems = [];
  const {currentView} = React.useContext(ScreenContext);
  const isMobile = currentView === ScreenSize.MOBILE;

  // Allows <li> to be generated from strings
  if(props.text?.match?.(listItemRegex)) {

    newStr = props.text.split(listItemRegex)[0];

    for (const li of props.text.match(listItemRegex)) {

      let text = li.slice(3, -2);
      text = text.replaceAll('(/n)', '\n');

      listItems.push(
        <li
          style={{listStylePosition: 'outside'}}
          key={`li-${listItems.length}`}
        >
          {text}
        </li>,
      );
    }
  }

  if (listItems.length > 0) {

    return (
      <Container
        css={`
          white-space: pre-line;
          font-family: "Josefin Sans", sans-serif;
          color: ${Colors.WHITE};
          margin: 3px 0 0;
          ${TextStyles.BODY};
          ${props.css};
        `}
      >
        {newStr &&
          <P
            {...props}
            ref={ref}
          >
            {newStr}
          </P>
        }

        <Ul
          css={`
              ${isMobile ? 'padding-inline-start: 20px;' : ''}
              ${props.listCss};
          `}
        >
          {listItems}
        </Ul>
      </Container>
    );
  }

  return (
    <P
      {...props}
      css={`
        white-space: pre-line;
        font-family: "Josefin Sans", sans-serif;
        color: ${Colors.WHITE};
        margin: 3px 0 0;
        ${TextStyles.BODY};
        ${props.css};
      `}
      ref={ref}
    >
      {newStr}
    </P>
  );
});

Text.propTypes = {text: PropTypes.oneOfType([PropTypes.string, PropTypes.number])};

Text.defaultProps = {text: null};