import React from 'react';

export const useOutsideClick = (ref, callback) => {

  React.useEffect(() => {

    const handleClick = (e) => {

      if (ref.current && !ref.current.contains(e.target)) {
        callback(e);
      }
    };

    document.addEventListener('mouseup', handleClick);

    return () => {
      document.removeEventListener('mouseup', handleClick);
    };
  }, [ref, callback]);
};