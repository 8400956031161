
import {
  Colors,
  ScreenSize,
} from 'appConstants';
import {
  Button,
  LinkTextButton,
  TextButton,
} from 'components/buttons';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {
  Image,
  Text,
  Tooltip,
} from 'components/general';
import {Resources} from 'compositeComponents/Resources';
import {
  ScreenContext,
  useSecurity,
} from 'context';
import {getResources} from 'providers/ResourcesProvider';
import React from 'react';
import {useNav} from 'routes';

export const Header = (props) => {

  const stickyRef = React.useRef(null);

  const [blur, setBlur] = React.useState(0);
  const [resources, setResources] = React.useState(null);
  const [showMenu, setShowMenu] = React.useState(false);

  const {
    login,
    isAuthenticated,
    isLoading,
    user,
  } = useSecurity();
  const navigate = useNav();
  const {currentView} = React.useContext(ScreenContext);

  const scroll = () => {

    if (window.pageYOffset === 0) {
      setBlur(0);
    } else if (window.pageYOffset < 8) {
      setBlur(1);
    } else if (window.pageYOffset < 16) {
      setBlur(2);
    } else if (window.pageYOffset < 24) {
      setBlur(3);
    } else if (window.pageYOffset < 32) {
      setBlur(4);
    } else if (window.pageYOffset < 40) {
      setBlur(5);
    } else if (window.pageYOffset > 48){
      setBlur(10);
    }
  };

  React.useEffect(() => {

    window.addEventListener('scroll', scroll, true);

    getResources()
      .then((res) => {

        if(!res.ok) {

          console.error('There was a problem getting the resources', res);
          return;
        }

        setResources(res.data);
      })
      .catch((err) => {
        console.error('An error occurred getting events', err);
      });

    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  const resourcesButton = (
    <TextButton
      text={'Resources'}
      css={`
        margin-right: 12px;
      `}
      textCss={'font-weight: 400;'}
    />
  );

  const signIn = () => {

    login()
      .catch((err) => {
        console.error('Login failure', err);
      });
  };

  const verifyEmail = () => {
    navigate('/verify');
  };

  const viewProfile = () => {
    navigate('/profile');
  };

  const mobileLogin = () => {

    if (!isAuthenticated && !isLoading) {
      return signIn();
    } else if (isAuthenticated && !isLoading && !user.email_verified) {
      return verifyEmail();
    } else if (isAuthenticated && !isLoading && user.email_verified) {
      return viewProfile();
    }
  };

  const changeMenu = () => {

    setShowMenu((prev) => !prev);
  };

  const isMobile = currentView === ScreenSize.MOBILE;

  const desktopMenu = [
    <Tooltip
      component={resourcesButton}
      tooltipWidth={350}
      key={'resources_item'}
      position={'left'}
      openOnHover={true}
      tooltipBackground={Colors.GREEN.DARK}
      tooltipBorder={Colors.GREEN.LIME}
      addRadius={true}
    >
      {resources &&
        <Resources
          items={resources}
        />
      }

      <Container
        css={`
          margin: 0;
          padding: 12px 0 8px;
        `}
      >

        <Text
          text={'Learn'}
          css={`
            text-decoration: underline;
            font-weight: 400;
          `}
        />

        <LinkTextButton
          text={'Blog'}
          link={'/blog/index.html'}
          css={`
            margin: 0;
            padding: 4px 0 8px 12px;
          `}
        />

      </Container>

      <Container
        css={`
          margin: 0;
          padding: 12px 0 8px;
        `}
      >

        <Text
          text={'Tools'}
          css={`
            text-decoration: underline;
            font-weight: 400;
          `}
        />

        <LinkTextButton
          text={'Earnings potential'}
          link={'/earnings-potential'}
          css={`
            margin: 0;
            padding: 2px 0 8px 12px;
          `}
        />

        { isAuthenticated && !isLoading && user.email_verified &&
          <LinkTextButton
            text={'Carbon Calculator'}
            link={'/profile/calc'}
            css={`
              margin: 0;
              padding: 2px 0 8px 12px;
            `}
          />
        }

      </Container>

    </Tooltip>,

    <LinkTextButton
      text={'FAQs'}
      link={'/faqs'}
      css={`
        margin-right: 12px;
        height: fit-content;
      `}
      textCss={'font-weight: 400;'}
      key={'faq_item'}
    />,

    props.contactUsOnClick ?
      <TextButton
        text={'Contact Us'}
        onClick={props.contactUsOnClick}
        css={`
          margin-right: 12px;
          height: fit-content;
        `}
        textCss={'font-weight: 400;'}
        key={'contactus_item'}
      /> : undefined,

  ];

  return (
    <Container
      css={`
        height: 64px;
        max-height: 64px;
        width: 100%;
        position: sticky;
        top: 0;
        backdrop-filter: blur(${blur}px);
        z-index: 9998;
      `}
      ref={stickyRef}
    >
      <HorizontalContainer
        css={`
          align-items: center;
          position: relative;
          padding: ${isMobile ? '8px' : '32px'};
          height: 100%;
          width: 100%;
        `}
      >

        {isMobile &&
          <Image
            height={'30px'}
            width={'30px'}
            src={'BurgerMenu'}
            stroke={Colors.WHITE}
            alt={'menu'}
            css={`
              padding: 0 8px;
            `}
            onClick={changeMenu}
          />
        }

        {isMobile && showMenu &&
          <Container
            css={`
              background: ${Colors.GREY.TRANS_DARK};
              width: 100vw;
              height: 100vh;
              position: absolute;
              top: 0;
              left: 0;
            `}
            onClick={changeMenu}
            onSc
          >
            <Container
              css={`
                align-items: flex-start;
                position: absolute;
                background: ${Colors.GREEN.DARK};
                border-radius: 0 8px 8px 0;
                padding: 16px 32px 16px 16px;
                left: 0;
                top: 64px;
                width: 80vw;
                gap: 8px;
                height: calc(100% - 64px); // 64px = Header height
                width: fit-content;
              `}
            >

              {resources &&
                <>
                  <Text
                    text={'Resources'}
                    css={'font-weight: bolder;'}
                  />
                  <Container
                    css={'padding: 0 0 0 0;'}
                  >
                    <Resources
                      items={resources}
                      dropCategories={true}
                      addText={'>'}
                    />
                  </Container>
                </>
              }

              <Container>

                <Text
                  text={'Tools'}
                  css={'font-weight: bolder;'}
                />

                <LinkTextButton
                  text={'Earnings potential'}
                  link={'/earnings-potential'}
                  css={`
                  margin: 0;
                  padding: 4px 0 0 12px;
                `}
                />

                { isAuthenticated && !isLoading && user.email_verified &&
                  <LinkTextButton
                    text={'Carbon Calculator'}
                    link={'/profile/calc'}
                    css={`
                      margin: 0;
                      padding: 2px 0 8px 12px;
                    `}
                  />
                }
              </Container>

              <Container>

                <Text
                  text={'Learn'}
                  css={'font-weight: bolder;'}
                />

                <LinkTextButton
                  text={'Blog'}
                  link={'/blog/index.html'}
                  css={`
                    margin: 0;
                    padding: 4px 0 0 12px;
                    width: fit-content;
                  `}
                />

                <LinkTextButton
                  text={'FAQs'}
                  link={'/faqs'}
                  css={`
                    margin: 0;
                    padding: 4px 0 0 12px;
                  `}
                  key={'faq_item'}
                />
              </Container>

              {props.contactUsOnClick &&
                <TextButton
                  text={'Contact Us'}
                  onClick={props.contactUsOnClick}
                  css={`
                  margin-right: 12px;
                  height: fit-content;
                `}
                  key={'contactus_item'}
                />
              }
            </Container>

          </Container>
        }

        <a
          style={{
            textDecoration: 'none',
            flex: 1,
          }}
          href={'/'}
          aria-label={'Logo redirecting to home page'}
        >
          <Image
            src={'OrizonLogo'}
            alt={'Orizon logo'}
            height={'40px'}
            width={'160px'}
            css={`${isMobile && 'align-items: center'};`}
          />
        </a>

        {isMobile &&
          <Container
            css={`
              box-shadow: 0 0 0 2px ${Colors.GREEN.LIME};
              background-color: ${Colors.TRANSPARENT.TRANSPARENT};
              border-radius: 32px;
              padding: 8px;
              transition: box-shadow 100ms, background-color 100ms;

              &:active {
                box-shadow: 0 0 0 3px ${Colors.GREEN.LIME};
                background-color: ${Colors.TRANSPARENT.GREEN.DARK}
              }

            `}
            onClick={mobileLogin}
          >

            <Image
              src={'Farmer'}
              alt={'Farmer profile icon'}
              width={'24px'}
              height={'24px'}
            />
          </Container>
        }

        {!isMobile &&
          <HorizontalContainer
            css={`
            align-items: center;
          `}
          >

            {!isMobile && desktopMenu}

            {!isAuthenticated && !isLoading &&
              <Button
                text={'Sign In'}
                onClick={signIn}
                css={`
                  padding: 4px 16px;
                `}
                textCss={'font-weight: 400;'}
              />
            }

            {isLoading &&
              <Button
                text={'Signing in...'}
                disabled={true}
                css={`
                  padding: 4px 16px;
                `}
                textCss={'font-weight: 400;'}
              />
            }

            {isAuthenticated && !isLoading && !user.email_verified &&
              <Button
                text={'Profile'}
                onClick={verifyEmail}
                css={`
                padding: 4px 16px;
              `}
                textCss={'font-weight: 400;'}
              />
            }

            {isAuthenticated && !isLoading && user.email_verified &&
              <Button
                text={'Profile'}
                onClick={viewProfile}
                css={`
                  padding: 4px 16px;
                `}
                textCss={'font-weight: 400;'}
              />
            }
          </HorizontalContainer>
        }

      </HorizontalContainer>

    </Container>
  );
};