import {
  Colors,
  TextStyles,
  makeTransparent,
} from 'appConstants';
import {
  Button,
  Text,
} from 'components';
import { Container } from 'components/containers';
import {
  Image,
  MultiPieceText,
} from 'components/general';
import PropTypes from 'prop-types';
import React from 'react';

export const ActivateJourneyPrompt = (props) => {

  const items = [];
  for (const item of props.items) {

    items.push(
      <Container
        key={`journey-item-${items.length}`}
        css={`
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          min-height: 48px;
        `}
      >

        <Image
          src={item.iconSrc}
          alt={item.altText}
          height={item.iconHeight ?? '40px'}
          width={item.iconWidth ?? '40px'}
          css={`
              align-items: center;
              flex-basis: 50px;
            `}
        />

        {item.text &&
          <Text
            text={item.text}
            css={`
              margin: 4px 0;
            `}
          />
        }

        {item.texts &&
          <MultiPieceText
            texts={item.texts}
            css={`
              flex: 1;
              margin: 4px 0;
            `}
          />
        }

      </Container>,
    );
  }

  return (
    <Container
      css={`
        padding: ${props.isMobile ? '24px' : '32px'};
        background-color: ${Colors.GREEN.DARK};
        border: 2px solid ${Colors.TRANSPARENT.TRANSPARENT};
        border-radius: 4px;
        box-shadow: 0 12px 32px ${makeTransparent(Colors.BLACK, 0.075)}, 0 0 0 2px ${props.disabled ? Colors.GREY.DARK : props.journeyStarted ? Colors.ORANGE : Colors.GREEN.LIME};
        transition: box-shadow 200ms;

        ${!(props.disabled || props.journeyStarted) ? `&:hover {
          box-shadow: 0 12px 32px ${Colors.BLACK}11, 0 0 0 3px ${Colors.GREEN.LIME};
        }` : ''}

        ${props.css}
      `}
    >

      <Text
        css={`
          ${TextStyles.SMALL_HEADING};
          margin-bottom: 8px;
        `}
        text={props.heading}
      />

      <Text
        text={props.description}
        css={`
          margin-bottom: 12px;
        `}
      />

      <Container
        css={`
          gap: 4px;
          margin-bottom: 24px;
        `}
      >
        {items}
      </Container>

      <Button
        text={props.journeyStarted ? 'Active' : 'Activate Journey'}
        css={`
          min-width: 30%;
          height: 50px;
          margin: 0 auto;

          ${!(props.disabled || props.journeyStarted) ? `transition: box-shadow 150ms;

          &:hover {
            box-shadow: inset 0 0 0 1px ${Colors.TRANS_WHITE};
          }

          &:active {
            transition: box-shadow 100ms;
            box-shadow: none;
          }` : ''}
          ${props.buttonCss}
        `}
        textCss={props.buttonTextCss}
        disabled={props.disabled || props.journeyStarted}
        onClick={props.onActivateJourney}
      />

      {props.disabled && props.expressed.length === 0 &&
        <MultiPieceText
          texts={[
            'We are still in the process of setting up for this project. If you are interested in joining this programme once we have registered it, please ',
            {
              text: 'express your interest by filling out this short form',
              onClick: props.onExpressInterestClick,
            },
            '.',
          ]}
          css={`
            margin: 30px 0 -10px;
          `}
        />
      }

      {props.disabled && props.expressed.length > 0 &&
        <Text
          text={'You have already expressed interest in this programme, we will keep you updated with it\'s progress.'}
          css={`
            margin: 30px 0 -10px;
          `}
        />
      }

    </Container>
  );
};

ActivateJourneyPrompt.propTypes = {
  buttonCss: PropTypes.string,
  buttonTextCss: PropTypes.string,
  css: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  expressed: PropTypes.array,
  heading: PropTypes.string,
  isMobile: PropTypes.bool,
  items: PropTypes.array,
  journeyStarted: PropTypes.bool,
  onActivateJourney: PropTypes.func,
  onExpressInterestClick: PropTypes.func,
};

ActivateJourneyPrompt.defaultProps = {expressed: []};