import {
  Colors,
  TextStyles,
} from 'appConstants';
import {Container} from 'components/containers';
import {
  Form,
  FormContext,
  FormSummary,
} from 'components/form';
import {
  Image,
  SteppedProgressBar,
  Text,
} from 'components/general';
import React from 'react';
import {getCurrentFormPage} from 'utils/getCurrentFormPage';

export const MultiSectionForm = (props) => {

  const formContext = React.useContext(FormContext);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [lastCompletedPage, setLastCompletedPage] = React.useState(0);

  const handleChangePage = (page) => {

    // Navigate to form page only if that page has already been completed
    if (lastCompletedPage >= page) {

      setCurrentPage(page);
      formContext.clear();
    }
  };

  const formPageHasInputComponent = (page) => {

    for (const component of page) {

      if (component.name) {
        return true;
      }
    }

    return false;
  };

  const handleSubmit = (formState) => {

    const pageNum = currentPage < props.items.length - 1 ? currentPage + 1 : currentPage;

    if (!formPageHasInputComponent(props.items[currentPage])) {

      setCurrentPage(pageNum);
      setLastCompletedPage((prev) => prev > pageNum ? prev : pageNum);
      return;
    }

    return props.onSubmit(formState, currentPage)
      .then((res) => {

        // Relies on parent component passing in the 'res' object from it's '.then()' function
        if (!res || !res.ok) {
          return;
        }

        setCurrentPage(pageNum);
        setLastCompletedPage((prev) => prev > pageNum ? prev : pageNum);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

      }).catch();
  };

  React.useEffect(() => {

    // Navigate user to the first page of the form where data needs to be filled in
    if (props.currentPage === undefined) {

      const page = getCurrentFormPage(props.items, props.values ?? {});
      setCurrentPage(page);
      setLastCompletedPage(page);
      return;
    }

    setCurrentPage(props.currentPage);
    setLastCompletedPage(props.currentPage);
  }, [props.currentPage]);

  return (
    <Container
      css={`
        container-type: inline-size;
        position: relative;
        border: ${Colors.GREY.TRANS_LIGHT} solid 2px;
        border-radius: 10px;
        padding: clamp(24px, 4vw, 32px);
        flex: 1;
        ${props.css}
      `}
    >

      {props.title &&
        <Text
          text={props.title}
          css={`
            ${TextStyles.HEADING};
          `}
        />
      }

      <SteppedProgressBar
        stepsCount={props.items.length}
        currentStep={currentPage + 1}
        lastCompletedStep={lastCompletedPage}
        onClick={props.currentPage === undefined ? handleChangePage : undefined}
      />

      {currentPage >= lastCompletedPage &&
        <Form
          {...props}
          title={undefined}
          items={props.items[currentPage]}
          onSubmit={props.overrideSubmit ?? (props.onSubmit ? handleSubmit : undefined)}
          submitText={props.submitText ?? 'Proceed'}
          css={`
            border: none;
            padding: 0;
          `}
          submitButtonCss={`
            margin-top: 20px;
          `}
        />
      }

      {currentPage < lastCompletedPage &&
        <FormSummary
          items={props.items[currentPage]}
          values={props.values}
          css={`
            border: none;
            padding: 0;
          `}
        />
      }

      {props.children }

      {props.currentPage === undefined && currentPage > 0 &&
        <Container
          onClick={() => setCurrentPage((prev) => prev - 1)}
          css={`
            position: absolute;
            left: 0;
            top: 240px;
            transform: translate(-22px, -22px);
            height: 44px;
            width: 44px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: ${Colors.GREEN.DARK};
            border: 2px solid ${Colors.GREEN.LIME};
          `}
        >
          <Image
            src={'ArrowLeft'}
            height={'24px'}
            width={'24px'}
            css={`
              transition: scale 75ms;

              &:active {
                scale: 0.95;
              }
            `}
          />
        </Container>
      }

      {props.currentPage === undefined && currentPage < lastCompletedPage && currentPage < props.items.length - 1 &&
        <Container
          onClick={() => setCurrentPage((prev) => prev + 1)}
          css={`
            position: absolute;
            right: 0;
            top: 240px;
            transform: translate(22px, -22px);
            height: 44px;
            width: 44px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: ${Colors.GREEN.DARK};
            border: 2px solid ${Colors.GREEN.LIME};
          `}
        >
          <Image
            src={'ArrowRight'}
            height={'24px'}
            width={'24px'}
            css={`
              transition: scale 75ms;

              &:active {
                scale: 0.95;
              }
            `}
          />
        </Container>
      }

    </Container>
  );
};
