import {
  BreadCrumbs,
  Button,
  Container,
} from 'components';
import React from 'react';

export const BreadCrumbPage = (props) => {

  const [currentPos, setCurrentPos] = React.useState(props.startingPos ?? 0);

  const next = () => {

    if( props.sections[currentPos].onNextClick) {

      return props.sections[currentPos].onNextClick()
        .then(({shouldNotRun} = {}) => {

          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });

          if (shouldNotRun) {
            return;
          }

          setCurrentPos(currentPos+1);
        });
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setCurrentPos(currentPos+1);
  };

  const breadCrumbs = [];
  for(let i=0; i < props.sections.length; i++) {

    const section = props.sections[i];
    breadCrumbs.push({
      text: section.breadCrumb,
      current: currentPos === i,
    });
  }

  const currentSection = props.sections[currentPos];
  const disabled = typeof currentSection.nextClickDisabled === 'function'
    ? currentSection.nextClickDisabled()
    : false;

  return (
    <Container
      css={`
        padding: 16px 0;
      `}
    >

      <BreadCrumbs
        items={breadCrumbs}
      />

      <Container
        css={`
          padding: 16px 0 32px;
        `}
      >
        {currentSection.component}
      </Container>

      {(currentPos < props.sections.length -1) &&
        <Button
          text={props.nextText ?? 'Next'}
          disabled={disabled}
          onClick={currentSection.doneClick ?? next}
        />
      }
    </Container>
  );
};