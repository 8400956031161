import {Container} from 'components/containers';
import React from 'react';
import { PopupButton } from 'react-calendly';

export const CalendlyModal = (props) => {

  const ref = React.useRef(null);

  return (
    <Container
      css={'width: fit-content;'}
    >
      <PopupButton
        url="https://calendly.com/orizonagriculture/30min?embed_domain=orizonagriculture.com&embed_type=PopupText"
        rootElement={document.getElementById('root')}
        ref={ref}
        text={'text'}
        styles={{display: 'none'}}
      />
      <Container
        onClick={(e) => {
          ref.current.onClick(e);
        }}
      >
        {props.triggerElement}
      </Container>

    </Container>
  );
};
