import {
  Container,
  Form,
  FormContext,
} from 'components';
import {mapFieldsDataConfig} from 'compositeComponents/addFields/mapFieldsDataConfig';
import PropTypes from 'prop-types';
import React from 'react';

export const MapFieldsDataForm = (props) => {

  const formContext = React.useContext(FormContext);

  const submit = (formState) => {

    delete formState.eligibility.all;
    return props.submit(formState);
  };

  React.useEffect(() => {

    const allEligibility = formContext.state['eligibility.all'];

    if(!allEligibility) {
      return;
    }

    formContext.setState((prev) => {

      return {
        ...prev,
        'eligibility.wetland': allEligibility,
        'eligibility.ecosystemCleared': allEligibility,
        'eligibility.biochar': allEligibility,
        'eligibility.cropland': allEligibility,
        'eligibility.sustainable': allEligibility,
      };
    });
  }, [formContext.state['eligibility.all']]);

  return(
    <Container>
      <Form
        title={'Please enter all the information for this field'}
        items={mapFieldsDataConfig}
        onSubmit={submit}
        disabled={props.disableForm}
        dynamicOpts={{farmId: {options: props.farms}}}
        back={props.back}
        backText={'Close'}
        prePopulateValues={props.initialValues}
      />
    </Container>
  );
};

MapFieldsDataForm.propTypes = {
  area: PropTypes.number,
  back: PropTypes.func,
  disableForm: PropTypes.bool,
  farms: PropTypes.array,
  initialValues: PropTypes.object,
  submit: PropTypes.func,
};