//* Font size starts shrinking when viewport is at ~1024px, and stops at ~320px

export const TextStyles = {
  LARGE_HEADING: `
    font-size: clamp(35px, 32px + 1vw, 42px);
    line-height: 1.4;
    font-weight: 700;
  `,
  HEADING: `
    font-size: clamp(28px, 25px + 1vw, 35px);
    line-height: 1.4;
    font-weight: 600;
  `,
  SMALL_HEADING: `
    font-size: clamp(18px, 16px + 0.6vw, 22px);
    line-height: 1.4;
    font-weight: 500;
  `,
  SUB_HEADING: `
    font-size: clamp(16px, 14px + 0.6vw, 20px);
    line-height: 1.4;
    font-weight: 400;
  `,
  BODY: `
    font-size: clamp(14px, 12px + 0.6vw, 18px);
    line-height: 1.4;
    font-weight: 300;
  `,
};
