import {Colors} from 'appConstants';
import {Button} from 'components/buttons/Button';
import React from 'react';

export const PillButton = (props) => {

  return(
    <Button
      {...props}
      css={`
        background: ${props.disabled ? Colors.TRANSPARENT.GREEN.LIME : Colors.TRANSPARENT.TRANSPARENT};
        padding: 6px;
        ${props.css};
        border-radius: 999px;
        text-align: center;
        border: 1px solid  ${props.disabled ? Colors.TRANSPARENT.GREEN.LIME : Colors.GREEN.LIME};

        &:hover {
          border: 1px solid ${props.disabled ? Colors.TRANSPARENT.GREEN.LIME : Colors.WHITE};
        }
      `}
    />
  );
};

PillButton.propTypes = {...Button.propTypes};