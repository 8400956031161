import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: none;
  flex-direction: column;
  box-sizing: border-box;
  ${(props)=> props.onClick ? 'cursor: pointer;' : ''}

  ${(props)=> props.css};
`;

export const HorizontalContainer = styled(Container)`
  flex-direction: row;

  ${(props)=> props.css};
`;

export const ScrollContainer = styled(Container)`
  overflow: scroll;
`;