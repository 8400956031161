import {removeUndefinedValues} from 'appConstants';
import {Storage} from 'providers/Storage';

export const addDefaultHeader = (key, value) => {
  Storage.set('defaultHeaders', {[key]: value}, true);
};

export const removeDefaultHeader = (key) => {

  const headers = Storage.get('defaultHeaders');
  if (!headers || !headers[key]) {
    return;
  }
  delete headers[key];
  Storage.set('defaultHeaders', headers);
};

const preventCacheingHeaders = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  'Pragma': 'no-cache',
  'Expires': 0,
};

const isJson = (value) => {

  try {
    JSON.parse(value);
  } catch(err) {
    return false;
  }

  return true;
};

const unStringifyArrays = (data) => {

  let newData;
  if (Array.isArray(data)) {

    newData = [];
    data.forEach((item) => {

      if (typeof item === 'string' && isJson(item)) {

        newData.push(JSON.parse(item));
        return;
      }

      if (typeof item === 'object') {

        newData.push(unStringifyArrays(item));
        return;
      }

      newData.push(item);
    });
  } else if (typeof data === 'object' && data !== null && data !== undefined) {

    newData = {};
    for (const [key, value] of Object.entries(data)) {

      if (typeof value === 'string' && isJson(value)) {

        newData[key] = JSON.parse(value);
        continue;
      }

      if (typeof value === 'object') {

        newData[key] = unStringifyArrays(value);
        continue;
      }

      newData[key] = value;
    }
  } else {
    newData = data;
  }

  return newData;
};

/**
 *
 * @param {string} url
 * @param {object} [options]
 * @param {string} [options.method]
 * @param {object} [options.query]
 * @param {object} [options.headers]
 * @param {object} [options.body]
 * @param {boolean} [options.removeCachingHeaders]
 * @returns {Promise<Response>}
 */
export const fetcher = async (url, options = {}) => {

  let headers = options.headers ?? {};

  if (options.query) {

    if (!url.includes('?')) {
      url += '?';
    }

    const queryObj = {};
    for (const [key, value] of Object.entries(options.query)) {

      if (value === undefined || value === null) {
        continue;
      }

      queryObj[key] = value;
    }
    url += new URLSearchParams(queryObj);
    delete options.query;
  }

  if(headers['Content-Type'] === null) {
    delete headers['Content-Type'];
  } else if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json';
  }

  if (!options.removeCachingHeaders) {

    headers = {
      ...preventCacheingHeaders,
      ...headers,
    };
  }

  if (options.body && headers['Content-Type'] === 'application/json') {
    options.body = JSON.stringify(removeUndefinedValues(options.body));
  }

  const response = await fetch(url, {
    ...options,
    headers: {
      ...Storage.get('defaultHeaders'),
      ...headers,
    },
  });

  if (response.body) {

    try {

      response.data = await response.json();
      response.data = unStringifyArrays(response.data);

    } catch (err) {
      console.warn('No data to parse', err);
    }

  }

  return response;
};