import {Container} from 'components/containers';
import PropTypes from 'prop-types';
import React from 'react';

export const Image = (props) => {

  const [Imge, setImg] = React.useState(undefined);

  React.useEffect(
    () => {

      import(`icons/${props.src}`)
        .then((res) => {

          setImg(
            <res.default
              {...props}
              onClick={undefined}
              css={`
                ${props.css};
              `}
            />,
          );
        })
        .catch((err) => {
          console.error('err occurred loading img', props.src, err);
        });
    },
    [props],
  );

  return (
    <Container
      css={`
        ${props.css};
      `}
      onClick={props.onClick}
    >

      {Imge}

    </Container>
  );
};

Image.propTypes = {
  alt: PropTypes.string,
  css: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
};