import {Colors} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {FormContext} from 'components/form/FormContext';
import {FormInputLabel} from 'components/form/FormInputLabel';
import {FormInputTooltip} from 'components/form/FormInputTooltip';
import {Text} from 'components/general';
import {cloneDeep} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const VariableLengthInputGroup = (props) => {

  const formContext = React.useContext(FormContext);
  const [reload, setReload] = React.useState(true);
  const [allValues, setAllValues] = React.useState([]);

  const onChange = (value) => {

    formContext.setState((prev) => {

      const copy = cloneDeep(prev);
      if (!copy[props.name]?.[value.key]) {
        copy[props.name][value.key] = {};
      }

      copy[props.name][value.key][value.name] = value.value;

      return copy;
    });

    setReload(!reload);
  };

  // Generate 'allValues' from 'props.items'
  React.useEffect(() => {

    const group = [];
    for (let i=0; i < props.numberItems; i++) {

      const singleGroup = [];
      for (const item of props.items) {

        const itemCopy = {
          required: true,
          disabled: props.disabled,
          ...item,
        };

        const Component = itemCopy.component;
        delete itemCopy.component;

        singleGroup.push(
          <Component
            {...itemCopy}
            value={formContext.state[props.name]?.[i]?.[itemCopy.name]}
            onChange={(value) => onChange({
              ...value,
              key: i,
            })}
          />,
        );
      }

      group.push(
        <HorizontalContainer
          key={`item-${i}`}
          css={`
            border-radius: 12px;
            padding: 4px;
            border: 1px solid ${Colors.GREY.TRANS_LIGHT};
            gap: 4px;
          `}
        >
          {singleGroup}
        </HorizontalContainer>,
      );
    }

    setAllValues(group);

  }, [reload, props.numberItems]);

  React.useEffect(() => {

    if (!props.numberItems) {
      return;
    }

    const templateObject = {};
    for (const item of props.items) {
      templateObject[item.name] = null;
    }

    const arrayTemplate = [];
    for (let i=0; i < props.numberItems; i++) {

      if (formContext.state[props.name]?.[i]) {

        arrayTemplate.push({...formContext.state[props.name][i]});
        continue;
      }

      arrayTemplate.push({...templateObject});
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: arrayTemplate,
    }));
  }, [props.numberItems]);

  // Generate 'allValues' from 'props.value' if it exists
  React.useEffect(() => {

    if(!props.value) {
      return;
    }

    const group = [];
    let i = 0;
    for (const value of props.value) {

      const singleGroup = [];
      for (const item of props.items) {

        const itemCopy = {
          required: true,
          disabled: props.disabled,
          ...item,
        };

        const Component = itemCopy.component;
        delete itemCopy.component;

        singleGroup.push(
          <Component
            {...itemCopy}
            value={value[itemCopy.name]}
          />,
        );
      }

      group.push(
        <HorizontalContainer
          key={`item-${i}`}
          css={`
            border-radius: 12px;
            padding: 4px;
            border: 1px solid ${Colors.GREY.TRANS_LIGHT};
            gap: 4px;
          `}
        >
          {singleGroup}
        </HorizontalContainer>,
      );
      i++;
    }

    setAllValues(group);

  }, []);

  return (
    <Container
      css={`
        gap: 8px;
        ${props.css}
      `}
    >

      <HorizontalContainer>

        {props.label &&
          <FormInputLabel
            text={props.label}
            optionalText={props.required === false}
            css={`
              margin-right: ${props.tooltip ? '12px' : '0'};
              margin-bottom: 4px;
            `}
          />
        }

        {props.tooltip &&
          <FormInputTooltip
            text={props.tooltip}
            css={`
              margin-right: -6px;
              margin-top: -4px;
            `}
          />
        }

      </HorizontalContainer>

      {allValues}

      {props.errorText &&
        <Text
          text={props.errorText}
          css={`
            color: ${Colors.RED};
            margin: 6px 0 0 4px;
          `}
        />
      }

      {props.hint &&
        <Text
          text={props.hint}
          css={`
            color: ${Colors.GREY.LIGHT};
            margin: 6px 0 0 4px;
          `}
        />
      }

    </Container>
  );
};

VariableLengthInputGroup.displayName = 'VariableLengthInputGroup';

VariableLengthInputGroup.propTypes = {
  css: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hint: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  numberItems: PropTypes.number,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.array,
};
