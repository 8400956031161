import {
  Colors,
  makeTransparent,
  TextStyles,
} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {FormContext} from 'components/form/FormContext';
import {FormInputLabel} from 'components/form/FormInputLabel';
import {FormInputTooltip} from 'components/form/FormInputTooltip';
import {Text} from 'components/general';
import PropTypes from 'prop-types';
import React from 'react';

export const Toggle = React.memo((props) => {

  const formContext = React.useContext(FormContext);

  const toggleValue = props.value ?? formContext.state[props.name] ?? false;

  const changeToggle = (value) => {

    const newValue =  value ?? !toggleValue;

    if (props.onChange) {

      props.onChange({
        name: props.name,
        value: newValue,
      });
      return;
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: newValue,
    }));
  };

  // Initialise toggle value
  React.useEffect(() => {

    const newValue = props.value ?? props.initialValue ?? false;
    changeToggle(newValue);
  }, []);

  return(
    <Container
      css={`
        ${(props.layout === 'horizontal') ? `
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 16px;
        ` : ''}
        gap: 8px;

        ${props.css}
      `}
    >

      <Container
        css={`
          flex: 1;
        `}
      >
        <HorizontalContainer>

          <FormInputLabel
            text={props.label}
            optionalText={props.required === false}
            css={`
              margin-right: ${props.tooltip ? '12px' : '0'};
              ${TextStyles.BODY}; // Re-enable 'clamp()' property that gets disabled in 'FormInputLabel' component
            `}
          />

          {props.tooltip &&
            <FormInputTooltip
              text={props.tooltip}
              css={`
                margin-left: 0;
                margin-top: -4px;
              `}
            />
          }

        </HorizontalContainer>

        {props.errorText &&
          <Text
            text={props.errorText}
            css={`
              color: ${Colors.RED};
              margin-left: 4px;
            `}
          />
        }

        {props.hint &&
          <Text
            text={props.hint}
            css={`
              color: ${Colors.GREY.LIGHT};
              margin-left: 4px;
            `}
          />
        }
      </Container>

      <Container
        onClick={!props.disabled ? () => changeToggle() : undefined}
        css={`
          width: 70px;
          height: 32px;
          border-radius: 999px;
          overflow: hidden;
          background-color: ${toggleValue ? makeTransparent(Colors.GREEN.LIME, 0.6) : makeTransparent(Colors.RED, 0.6)};
          justify-content: center;
          cursor: ${props.disabled ? 'initial' : 'pointer'};
          ${props.disabled ? 'filter: grayscale(50%);' : ''}
        `}
      >
        <HorizontalContainer
          css={`
            width: 100px;
            align-items: center;
            transition: transform 50ms;
            transform: translateX(${toggleValue ? '4px' : '-34px'});
          `}
        >

          <Text
            text={props.leftText}
            css={`
              font-size: 18px; // Prevent font size from shrhinking because of 'clamp()'
              flex: 1 1 0;
              width: 24px;
              height: 24px;
              text-align: center;
            `}
          />

          <Container
            css={`
                background-color: ${Colors.GREEN.DARK};
                background-color: ${Colors.WHITE};
                height: 24px;
                width: 24px;
                border-radius: 50%;
                box-shadow: 0 0 2px 1px ${makeTransparent(Colors.BLACK, 0.1)};
            `}
          />

          <Text
            text={props.rightText}
            css={`
              font-size: 18px; // Prevent font size from shrhinking because of 'clamp()'
              flex: 1 1 0;
              width: 24px;
              height: 24px;
              text-align: center;
            `}
          />

        </HorizontalContainer>
      </Container>

    </Container>
  );
});

Toggle.displayName = 'Toggle';

Toggle.propTypes = {
  css: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hint: PropTypes.string,
  initialValue: PropTypes.bool,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  layout: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

Toggle.defaultProps = {
  leftText: 'Yes',
  rightText: 'No',
};