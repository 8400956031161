import {Storage} from 'providers/Storage';

export const downloadFile = async ({fileName}) => {

  return fetch(`/api/v2/download?fileName=${fileName}`)
    .catch((err) => console.error('Network error', err));
};

export const elevatedDownloadFile = async ({fileName}) => {

  return fetch(
    `/api/v2/admin/elevated/download?fileName=${fileName}`,
    {headers: {...Storage.get('defaultHeaders')}},
  )
    .catch((err) => console.error('Network error', err));
};

export const downloadFieldsKml = async ({
  companyId,
  filter,
  fieldFilter,
}) => {

  const options = {
    headers: {...Storage.get('defaultHeaders')},
    method: 'POST',
  };

  if (fieldFilter) {

    options.body = fieldFilter;
    options.headers['Content-Type'] = 'application/json';
  }

  return fetch(
    `/api/v2/company/fields/${companyId}/download${filter ? `?filter=${filter}` : ''}`,
    options,
  );
};

export const downloadFarmsKml = async ({companyId}) => {

  const options = {
    headers: {...Storage.get('defaultHeaders')},
    method: 'POST',
  };


  return fetch(
    `/api/v2/company/farms/${companyId}/download`,
    options,
  );
};

export const downloadUserReport = async ({
  companyId,
  fileName,
}) => {

  const options = {headers: {...Storage.get('defaultHeaders')}};

  return fetch(`/api/v2/company/report/${companyId}/download?name=${fileName}`, options);
};

export const downloadCarbonReport = (companyId) => {

  const options = {headers: {...Storage.get('defaultHeaders')}};

  return fetch(
    `/api/v2/admin/generate/${companyId}/qa3`,
    options,
  );
};

export const downloadMoistureGraphs = ({companyId}) => {

  return fetch(`/api/v2/admin/dataprocessing/ndvi/moisture/${companyId}/download`, {headers: {...Storage.get('defaultHeaders')}});
};