import {
  Colors,
  ScreenSize,
} from 'appConstants';
import {
  Image,
  Text,
  Tooltip,
} from 'components/general';
import {ScreenContext} from 'context';
import PropTypes from 'prop-types';
import React from 'react';

export const FormInputTooltip = (props) => {

  const {currentView} = React.useContext(ScreenContext);

  return (
    <Tooltip
      component={
        <Image
          src={'Info'}
          width={'20px'}
          height={'20px'}
          css={`
            margin-right: 2px;
            border-radius: 50%;
            box-shadow: 0 0 0 1px transparent;
            transition: box-shadow 75ms;
            background-color: ${Colors.GREEN.DARK};

            &:hover {
              box-shadow: 0 0 0 1px ${Colors.GREEN.LIME};
            }
          `}
        />
      }
      css={props.css}
      position={props.position ?? 'right'}
      tooltipLeft={20}
      tooltipBorder={Colors.GREEN.LIGHT}
      tooltipBackground={Colors.GREEN.DARK}
      tooltipWidth={currentView === ScreenSize.MOBILE ? 200 : 350}
      addRadius={true}
      openOnHover={true}
    >

      <Text
        text={props.text}
        listCss={`
          gap: 0;
          margin-left: -12px;
        `}
      />

    </Tooltip>
  );
};

FormInputTooltip.propTypes = {
  css: PropTypes.string,
  position: PropTypes.string,
  text: PropTypes.string,
};
