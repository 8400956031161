import {
  Colors,
  ScreenSize,
  TextStyles,
} from 'appConstants';
import {TextButton} from 'components/buttons';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {Form} from 'components/form/Form';
import {FormContext} from 'components/form/FormContext';
import {Text} from 'components/general';
import {ModalContext} from 'components/modal';
import {ScreenContext} from 'context';
import PropTypes from 'prop-types';
import React from 'react';

export const FormSummary = (props) => {

  const {currentView} = React.useContext(ScreenContext);
  const modalContext = React.useContext(ModalContext);
  const formContext = React.useContext(FormContext);

  const addItem = (item, formItems) => {

    if (item.name) {

      if (
        props.values[item.name] === null
        || props.values[item.name] === undefined
        || props.values[item.name] === ''
        || (Array.isArray(props.values[item.name]) && props.values[item.name].length === 0)
      ) {

        return;
      }
    }

    if(props.dynamicOpts?.[item.name]) {

      item = {
        ...item,
        ...props.dynamicOpts?.[item.name],
      };
    }

    const Component = item.component;

    //resolve functions
    const newItem = {...item};
    for(const [key, val] of Object.entries(item)) {

      if(key === 'component') {
        continue;
      }

      if (typeof val === 'function')  {
        newItem[key] = val(props.values);
      }
    }

    formItems.push(
      <Component
        {...newItem}
        key={newItem.name ?? newItem.text ?? newItem.texts?.[0]?.text ?? newItem.texts?.[0] ?? newItem.component.displayName}
        value={props.values[newItem.name]}
        disabled={true}
        preventDefault={true}
        css={`
          padding: 8px;
          font-weight: 300;
          flex: 1;
          ${newItem.css};
        `}
      />,
    );
  };

  const addInnerItems = (item, formItems) => {

    const innerItems = [];

    for (const innerItem of item) {
      addItem(innerItem, innerItems);
    }

    formItems.push(
      <HorizontalContainer
        key={`row-${item[0].name}`}
        css={`
          ${currentView === ScreenSize.MOBILE && 'flex-direction: column;'}
        `}
      >
        {innerItems}
      </HorizontalContainer>,
    );
  };

  const addGroupItems = (items, formItems) => {

    for (const item of items ?? []) {

      if (Array.isArray(item)){

        addInnerItems(item, formItems);
        continue;
      }

      if (item.isGroup) {

        if (item.visible(props.values)){

          addGroupItems(item.items, formItems);
        }
        continue;
      }

      addItem(item, formItems);
    }
  };

  const formItems = [];
  addGroupItems(props.items, formItems);

  const editForm = () => {

    formContext.clear();

    modalContext.setState(
      <Form
        title={`${props.title} (EDIT)`}
        prePopulateValues={props.values}
        dynamicOpts={props.dynamicOpts}
        items={props.items}
        back={props.onEditBackClick}
        backText={props.editBackText}
        onSubmit={props.onEdit}
        submitText={'Submit Edit'}
      />,
    );
  };

  return (
    <Container
      css={`
        border: ${Colors.GREY.TRANS_LIGHT} solid 2px;
        border-radius: 10px;
        padding: 16px 32px 32px;
        flex: 1;
        ${props.css}
      `}
    >

      <HorizontalContainer
        css={'justify-content: space-between;'}
      >
        {props.title &&
          <Text
            text={props.title}
            css={`
              ${TextStyles.HEADING};
              padding-bottom: 16px;
            `}
          />
        }
        {props.canEdit &&
          <TextButton
            text={'Edit >'}
            textCss={`
            color: ${Colors.GREEN.LIME}
          `}
            css={`
              padding: 0 0 16px;
              margin: 0;
          `}
            onClick={editForm}
          />
        }
      </HorizontalContainer>

      {formItems}

    </Container>
  );
};

FormSummary.propTypes = {
  canEdit: PropTypes.bool,
  css: PropTypes.string,
  dynamicOpts: PropTypes.object,
  editBackText: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.string,
  values: PropTypes.object,
  onEdit: PropTypes.func,
  onEditBackClick: PropTypes.func,
};

FormSummary.defaultTypes = { title: 'Summary' };