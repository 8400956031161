/* eslint import/order: 0 */
import React from 'react';
import {SignUpForm} from 'compositeComponents';

const TsnCs = React.lazy(() => import('scenes/tsncs/TsnCs'));
const FAQs = React.lazy(() => import('scenes/faq/FAQs'));
const Profile = React.lazy(() => import('scenes/profile/Profile'));
const AdminProfile = React.lazy(() => import('scenes/adminProfile/AdminProfile'));
const VerifyEmail = React.lazy(() => import('scenes/profile/VerifyEmail'));

const EventManagement = React.lazy(() => import('scenes/adminProfile/components/eventManagement/EventManagement'));
const ResourceManagement = React.lazy(() => import('scenes/adminProfile/components/ResourceManagement'));
const BaselineFields = React.lazy(() => import('scenes/adminProfile/components/baseline/BaselineFields'));
const AdminTools = React.lazy(() => import('scenes/adminProfile/components/calcConsts/AdminTools'));
const DoEmail = React.lazy(() => import('scenes/adminProfile/components/calcConsts/DoEmail'));
const EditCalcConsts = React.lazy(() => import('scenes/adminProfile/components/calcConsts/EditCalcConsts'));
const CarbonCropJourney = React.lazy(() => import('scenes/adminProfile/components/carbonCropJourney/CarbonCropJourney'));
const Dashboard = React.lazy(() => import('scenes/adminProfile/components/dashboard/Dashboard'));
const Journey = React.lazy(() => import('scenes/adminProfile/components/journey/Journey'));
const JourneyActions = React.lazy(() => import('scenes/adminProfile/components/journey/journeyActions/JourneyActions'));
const JourneyAnalysis = React.lazy(() => import('scenes/adminProfile/components/journey/journeyAnalysis/JourneyAnalysis'));
const JourneyDashboard = React.lazy(() => import('scenes/adminProfile/components/journey/JourneyDashboard'));
const JourneyFields = React.lazy(() => import('scenes/adminProfile/components/journey/journeyFields/JourneyFields'));
const JourneyNotImplemented = React.lazy(() => import('scenes/adminProfile/components/journey/JourneyNotImplemented'));
const JourneyOnboarding = React.lazy(() => import('scenes/adminProfile/components/journey/journeyOnboarding/JourneyOnboarding'));
const JourneyPracticeData = React.lazy(() => import('scenes/adminProfile/components/journey/journeyPracticeData/JourneyPracticeData'));
const JourneyReports = React.lazy(() => import('scenes/adminProfile/components/journey/reports/JourneyReports'));
const SummaryFiles = React.lazy(() => import('scenes/adminProfile/components/summaryFiles/SummaryFiles'));
const UpdateUserAccess = React.lazy(() => import('scenes/adminProfile/components/userAccess/UpdateUserAccess'));
const UserView = React.lazy(() => import('scenes/adminProfile/components/userView/UserView'));
const EarningsPotentialPage = React.lazy(() => import('scenes/landingPage/EarningsPotentialPage'));
const PrivacyPolicy = React.lazy(() => import('scenes/privacyPolicy/PrivacyPolicy'));
const CCDashboard = React.lazy(() => import('scenes/profile/components/CCDashboard'));
const CcJourney = React.lazy(() => import('scenes/profile/components/ccJourney/CcJourney'));
const CarbonCreditCalculator = React.lazy(() => import('scenes/profile/components/tools/carbonCreditCalculator/CarbonCreditCalculator'));
const EarningsPotential = React.lazy(() => import('scenes/profile/components/tools/earningsPotential/EarningsPotential'));
const Home = React.lazy(() => import('scenes/profile/components/Home'));
const Learn = React.lazy(() => import('scenes/profile/components/Learn'));
const MyFields = React.lazy(() => import('scenes/profile/components/myFields/MyFields'));
const ProfileError = React.lazy(() => import('scenes/profile/components/ProfileError'));
const AwaitFeedback = React.lazy(() => import('scenes/profile/components/ccJourney/AwaitFeedback'));
const ContractingDetailsForm = React.lazy(() => import('scenes/profile/components/ccJourney/contractingDetails/ContractingDetailsForm'));
const EligibilityForm = React.lazy(() => import('scenes/profile/components/ccJourney/eligibility/EligibilityForm'));
const ImplementationPlan = React.lazy(() => import('scenes/profile/components/ccJourney/implementationPlan/ImplementationPlan'));
const MapFields = React.lazy(() => import('scenes/profile/components/ccJourney/mapFields/MapFields'));
const PracticeData = React.lazy(() => import('scenes/profile/components/ccJourney/practiceData/PracticeData'));
const SignContract = React.lazy(() => import('scenes/profile/components/ccJourney/SignContract'));
const StartCCJourney = React.lazy(() => import('scenes/profile/components/ccJourney/StartCCJourney'));
const SubmitEvidence = React.lazy(() => import('scenes/profile/components/ccJourney/submitEvidence/SubmitEvidence'));
const ViabilityForm = React.lazy(() => import('scenes/profile/components/ccJourney/ViabilityForm'));
const CompanyInfo = React.lazy(() => import('scenes/profile/components/companyInfo/CompanyInfo'));
const Tools = React.lazy(() => import('scenes/profile/components/tools/Tools'));
const LandingPage = React.lazy(() => import('scenes/landingPage/LandingPage'));
const Notes = React.lazy(() => import('scenes/adminProfile/components/journey/notes/Notes'));
const Evidence = React.lazy(() => import('scenes/adminProfile/components/journey/journeyEvidence/JourneyEvidence'));

const userRoutes = {
  Error: {
    component: ProfileError,
    path: 'oops',
  },
  SignUp: {
    component: SignUpForm,
    path: 'signup',
  },
  Home: {
    component: Home,
    path: 'home',
  },
  Company: {
    component: CompanyInfo,
    path: 'company',
  },
  MyFields: {
    component: MyFields,
    path: 'my_fields',
  },
  Dashboard: {
    component: CCDashboard,
    path: 'dashboard',
  },
  CcJourney: {
    component: CcJourney,
    path: 'journey',
    nested: {
      Eligibility: {
        component: EligibilityForm,
        path: 'eligibility',
      },
      Viability: {
        component: ViabilityForm,
        path: 'viability',
      },
      Details: {
        component: ContractingDetailsForm,
        path: 'details',
      },
      Contract: {
        component: SignContract,
        path: 'contract',
      },
      Fields: {
        component: MapFields,
        path: 'map_fields',
      },
      Implementation: {
        component: ImplementationPlan,
        path: 'implementation',
      },
      PracticeData: {
        component: PracticeData,
        path: 'practice_data',
      },
      Evidence: {
        component: SubmitEvidence,
        path: 'evidence',
      },
      Feedback: {
        component: AwaitFeedback,
        path: 'complete',
      },
    },
  },
  StartJourney: {
    component: StartCCJourney,
    path: 'journey_start',
  },
  Tools: {
    component: Tools,
    path: 'tools',
  },
  CCCalc: {
    component: CarbonCreditCalculator,
    path: 'calc',
  },
  EarningsPotential: {
    component: EarningsPotential,
    path: 'checker',
  },
  Learn: {
    component: Learn,
    path: 'learn',
  },
};

export const routes = {
  LandingPage: {
    component: LandingPage,
    path: '',
  },
  TsnCs: {
    component: TsnCs,
    path: '/terms-and-conditions',
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/privacy-policy',
  },
  FAQs: {
    component: FAQs,
    path: '/faqs',
  },
  VerifyEmail: {
    component: VerifyEmail,
    path: '/verify',
  },
  Profile: {
    component: Profile,
    path: '/profile',
    nested: {...userRoutes},
  },
  AdminProfile:{
    component: AdminProfile,
    path: '/admin',
    nested: {
      Dashboard: {
        component: Dashboard,
        path: '',
      },
      Events: {
        component: EventManagement,
        path: 'events',
      },
      Resources: {
        component: ResourceManagement,
        path: 'resources',
      },
      CarbonJourney:{
        component: CarbonCropJourney,
        path: 'user_journey',
      },
      Journey: {
        path: 'journey',
        component: Journey,
        nested: {
          dashboard: {
            path: '',
            component: JourneyDashboard,
          },
          onboarding: {
            path: 'onboarding',
            component: JourneyOnboarding,
          },
          fields: {
            path: 'fields',
            component: JourneyFields,
          },
          fieldRecords: {
            path: 'fieldRecords',
            component: JourneyPracticeData,
          },
          actions: {
            path: 'actions',
            component: JourneyActions,
          },
          reports: {
            path: 'reports',
            component: JourneyReports,
          },
          analysis: {
            path: 'analysis',
            component: JourneyAnalysis,
          },
          notes: {
            path: 'notes',
            component: Notes,
          },
          evidence: {
            path: 'evidence',
            component: Evidence,
          },
          notFound: {
            path: '*',
            component: JourneyNotImplemented,
          },
        },
      },
      BaselineUser: {
        component: BaselineFields,
        path: 'baseline',
      },
      UpdateUserAccess: {
        component: UpdateUserAccess,
        path: 'userAccess',
      },
      SummaryFiles: {
        component: SummaryFiles,
        path: 'summaryFiles',
      },
      UserView: {
        component: UserView,
        path: 'userView',
        nested: {...userRoutes},
      },
      Tools: {
        component: AdminTools,
        path: 'tools',
      },
      EditConsts: {
        component: EditCalcConsts,
        path: 'editConsts',
      },
      CCCalc: {
        component: CarbonCreditCalculator,
        path: 'calc',
      },
      EarningsPotential: {
        component: EarningsPotential,
        path: 'checker',
      },
      DoEmail: {
        component: DoEmail,
        path: 'doemails',
      },
    },
  },
  EarningsPotential: {
    component: EarningsPotentialPage,
    path: 'earnings-potential',
  },
};


import {
  ModalContext,
  Spinner,
} from 'components';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';

export const Router = () => {

  const Wrapper = ({children}) => {

    const location = useLocation();
    React.useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  const buildRoutes = (routeObj) => {

    const builtRoutes = [];
    for (const route of Object.values(routeObj)) {

      let innerRoutes;
      if (route.nested) {
        innerRoutes = buildRoutes(route.nested);
      }

      builtRoutes.push(
        <Route
          key={route.path}
          path={route.path}
          element={<route.component />}
        >
          {innerRoutes}
        </Route>,
      );
    }
    return builtRoutes;
  };

  const allRoutes = buildRoutes(routes);

  return(
    <BrowserRouter>
      <React.Suspense fallback={<Spinner />}>
        <Wrapper>
          <Routes>
            {allRoutes}
          </Routes>
        </Wrapper>
      </React.Suspense>
    </BrowserRouter>
  );
};

export const useNav = () => {

  const navigate = useNavigate();
  const modalContext = React.useContext(ModalContext);

  return (path, options = {}) => {

    let pathString;
    if (typeof path === 'string') {

      pathString = path;

      if (path === 'back') {
        pathString = -1;
      }
    }

    if (typeof path === 'object') {
      pathString = path.path;
    }
    if (options.query) {

      let queryString = '';

      for (const [key, value] of Object.entries(options.query)) {
        queryString+=`${key}=${value}&`;
      }
      pathString += `?${queryString.slice(0, -1)}`;
    }

    if (!options.modalOpen) {
      modalContext.closeAll();
    }

    navigate(pathString);

  };
};