import {Colors} from 'appConstants';
import {Container} from 'components/containers';
import {Spinner} from 'components/general/Spinner';
import React from 'react';

export const FullScreenLoader = () => {

  const onClickOverride = (e) => {
    e.stopPropagation();
  };

  return (
    <Container
      css={`
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background: ${Colors.TRANSPARENT.DARK};
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
`     }
      onClick={onClickOverride}
    >
      <Spinner />
    </Container>
  );
};