
export const Storage = {
  set: (key, value, spread) => {

    if(value === undefined) {
      return;
    }
    if(spread) {

      const currentItems = JSON.parse(window.sessionStorage.getItem(key)) ?? {};

      value = {
        ...currentItems,
        ...value,
      };
    }

    window.sessionStorage.setItem(key, JSON.stringify(value));
  },
  get: (key) => {

    const item = window.sessionStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  },
  remove: (key) => {
    window.sessionStorage.removeItem(key);
  },
  clear: () => {
    window.sessionStorage.clear();
  },
};