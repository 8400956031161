import {
  Colors,
  ScreenSize,
} from 'appConstants';
import {Container} from 'components/containers';
import {
  Image,
  Text,
} from 'components/general';
import {Spinner} from 'components/general/Spinner';
import {ScreenContext} from 'context';
import PropTypes from 'prop-types';
import React from 'react';

export const Button = (props) => {

  const [isLoading, setIsLoading] = React.useState(false);

  const screenContext = React.useContext(ScreenContext);
  const isMobile = screenContext.currentView === ScreenSize.MOBILE;

  const onClick = () => {

    if (props.disabled) {
      return;
    }

    setIsLoading(true);

    const onClickRes = props.onClick
      ? props.onClick()
      : console.warn('Button does not have an onClick');

    if (typeof onClickRes === 'object' && typeof onClickRes.then === 'function') {

      onClickRes.finally(() => {
        setIsLoading(false);
      });

      return;
    }

    setIsLoading(false);
  };

  return (
    <Container
      css={`
        flex-direction: row;
        align-items: center;
        padding: ${isMobile ? '8px 16px' : '16px 32px'};
        justify-content: center;
        margin: 4px;
        border-radius: 3px;
        cursor: ${(props.disabled || isLoading) ? 'default' : 'pointer'};
        background: ${(props.disabled || isLoading) ? Colors.TRANSPARENT.GREEN.LIME : Colors.GREEN.LIME};
        ${props.css};
      `}
      onClick={(props.disabled || isLoading) ? () => {} : onClick}
    >

      {!isLoading &&
        <>
          {props.icon &&
            <Image
              src={props.icon.src}
              alt={props.icon.alt}
              height={props.icon.height ?? '40px'}
              width={props.icon.width ?? '40px'}
              css={props.icon.css}
            />
          }

          <Text
            text={props.text}
            css={`
              text-align: center;
              text-wrap: pretty;
              ${props.icon ? 'padding-right: 8px;' : ''}
              ${props.textCss};
            `}
          />
        </>
      }

      {isLoading &&
        <Spinner
          size={12}
          color={props.spinnerColor}
          css={props.textCss}
        />
      }
    </Container>
  );
};

Button.propTypes = {
  css: PropTypes.string,
  disabled: PropTypes.bool,
  spinnerColor: PropTypes.string,
  text: PropTypes.string,
  textCss: PropTypes.string,
  onClick: PropTypes.func,
};