import {Permissions} from 'appConstants';
import {useSecurity} from 'context/SecurityContext';
import {
  getUser,
  removeDefaultHeader,
  Storage,
} from 'providers';
import React from 'react';

export const UserContext = React.createContext({
  userInfo: {},
  setUserInfo: () => {},
});

export const UserContextProvider = (props) => {

  const {
    user,
    isLoading,
    logout,
  } = useSecurity();

  const [userInfo, setUserInfo] = React.useState({});

  // Load user
  React.useEffect(() => {

    if (isLoading) {
      return;
    }

    if (!user) {

      removeDefaultHeader('Authorization');
      return;
    }

    getUser({email: user.email})
      .then((res) => {

        if (res.ok) {

          let isAdmin = false;
          let decodedToken = {};
          if (Storage.get('defaultHeaders')?.Authorization) {

            const token = Storage.get('defaultHeaders').Authorization.split(' ')[1];
            decodedToken = JSON.parse(atob(token.split('.')[1]));

            if (decodedToken.permissions?.includes(Permissions.READ_ADMIN)) {

              isAdmin = true;
            }
          }

          setUserInfo({
            ...res.data,
            isAdmin,
            hasWriteAccess: decodedToken.permissions?.includes?.(Permissions.WRITE_ADMIN),
            hasAdminUpdateAccess: decodedToken.permissions?.includes?.(Permissions.UPDATE_ADMIN),
          });
          return;
        }

        if (res.status === 404) {

          setUserInfo((prev) => ({
            ...prev,
            statusCode: 404,
          }));
          return;
        }

        if (res.status === 401) {

          setUserInfo({});
          logout();
        }
      })
      .catch((err) => {

        console.error('An error occurred getting the user info', err);
      });
  }, [user?.email, isLoading]);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
      }}
      displayName={'User context'}
    >
      {props.children}
    </UserContext.Provider>
  );
};