import {
  ProvinceOptions,
  countryList,
  userTypeOptions,
} from 'appConstants';
import {
  DropDown,
  PhoneNumberInput,
  TextArea,
  TextInput,
  Toggle,
} from 'components/form';


export const signUpFormConfig = [
  [
    {
      component: TextInput,
      name: 'name',
      label: 'First Name',
    },
    {
      component: TextInput,
      name: 'surname',
      label: 'Last Name',
    },
  ],
  [
    {
      component: TextInput,
      name: 'email',
      label: 'Email Address',
      disabled: true,
    },
    {
      component: PhoneNumberInput,
      name: 'phoneNumber',
      label: 'Phone Number',
    },
  ],
  {
    component: DropDown,
    name: 'userType',
    label: 'What describes you the best?',
    options: userTypeOptions,
  },
  {
    component: TextArea,
    name: 'otherUserType',
    label: 'If “other”, please specify:',
    visible: (context) => {
      return context.describesBest === 'other';
    },
  },
  {
    component: DropDown,
    name: 'country',
    label: 'Country',
    options: countryList,
    default: 'ZA',
  },
  {
    component: DropDown,
    name: 'region',
    label: 'Province',
    options: ProvinceOptions,
    visible: (context) => {
      return context.country?.toLowerCase() === 'za';
    },
  },
  {
    component: DropDown,
    name: 'hearAboutUs',
    label: 'How did you hear about us?',
    options: [
      {
        value: 'wordOfMouth',
        label: 'Word of mouth',
      },
      {
        value: 'conference',
        label: 'Conference',
      },
      {
        value: 'farmersAssociation',
        label: 'Farmers Association/Corporation',
      },
      {
        value: 'serviceProviders',
        label: 'Consultants/Service Providers',
      },
      {
        value: 'internetSearch',
        label: 'Internet search',
      },
      {
        value: 'webinar',
        label: 'Webinar',
      },
      {
        value: 'socialMedia',
        label: 'Social Media',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  {
    component: Toggle,
    name: 'hasReferral',
    label: 'Did someone refer you to sign up with Orizon?',
    layout: 'horizontal',
  },
  {
    component: TextInput,
    label: 'Referral code',
    name: 'referralCode',
    visible: (context) => context.hasReferral,
  },
  {
    component: Toggle,
    name: 'mailingList',
    label: 'Would you like to join Orizon\'s mailing list for project updates and regenerative news?',
    layout: 'horizontal',
  },
];
