import {TextStyles} from 'appConstants';
import {
  Button,
  Container,
  ModalContext,
  Text,
} from 'components';
import React from 'react';
import {useMap} from 'react-leaflet';

export const MapGotoLocation = (props) => {

  const map = useMap();
  const modalContext = React.useContext(ModalContext);

  const located = (e) => {

    props.onLocated();
    map.flyTo(e.latlng, (props.zoom ?? 15), {duration: 1.5});
  };

  const locationError = () => {

    props.onLocated();

    modalContext.setState(
      <Container
        css={`
          padding: 16px;
          max-width: 40vw;
          gap: 8px;
        `}
      >
        <Text
          text={'Unable to load location'}
          css={`
            ${TextStyles.HEADING};
            text-align: center;
          `}
        />

        <Text text={'We were unable to go to your current location, this could be because you denied this website access to use your location, because your laptop does not support location or because your location is turned off. \n The website will still function normally, but you will have to manually zoom in to your current location. \n If you declined this website access to your location and would like to change it, you can turn it on using the location icon with a small red cross in the URL bar at the top of your browser.'} />

        <Button
          text={'Ok'}
          onClick={modalContext.close}
        />
      </Container>,
    );
  };

  React.useEffect(() => {

    if (props.zoomTo) {

      located({latlng: props.zoomTo});
      return;
    }

    if(props.findLocation === false) {
      return;
    }

    map
      .locate()
      .once('locationfound', located)
      .once('locationerror', locationError);


  }, [map, props.zoomTo]);

  return (<></>);
};