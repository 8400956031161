import React from 'react';
export const ModalContext = React.createContext({});

export const ModalProvider = (props) => {

  const [state, setState] = React.useState(null);
  const [allModals, setAllModals] = React.useState([]);

  const close = () => {

    setAllModals((prev) => {

      const newModalArr = prev.slice(0,prev.length-1);

      if (newModalArr.length > 0) {
        setState(newModalArr[newModalArr.length-1]);
      } else {
        setState(null);
      }

      return newModalArr;
    });
  };

  const closeAll = () => {

    setState(null);
    setAllModals([]);
  };

  const setActiveModal = (modal) => {

    setAllModals((prev) => {

      prev.push(modal);
      return prev;
    });
    setState(modal);
  };

  return (
    <ModalContext.Provider
      displayName={'Modal Context'}
      value={{
        state,
        setState: setActiveModal,
        close,
        closeAll,
        allModals,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};
