import {Colors} from 'appConstants';
import BackgroundImage from 'assets/BackgroundImage.png';
import {
  Container,
  Modal,
  ModalProvider,
  NotificationProvider,
  Notification,
} from 'components';
import {FormProvider} from 'components/form/FormContext';
import {
  ScreenContextProvider,
  SecurityProvider,
  UserContextProvider,
} from 'context';
import {CompanyContextProvider} from 'context/CompanyContext';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Router} from 'routes';
import styled from 'styled-components';
import 'whatwg-fetch';

const BackgroundContainer = styled(Container)`
  animation-name: background-animation;
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-size: cover;

  @keyframes background-animation {
    0% {background: no-repeat left top/100% url(${BackgroundImage}), #095352;}
    33% {background: no-repeat left top/100% url(${BackgroundImage}), #006531;}
    66% {background: no-repeat left top/100% url(${BackgroundImage}), #0a5151;}
    100% {background: no-repeat left top/100% url(${BackgroundImage}), #095352;}
  }

  @media only screen and (max-device-width: 767px) {
    @keyframes background-animation {
      0% {background: no-repeat left 30% top/300% url(${BackgroundImage}), #095352;}
      33% {background: no-repeat left 30% top/300% url(${BackgroundImage}), #006531;}
      66% {background: no-repeat left 30% top/300% url(${BackgroundImage}), #0a5151;}
      100% {background: no-repeat left 30% top/300% url(${BackgroundImage}), #095352;}
    }
  }
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <style>
      {`
        body {
          margin: 0px;
          padding: 0px;
          overflow-y: auto;
          overflow-x: hidden;
        }
        ::-webkit-scrollbar {
          background: ${Colors.GREEN.LIGHT};
          width: 8px;
        }
        ::-webkit-scrollbar-thumb {
          background: ${Colors.GREEN.DARK};
          border-radius: 5px;
        }
        
      `}
    </style>
    <BackgroundContainer
      css={`
        min-width: 100vw;
        min-height: 100vh;
      `}
    >

      <SecurityProvider>

        <ScreenContextProvider>
          <UserContextProvider>
            <CompanyContextProvider>
              <ModalProvider>
                <NotificationProvider>
                  <FormProvider>
                    <Notification />
                    <Modal />
                    <Router />
                  </FormProvider>
                </NotificationProvider>
              </ModalProvider>
            </CompanyContextProvider>
          </UserContextProvider>
        </ScreenContextProvider>

      </SecurityProvider>


    </BackgroundContainer>
  </React.StrictMode>,
);
