import {
  displayDate,
  downloadFromRes,
  docTranslations,
} from 'appConstants';
import {
  Container,
  MultiPieceText,
  Text,
} from 'components';
import {CompanyContext} from 'context/CompanyContext';
import {
  downloadUserReport,
  getCompanyReports,
} from 'providers';
import React from 'react';

export const UserReportView = () => {

  const [docData, setDocData] = React.useState([]);

  const {activeCompany} = React.useContext(CompanyContext);

  const downloadReport = ({
    name,
    downloadName,
  }) => {

    return downloadUserReport({
      companyId: activeCompany.id,
      fileName: name,
    })
      .then((res) => {
        downloadFromRes(res, downloadName);
      })
      .catch((err) => console.error('An error occurred downloading the file', err));

  };

  const carbonDocs = [];

  let cnt = 0;
  for (const doc of docData) {

    const text = `${docTranslations[doc.type]} (${displayDate(new Date(doc.dateUploaded))}) `;

    carbonDocs.push(
      <MultiPieceText
        key={`doc-${cnt}`}
        texts={[
          text,
          {
            text: 'Download',
            onClick: () => downloadReport({
              name: doc.name,
              downloadName: text,
            }),
          },
        ]}
      />,
    );
    cnt++;
  }

  React.useEffect(() => {

    if(!activeCompany.id) {

      return;
    }

    getCompanyReports({companyId: activeCompany.id})
      .then((res) => {

        if (!res.ok) {
          return;
        }

        const filteredData = res.data.filter((doc) => doc.type !== 'ndvi');

        setDocData(filteredData);
      });
  }, [activeCompany.id]);

  return(
    <Container>
      {carbonDocs}

      {carbonDocs.length === 0 &&
        <Text
          text={'No reports are available yet.\n Please check again at a later point.'}
        />
      }
    </Container>
  );
};