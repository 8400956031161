import {Text} from 'components/general';

export const formLabel = (text, css='') => {

  return {
    component: Text,
    css: `
      padding: 24px 0 8px;
      font-weight: 500;
      font-size: 24px;
      text-decoration: underline;
      ${css};
    `,
    text: text,
  };
};
