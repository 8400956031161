import {Colors} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {Image} from 'components/general/Image';
import {Text} from 'components/general/Text';
import PropTypes from 'prop-types';
import React from 'react';

export const Accordion = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const onClick = () => {

    props.onClick
      ? props.onClick(props.name)
      : setIsOpen(!isOpen);
  };

  let title = props.title;
  if (typeof props.title === 'string') {

    title = (
      <Text
        text={props.title}
        css={`
          font-weight: inherit;
          color: inherit;
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 90%;
      `}
      />
    );
  }

  const open = props.isOpen ?? isOpen;

  return (
    <Container
      css={props.css}
    >
      <HorizontalContainer
        css={`
          ${props.titleCss};
          color: ${props.disabled ? Colors.GREY.LIGHT : Colors.WHITE};
          justify-content: space-between;
          align-items: center;
        `}
        onClick={props.disabled ? () => {} : onClick}
      >

        {title}

        {props.hideIcon ||
          <Image
            src={open ? 'ArrowUp' : 'ArrowDown'}
            alt={'Accordion arrow'}
            css={`
              fill: ${Colors.WHITE};
              padding-right: 16px;
            `}
          />
        }
      </HorizontalContainer>


      {open &&
        <Container
          css={'padding: 16px 8px 0;'}
        >
          {props.children}
        </Container>
      }

    </Container>
  );
};

Accordion.propTypes = {
  css: PropTypes.string,
  hideIcon: PropTypes.bool,
  isOpen: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.any,
  ]),
  titleCss: PropTypes.string,
  onClick: PropTypes.func,
};