import {Colors} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {CheckBox} from 'components/form/CheckBox';
import {FormContext} from 'components/form/FormContext';
import {FormInputLabel} from 'components/form/FormInputLabel';
import {FormInputTooltip} from 'components/form/FormInputTooltip';
import {Text} from 'components/general';
import PropTypes from 'prop-types';
import React from 'react';

export const MultiSelector = (props) => {

  const [allSelected, setAllSelected] = React.useState(false);
  const [noneSelected, setNoneSelected] = React.useState(false);

  const formContext = React.useContext(FormContext);

  const onChange = (changedName) => {

    if(props.disabled) {
      return;
    }

    if (noneSelected) {
      setNoneSelected(false);
    }

    if (allSelected) {
      setAllSelected(false);
    }

    const oldValue = props.value ?? formContext.state[props.name] ?? [];
    let newValue = oldValue.includes(changedName) ? oldValue.filter((item) => item !== changedName) : [...oldValue, changedName];

    if (newValue.length === 0) {
      newValue = null;
    }

    if (props.onChange) {

      props.onChange({
        name: props.name,
        value: newValue,
      });
      return;
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: newValue,
    }));
  };

  const allSelectedChange = () => {

    if (allSelected) {

      setAllSelected(false);
      return;
    }

    setNoneSelected(false);
    setAllSelected(true);

    const allVals = [];
    for (const item of props.items) {
      allVals.push(item.value);
    }

    if (props.onChange) {

      props.onChange({
        name: props.name,
        value: allVals,
      });
      return;
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: allVals,
    }));
  };

  const noneSelectedChange = () => {

    if (noneSelected) {

      setNoneSelected(false);
      return;
    }

    setAllSelected(false);
    setNoneSelected(true);

    if (props.onChange) {

      props.onChange({
        name: props.name,
        value: null,
      });
      return;
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: null,
    }));
  };

  const items = [];
  for(const item of props.items) {

    const value = props.value
      ? props.value.includes(item.value) ?? false
      : formContext.state[props.name]?.includes(item.value) ?? false;

    if(!value && props.disabled) {
      continue;
    }

    items.push(
      <CheckBox
        key={item.value}
        label={item.label}
        name={item.value}
        onChange={onChange}
        disabled={props.disabled}
        value={value}
        preventDefault={true}
      />,
    );
  }

  if (items.length === 0) {

    items.push(
      <Text
        text={'No options selected'}
        css={`
          color: ${Colors.GREY.TRANS_LIGHT};
          margin-left: 4px;
        `}
      />,
    );
  }

  if (props.selectAllOption && !props.disabled) {

    items.unshift(
      <CheckBox
        key={'ms-selectAll'}
        label={props.selectAllText ?? 'Select all'}
        name={'selectAll'}
        onChange={allSelectedChange}
        value={allSelected}
        css={'margin-bottom: 8px;'}
        preventDefault={true}
      />,
    );
  }

  if (props.selectNoneOption && !props.disabled) {

    items.unshift(
      <CheckBox
        key={'ms-selectNone'}
        label={props.selectNoneText ?? 'None'}
        name={'selectNone'}
        css={`margin-bottom: ${props.selectAllOption ? '0' : '8px'};`}
        onChange={noneSelectedChange}
        value={noneSelected}
        preventDefault={true}
      />,
    );
  }

  return (
    <Container
      css={`
        ${props.css}
      `}
    >

      <HorizontalContainer>

        <FormInputLabel
          text={props.label}
          optionalText={props.required === false}
          css={`
            margin-right: ${props.tooltip ? '12px' : '0'};
            margin-bottom: 4px;
          `}
        />

        {props.tooltip &&
          <FormInputTooltip
            text={props.tooltip}
            css={`
              margin-left: 0;
              margin-top: -4px;
            `}
          />
        }

      </HorizontalContainer>

      {items}

      {props.errorText &&
        <Text
          text={props.errorText}
          css={`
            color: ${Colors.RED};
            margin: 6px 0 0 4px;
          `}
        />
      }

      {props.hint &&
        <Text
          text={props.hint}
          css={`
            color: ${Colors.GREY.LIGHT};
            margin: 6px 0 0 4px;
          `}
        />
      }

    </Container>
  );
};

MultiSelector.displayName = 'MultiSelector';

MultiSelector.propTypes = {
  css: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hint: PropTypes.string,
  invalid: PropTypes.bool,
  items: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  selectAllOption: PropTypes.bool,
  selectAllText: PropTypes.string,
  selectNoneOption: PropTypes.bool,
  selectNoneText: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};
