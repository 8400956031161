
export const b2cPolicies = {
  names: {
    forgotPassword: 'b2c_1_reset',
    signUpSignIn: 'B2C_1_signup_signin',
  },
  authorities: {
    signUpSignIn: {authority: 'https://orizonagri.b2clogin.com/orizonagri.onmicrosoft.com/B2C_1_signup_signin'},
    forgotPassword: {authority: 'https://orizonagri.b2clogin.com/orizonagri.onmicrosoft.com/B2C_1_pass_reset'},
  },
  authorityDomain: 'orizonagri.b2clogin.com',
};

// MSAL configuration
export const msalConfig = {auth: {
  clientId: '22bb6349-8cf0-40d4-b81b-94d2b3b9c5b4', // This is the ONLY mandatory field that you need to supply.
  authority: b2cPolicies.authorities.signUpSignIn.authority, // Use a sign-up/sign-in user-flow as a default authority
  knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
  redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
  postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
  navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.
}};