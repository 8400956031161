import {
  Colors,
  TextStyles,
} from 'appConstants';
import {Container} from 'components/containers';
import React from 'react';
import styled from 'styled-components';

const H3 = styled.h3`
  margin: unset;
  ${(props) => props.css};
`;

const H2 = styled.h2`
  margin: unset;
  ${(props) => props.css};
`;

const H1 = styled.h1`
  margin: unset;
  ${(props) => props.css};
`;

export const SubHeading = (props) => {

  return (
    <Container
      {...props}
      css={`
        white-space: pre-line;
        font-family: "Josefin Sans", sans-serif;
        color: ${Colors.WHITE};
        margin-top: 3px;
        flex: 1;
      `}
    >

      <H3
        css={`
          ${TextStyles.SUB_HEADING};
          ${props.css};
        `}
      >
        {props.text}
      </H3>

    </Container>
  );
};

export const Heading = (props) => {

  return (
    <Container
      {...props}
      css={`
        white-space: pre-line;
        font-family: "Josefin Sans", sans-serif;
        color: ${Colors.WHITE};
        margin-top: 3px;
        ${props.containerCss}
      `}
    >

      <H2
        css={`
          ${TextStyles.HEADING};
          ${props.css};
        `}
      >
        {props.text}
      </H2>

    </Container>
  );
};

export const HeadingLarge = (props) => {

  return (
    <Container
      {...props}
      css={`
        white-space: pre-line;
        font-family: "Josefin Sans", sans-serif;
        color: ${Colors.WHITE};
        margin-top: 3px;
      `}
    >

      <H1
        css={`
          ${TextStyles.LARGE_HEADING};
          ${props.css};
        `}
      >
        {props.text}
      </H1>

    </Container>
  );
};