/**
 * @description Returns the index of the first form page that still needs to be completed
 * @param {array} formConfigPages - Form config
 * @param {object} currentData - All data that already exists for the company (fetched from DB)
 * @returns {number}
 */
export const getCurrentFormPage = (formConfigPages, currentData) => {

  for (let i = 0; i < formConfigPages.length; i++) {

    const page = formConfigPages[i];

    let allInputsOptional = true;
    let pageContainsData = false;
    for (const component of page) {

      if (currentData[component?.name] === null && !(component.required === false || typeof component.required === 'function')) {
        allInputsOptional = false;
      }

      if (currentData[component?.name] !== null && currentData[component?.name] !== undefined) {
        pageContainsData = true;
      }
    }

    for (const component of page) {


      // If company already has data for this input field, or input field doesn't have a 'name' property (e.g. Text component)
      if (currentData[component?.name] !== null) {
        continue;
      }

      if (
        // Component doesn't have a 'required' property (default is {required: true})
        !('required' in component) ||
        // All inputs on the current page is optional and no inputs on the page has been completed yet (at least 1 input needs to be filled in if all inputs on the page are optional)
        (allInputsOptional && !pageContainsData)
      ) {
        return i;
      }
    }
  }

  return 0;
};