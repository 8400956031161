import {Colors} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {FormContext} from 'components/form/FormContext';
import {FormInputLabel} from 'components/form/FormInputLabel';
import {FormInputTooltip} from 'components/form/FormInputTooltip';
import {RadioButton} from 'components/form/RadioButton';
import {Text} from 'components/general';
import PropTypes from 'prop-types';
import React from 'react';

export const Selector = (props) => {

  const formContext = React.useContext(FormContext);

  const onChange = (changedName) => {

    if ('value' in props) {
      return;
    }

    if (props.onChange) {

      props.onChange(
        props.name,
        changedName,
      );
      return;
    }

    formContext.setState((prev) => {

      let newName = changedName;
      if (prev[props.name] === changedName) {
        newName = null;
      }
      return {
        ...prev,
        [props.name]: newName,
      };
    });
  };

  React.useEffect(() => {

    if ('value' in props) {
      return;
    }

    if (formContext.state[props.name] === undefined && props.default && !props.disabled) {

      formContext.setState((prev) => ({
        ...prev,
        [props.name]: props.default,
      }));
    }
  }, []);

  const items = [];
  for(const item of props.items) {

    const value = props.value
      ? props.value === item.value
      : formContext.state[props.name] === item.value;

    if (!value && props.disabled) {
      continue;
    }

    items.push(
      <RadioButton
        key={item.value}
        label={item.label}
        name={item.value}
        disabled={props.disabled}
        onChange={onChange}
        value={(formContext.state[props.name] === item.value) || (props.value === item.value)}
        preventDefault={true}
      />,
    );
  }

  if (items.length === 0) {

    items.push(
      <Text
        text={'No option selected'}
        css={`
          color: ${Colors.GREY.TRANS_LIGHT};
          margin-left: 4px;
        `}
      />,
    );
  }

  return(
    <Container
      css={`
        ${props.css}
      `}
    >
      <HorizontalContainer>

        <FormInputLabel
          text={props.label}
          optionalText={props.required === false}
          css={`
            margin-right: ${props.tooltip ? '12px' : '0'};
            margin-bottom: 4px;
          `}
        />

        {props.tooltip &&
          <FormInputTooltip
            text={props.tooltip}
            css={`
              margin-left: 0;
              margin-top: -4px;
            `}
          />
        }

      </HorizontalContainer>

      <Container
        css={`
          flex-direction: ${props.horizontal ? 'row' : 'column'};
            padding-left: ${props.horizontal ? '6px' : '0'};
            ${props.horizontal ? 'gap: 16px' : ''};
        `}
      >

        {items}
      </Container>

      {props.errorText &&
        <Text
          text={props.errorText}
          css={`
            color: ${Colors.RED};
            margin: 6px 0 0 4px;
          `}
        />
      }

      {props.hint &&
        <Text
          text={props.hint}
          css={`
            color: ${Colors.GREY.LIGHT};
            margin: 6px 0 0 4px;
          `}
        />
      }

    </Container>
  );
};

Selector.displayName = 'Selector';

Selector.propTypes = {
  css: PropTypes.string,
  default: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hint: PropTypes.string,
  invalid: PropTypes.bool,
  items: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
