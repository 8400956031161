import {Colors} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Circle = styled.span`
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: clamp(32px, 8cqi, 40px);
  height: clamp(32px, 8cqi, 40px);
  padding: 6px;
  border-radius: 50%;
  color: ${Colors.WHITE};
  flex-shrink: 0;
  justify-content: center;

  transition: box-shadow ${(props) => props.noTransition ? '0' : '150ms'};
  transition-delay: ${(props) => props.noTransition ? '0' : '500ms'};
  transition-property: box-shadow;

  background-color: ${(props) => props.completed ? Colors.GREEN.LIME : Colors.TRANSPARENT.TRANSPARENT};
  box-shadow: inset 0 0 0 3px ${(props) => props.active || props.completed ? Colors.GREEN.LIME : Colors.WHITE};

  ::before {
    display: block;
    content: "";
    width: 1px; // Make the pointer appear more centered
    position: absolute;
    bottom: ${(props) => props.isLastCompletedStep ? '-12px' : '-18px'};
    transition: bottom 200ms, border-color 150ms;
    border: 6px solid ${Colors.TRANSPARENT.TRANSPARENT};
    border-bottom-color: ${(props) => props.isLastCompletedStep ? Colors.WHITE : Colors.TRANSPARENT.TRANSPARENT};
  }

  &:hover {
    cursor: ${(props) => props.cursorType};
  }
`;

const Line = styled.div`
  flex: 1 0 6px;
  max-width: 64px;
  height: 3px;
  border-radius: 10px;

  background: linear-gradient(to left, ${Colors.WHITE} 50%, ${Colors.GREEN.LIME} 50%);
  background-size: 200%;
  background-position: right;
  transition: background-position ${(props) => props.noTransition ? '0' : '500ms'};

  ${(props) => props.completed ? 'background-position: left;' : ''}
  ${(props) => props.css}
`;

/**
 * @description        Component that shows which page of the form is currently active
 * @param {number}     stepsCount     - Number of steps/pages.
 * @param {number}     currentStep    - Current active step/page.
 * @param {function}   onClick   - A function that receives the index of the clicked step as an argument.
 */
export const SteppedProgressBar = (props) => {

  const items = [];
  for (let i = 1; i <= props.stepsCount; i++) {

    items.push(
      <Circle
        completed={i < props.currentStep}
        active={i === props.currentStep}
        onClick={() => props.onClick?.(i - 1)}
        noTransition={i > props.currentStep || i < props.currentStep}
        cursorType={'onClick' in props ? 'pointer' : 'default'}
        isLastCompletedStep={props.lastCompletedStep + 1 === i}
        key={`circle${i}`}
      >
        {i < props.currentStep &&
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            transform='rotate(10)'
            strokeWidth={4}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 12.75 6 6 9-13.5"
            />
          </svg>
        }
      </Circle>,
    );
    if (i < props.stepsCount) {

      items.push(
        <Line
          completed={i < props.currentStep}
          noTransition={i > props.currentStep || i < props.currentStep - 1}
          key={`line${i}`}
        />,
      );
    }
  }

  return (
    <Container
      css={`
        container-type: inline-size;
        align-items: center;
        margin: 20px 0 40px;
        ${props.css}
      `}
    >
      <HorizontalContainer
        css={`
          gap: clamp(2px, 0.7cqi, 5px);
          align-items: center;
          width: min(100cqi, 800px);
          justify-content: center;
        `}
      >

        {items}

      </HorizontalContainer>
    </Container>
  );
};

SteppedProgressBar.propTypes = {
  css: PropTypes.string,
  currentStep: PropTypes.number,
  stepsCount: PropTypes.number,
  onClick: PropTypes.func,
};