import {enumerateObject} from 'appConstants/utils';

export const viabilityTypes = {
  croplandDry: 'croplandDry',
  croplandPivot: 'croplandPivot',
  pasturesPlanted: 'pasturesPlanted',
  pasturesNatural: 'pasturesNatural',
  other: 'other',
  livestock: 'livestock',
};

export const viabilityTypeEnum = enumerateObject({
  croplandDry: 'croplandDry',
  croplandPivot: 'croplandPivot',
  pasturesPlanted: 'pasturesPlanted',
  pasturesNatural: 'pasturesNatural',
  forestry: 'forestry',
  orchards: 'orchards',
  vineyards: 'vineyards',
  vegetables: 'vegetables',
  livestock: 'livestock',
});

export const viabilityOther = {
  forestry: 'forestry',
  orchards: 'orchards',
  vineyards: 'vineyards',
  vegetables: 'vegetables',
};