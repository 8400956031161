import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(enLocale);
const countryObject = countries.getNames('en', {select: 'official'});

export const countryList = [];

for (const [key, value] of Object.entries(countryObject)) {

  countryList.push({
    value: key,
    label: value,
  });
}