import {
  Colors,
  downloadFromRes,
} from 'appConstants';
import {
  Container,
  HorizontalContainer,
  Image,
  Text,
  TextButton,
} from 'components';
import {downloadFile} from 'providers';
import React from 'react';

export const Resources = (props) => {

  const download = (fileName, downloadName) => {

    return downloadFile({fileName})
      .then((res) => {
        downloadFromRes(res, downloadName ?? fileName);
      })
      .catch((err) => console.error('An error occurred downloading the file', err));
  };

  const categories = {};

  for(const item of props.items) {

    if(!categories[item.category]) {
      categories[item.category] = [];
    }
    categories[item.category].push(
      <HorizontalContainer
        css={`
          box-sizing: content-box;
          padding: 0 0 0 8px;
          align-items: center;
        `}
        key={item.fileName}
      >

        <Image
          src={'Download'}
          stroke={Colors.WHITE}
        />

        <TextButton
          text={item.displayName}
          key={item.fileName}
          onClick={async () => {

            return props.onClickOverride
              ? props.onClickOverride(item)
              : download(item.fileName, item.downloadName);
          }}
          spinnerColor={Colors.GREEN.LIGHT}
          css={`
            font-weight: 300;
            box-sizing: content-box;
            flex-shrink: 1;
            align-items: flex-start; //align-items must be used instead of text-align because Text is a child in TextButton
            border-bottom: 1px solid transparent;
          `}
        />

      </HorizontalContainer>,
    );
  }

  const resources = [];
  for(const [category, items] of Object.entries(categories)) {

    if (props.dropCategories) {

      resources.push(...items);
      continue;
    }

    resources.push(
      <Container
        key={category}
        css={`
          width: fit-content;
          padding: 12px 0 8px;
        `}
      >
        <Text
          text={category}
          css={`
            font-weight: 400;
            text-decoration: underline;
          `}
        />

        {items}
      </Container>,
    );
  }

  return (resources);
};