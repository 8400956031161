import {
  Colors,
  makeTransparent,
} from 'appConstants';
import {Container} from 'components/containers';
import {Text} from 'components/general/Text';
import PropTypes from 'prop-types';
import React from 'react';
import styled, {keyframes} from 'styled-components';

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinContainer = styled(Container)`
  animation-name: ${spinAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
`;

const LoadingShift = keyframes`
  0% {
    background: no-repeat left/100%, #095352FF;
  }
  40% {
    background: no-repeat left/100%, #09535200;
  }
  80% {
    background: no-repeat left/100%, #095352FF;
  }
  100% {
     background: no-repeat left/100%, #095352FF;
  }
`;

const PulsingContainer = styled(Container)`
  animation-name: ${LoadingShift};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  background-size: cover;
`;

export const Spinner = (props) => {

  const size = props.size
    ? `${props.size}px`
    : '100px';
  const border = (props.size && props.size < 20)
    ? '4px'
    : '8px';

  const color = props.color ?? Colors.GREEN.LIGHT;

  return (
    <Container
      css={`
        width: 100%;
        height: 100%;
        min-height: ${size};
        min-width: ${size};
        align-items: center;
        justify-content: center;
        ${props.css};
      `}
    >

      {props.text &&
        <Container
          css={`
            background-color: ${Colors.GREEN.LIGHT};
            border-radius: 20px;
            max-width: 80px;
            text-align: center;
          `}
        >

          <PulsingContainer>
            <Text
              css={`
              width: fit-content;
              padding: 8px;
            `}
              text={props.text}
            />
          </PulsingContainer>

        </Container>
      }

      <SpinContainer
        css={`
            animation-delay: -1s;
            border: ${border} solid ${color};
            border-color: ${color} transparent transparent transparent;
            border-radius: 50%;
            box-sizing: border-box;
            display: block;
            position: absolute;
            height: ${size};
            width: ${size};
          `}
      />
      <SpinContainer
        css={`
            animation-delay: -0.8s;
            border: ${border} solid ${makeTransparent(color, 0.8)};
            border-color: ${makeTransparent(color, 0.8)} transparent transparent transparent;
            border-radius: 50%;
            display: block;
            position: absolute;
            height: ${size};
            width: ${size};
          `}
      />
      <SpinContainer
        css={`
            animation-delay: -0.6s;
            border: ${border} solid ${makeTransparent(color, 0.6)};
            border-color: ${makeTransparent(color, 0.6)} transparent transparent transparent;
            border-radius: 50%;
            display: block;
            position: absolute;
            height: ${size};
            width: ${size};
          `}
      />
      <SpinContainer
        css={`
            animation-delay: -0.4s;
            border: ${border} solid ${makeTransparent(color, 0.4)};
            border-color: ${makeTransparent(color, 0.4)} transparent transparent transparent;
            border-radius: 50%;
            display: block;
            position: absolute;
            height: ${size};
            width: ${size};
          `}
      />

    </Container>
  );
};

Spinner.propTypes = {
  color: PropTypes.string,
  css: PropTypes.string,
  size: PropTypes.number,
};