import {fetcher} from 'providers/fetcher';

export const getFarms = (companyId) => {

  return fetcher(`/api/v2/company/farms/${companyId}`);
};

export const postFarmDetails = ({
  data,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/farms/${companyId}`,
    {
      method: 'POST',
      body: data,
    },
  );
};

export const deleteFarm = ({
  companyId, farmId,
}) => {

  return fetcher(
    `/api/v2/company/farms/${companyId}`,
    {
      method: 'DELETE',
      query: {farmId},
    },
  );
};

export const getFarmBoundaries = (companyId) => {

  return fetcher(`/api/v2/company/farms/boundaries/${companyId}`);
};

export const postFarmBoundaries = ({
  data,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/farms/boundaries/${companyId}`,
    {
      method: 'POST',
      body: data,
    },
  );
};

export const putFarmBoundaries = ({
  data,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/farms/boundaries/${companyId}`,
    {
      method: 'PUT',
      body: data,
    },
  );
};

export const deleteFarmBoundary = ({
  companyId, boundaryId,
}) => {

  return fetcher(
    `/api/v2/company/farms/boundaries/${companyId}`,
    {
      method: 'DELETE',
      query: {boundaryId},
    },
  );
};

export const postViability = ({
  data,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/viability/${companyId}`,
    {
      method: 'POST',
      body: data,
    },
  );
};

export const getViabilityLivestock = (companyId) => {

  return fetcher(`/api/v2/company/viability/livestock/${companyId}`);
};

export const putViability = ({
  data,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/viability/${companyId}`,
    {
      method: 'PUT',
      body: data,
    },
  );
};

export const getViability = (companyId) => {

  return fetcher(`/api/v2/company/viability/${companyId}`);
};

export const getLegacyViability = (companyId) => {

  return fetcher(`/api/v2/company/viability/legacy/${companyId}`);
};

export const postField = async ({
  data,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/fields/${companyId}`,
    {
      method: 'POST',
      body: data,
    },
  );
};

export const putField = async ({
  data,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/fields/${companyId}`,
    {
      method: 'PUT',
      body: data,
    },
  );
};

export const updateField = async ({
  data,
  companyId,
  updatedBy,
}) => {

  return fetcher(
    `/api/v2/company/fields/${companyId}/incomplete`,
    {
      method: 'PUT',
      body: {
        ...data,
        updatedBy,
      },
    },
  );
};

export const convertCircleToPoly = async ({
  fieldId,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/fields/${companyId}/circleToPoly`,
    {
      method: 'PUT',
      query: {fieldId},
    },
  );
};

export const postMultiFields = ({
  data,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/fields/${companyId}/multi`,
    {
      method: 'POST',
      body: data,
    },
  );
};

export const getAllFields = async ({
  farmId,
  companyId,
  fieldId,
  fieldStatus,
}) => {

  return fetcher(
    `/api/v2/company/fields/${companyId}`,
    {query: {
      farmId,
      fieldId,
      fieldStatus,
    }},
  );
};

export const deleteField = async ({
  fieldId,
  updatedBy,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/fields/${companyId}`,
    {
      method: 'DELETE',
      query: {
        fieldId,
        updatedBy,
      },
    },
  );
};

export const uploadFieldKML = async ({
  data,
  companyId,
}) => {

  const body = new FormData();

  for (const [key, value] of Object.entries(data)) {
    body.append(key, value);
  }

  return fetcher(
    `/api/v2/company/fields/${companyId}/upload`,
    {
      headers: {'Content-Type': null},
      method: 'POST',
      body,
    },
  );
};

export const getFieldPracticeSeasons = ({fieldId}) => {

  return fetcher(`/api/v2/pd/${fieldId}/seasons`);
};

export const getSeasonPractice = ({
  seasonId,
  companyId,
  pdId,
  fieldId,
}) => {

  return fetcher(
    `/api/v2/pd/${fieldId}/data/${seasonId}`,
    {query: {
      pdId,
      companyId,
    }},
  );
};

export const copySeasonPractice = ({
  fromSeasonId,
  toSeasonId,
  userId,
}) => {

  return fetcher(
    `/api/v2/pd/season/${fromSeasonId}/copy/${toSeasonId}`,
    {
      method: 'POST',
      query: {userId},
    },
  );
};

export const postFieldEvent = ({
  practiceSeasonId,
  data,
  userId,
  fieldId,
  companyId,
}) => {

  return fetcher(
    `/api/v2/pd/${fieldId}/data/${practiceSeasonId}`,
    {
      method: 'POST',
      body: {
        ...data,
        updatedBy: userId,
        companyId,
      },
    },
  );
};

export const initialiseField = ({
  fieldId,
  data,
  userId,
}) => {

  return fetcher(
    `/api/v2/pd/${fieldId}/seasons/initialise`,
    {
      method: 'POST',
      body: {
        ...data,
        updatedBy: userId,
      },
    },
  );
};

export const initialiseFields = ({
  data,
  userId,
}) => {

  return fetcher(
    '/api/v2/pd/seasons/initialise',
    {
      method: 'POST',
      body: {
        updatedBy: userId,
        data,
      },
    },
  );
};

export const putFieldEvent = ({
  data,
  seasonId,
  userId,
  fieldId,
}) => {

  return fetcher(
    `/api/v2/pd/${fieldId}/data/${seasonId}`,
    {
      method: 'PUT',
      body: data,
      query: {userId},
    },
  );
};

export const deleteFieldEvent = ({
  seasonId,
  pdId,
  pdType,
  fieldId,
}) => {

  return fetcher(
    `/api/v2/pd/${fieldId}/data/${seasonId}`,
    {
      method: 'DELETE',
      query: {
        id: pdId,
        pdType,
      },
    },
  );
};

export const completeFieldSeason = ({
  seasonId,
  fieldId,
  updatedBy,
}) => {

  return fetcher(
    `/api/v2/pd/${fieldId}/data/${seasonId}/complete`,
    {
      method: 'POST',
      query: {updatedBy},
    },
  );
};

export const practiceComplete = ({
  companyId,
  updatedBy,
}) => {

  return fetcher(
    `/api/v2/company/update/${companyId}/practiceComplete`,
    {query: {updatedBy}},
  );
};

export const getCropSetup = (companyId) => {

  return fetcher(`/api/v2/pd/${companyId}/fields/crop/setup`);
};

export const getFieldEvents = ({
  companyId,
  fieldId,
}) => {

  return fetcher(`/api/v2/pd/company/${companyId}/field/${fieldId}`);
};

export const postCropSetup = ({
  companyId,
  userId,
  type,
  pdType,
  data,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/fields/crop/setup`,
    {
      method: 'POST',
      query: {
        userId,
        type,
        pdType,
      },
      body: {fieldSeasons: data},
    },
  );
};

export const postFieldGroup = ({
  userId,
  eventLinks,
  seasonId,
  practiceProgrammeId,
  companyId,
}) => {

  return fetcher(
    '/api/v2/pd/link/data',
    {
      method: 'POST',
      body: {
        eventLinks,
        updatedBy: userId,
        practiceProgrammeId,
        seasonId,
        companyId,
      },
    },
  );
};

export const getFieldGroupings = ({
  seasonId,
  practiceProgrammeId,
  companyId,
}) => {

  return fetcher(
    '/api/v2/pd/link/data',
    {query: {
      seasonId,
      practiceProgrammeId,
      companyId,
    }},
  );
};

export const updateFieldGroup = ({
  eventLinks,
  eventsUnlinked,
  groupId,
}) => {

  return fetcher(
    '/api/v2/pd/link/data',
    {
      method: 'PUT',
      body: {
        eventLinks,
        eventsUnlinked,
        groupId,
      },
    },
  );
};

export const deleteFieldGroup = ({groupId}) => {

  return fetcher(
    '/api/v2/pd/link/data',
    {
      method: 'DELETE',
      query: {groupId},
    },
  );
};

export const getProgrammes = ({companyId}) => {

  return fetcher(`/api/v2/pd/${companyId}/programme`);
};

export const postProgramme = ({
  companyId,
  name,
  type,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/programme`,
    {
      method: 'POST',
      body: {
        name,
        type,
      },
    },
  );
};

export const updateProgramme = ({
  id,
  name,
  companyId,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/programme`,
    {
      method: 'PUT',
      body: {
        name,
        id,
      },
    },
  );
};

export const deleteProgramme = ({
  companyId,
  id,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/programme`,
    {
      method: 'DELETE',
      query: {id},
    },
  );
};

export const getProgrammeEvent = ({
  companyId,
  programmeId,
  eventId,
}) => {
  return fetcher(`/api/v2/pd/${companyId}/programme/${programmeId}/event?eventId=${eventId}`);
};

export const postProgrammeEvent = ({
  companyId,
  programmeId,
  updatedBy,
  data,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/programme/${programmeId}/event`,
    {
      method: 'POST',
      body: data,
      query: {updatedBy},
    },
  );
};

export const updateProgrammeEvent = ({
  eventId,
  programmeId,
  companyId,
  userId,
  data,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/programme/${programmeId}/event?updatedBy=${userId}`,
    {
      method: 'PUT',
      body: {
        ...data,
        id: eventId,
      },
    },
  );
};

export const deleteProgrammeEvent = ({
  eventId,
  programmeId,
  companyId,
  pdType,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/programme/${programmeId}/event`,
    {
      method: 'DELETE',
      query: {
        id: eventId,
        pdType,
      },
    },
  );
};

export const getAllPracticeSeasons = ({companyId}) => {

  return fetcher(`/api/v2/pd/${companyId}/allSeasons`);
};

export const generatePdEvents = ({
  companyId, updatedBy,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/events/generate`,
    {
      method: 'POST',
      query: {updatedBy},
    },
  );
};

export const mergeFields = ({
  companyId,
  userId,
  fieldIds,
}) => {

  return fetcher(
    `/api/v2/company/fields/${companyId}/merge`,
    {
      method: 'POST',
      body: {
        fields: fieldIds,
        updatedBy: userId,
      },
    },
  );
};

export const duplicateProgramme = ({
  companyId,
  programmeId,
  updatedBy,
  name,
}) => {

  return fetcher(`/api/v2/pd/${companyId}/programme/${programmeId}/duplicate`,{
    method: 'POST',
    body: {
      updatedBy,
      name,
    },
  });
};
