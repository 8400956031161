import {MONTHS} from 'appConstants/months';

export const downloadFromRes = (res, fileName, options={}) => {

  if(!res.ok) {
    return;
  }

  res
    .blob()
    .then((data) => {

      const blob = new Blob([data], { type: options.type ?? 'application/pdf' });
      const fileURL = URL.createObjectURL(blob);
      const el = document.createElement('a');

      el.href = fileURL;
      el.download = fileName;
      el.click();
      el.remove();
    })
    .catch((e) => {
      console.error('Error in blob', e);
    });
};

export const TrueFalseText = {
  null: 'Not Done',
  true: 'Passed',
  false: 'Failed',
};

export const removeUndefinedValues = (obj) => {

  if (Array.isArray(obj)) {

    const newObj = [];

    for (const item of obj) {
      newObj.push(removeUndefinedValues(item));
    }
    return newObj;
  }

  const cleanedInfo = {...obj};

  for (const key of Object.keys(obj)) {

    if (obj[key] === undefined || obj[key] === null) {
      delete cleanedInfo[key];
    }
  }
  return cleanedInfo;
};

export const isDate = (date) => {

  return date instanceof Date && !isNaN(date);
};

export const displayDate = (date) => {

  if (!date) {
    return '';
  }

  let parsedDate = date;
  if (!isDate(date)) {

    parsedDate = new Date(date);

    if (!isDate(parsedDate)) {
      return '';
    }
  }

  return `${parsedDate.getDate()} ${MONTHS[parsedDate.getMonth()].name} ${parsedDate.getFullYear()}`;
};

export const displayRandValue = (value) => {

  if (typeof value !== 'number') {
    return 'R0';
  }

  return 'R'+Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(value);
};

export const enumerateObject = (obj) => {

  const enumObject = {};
  let cnt = 0;
  for (const key of Object.keys(obj)) {

    enumObject[key] = cnt;
    cnt ++;
  }

  return enumObject;
};

export const getNPKTotals = (fertEvent) => {

  let multiplier = 0;
  const retObject = {
    nitrogen: 0,
    phosphorus: 0,
    potassium: 0,
  };

  if (fertEvent.npkType === 'npk') {
    multiplier = (fertEvent.applicationRate * fertEvent.nutrientPercent) / (100 * (fertEvent.nitrogen + fertEvent.phosphorus + fertEvent.potassium));
  }

  if (fertEvent.npkType === 'nutrient') {
    multiplier = fertEvent.applicationRate / 100;
  }

  if (isNaN(multiplier) || multiplier === 0) {
    return retObject;
  }

  retObject.nitrogen = Math.round((fertEvent.nitrogen ?? 0) * multiplier * 10)/10 ?? 0;
  retObject.phosphorus = Math.round((fertEvent.phosphorus ?? 0) * multiplier * 10)/10 ?? 0;
  retObject.potassium = Math.round((fertEvent.potassium ?? 0) * multiplier * 10)/10 ?? 0;

  return retObject;
};