import {
  ScreenDimensions,
  ScreenSize,
} from 'appConstants';
import React from 'react';

export const ScreenContext = React.createContext({
  currentView: '',
  currentWidth: '',
});

export const ScreenContextProvider = (props) => {

  const [state, setState] = React.useState(ScreenSize.DESKTOP);

  React.useEffect(()=> {

    const updateSize = () => {

      setState({
        currentView: window.innerWidth < ScreenDimensions.MOBILE
          ? ScreenSize.MOBILE
          : window.innerWidth < ScreenDimensions.TABLET
            ? ScreenSize.TABLET
            : ScreenSize.DESKTOP,
        currentWidth: window.innerWidth,
      });
    };

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <ScreenContext.Provider
      displayName={'Screen Context'}
      value={state}
    >
      {props.children}
    </ScreenContext.Provider>
  );
};