import React from 'react';

export const useViewportEntry = (ref, observerOptions) => {

  const [entered, setEntered] = React.useState(false);

  const observerRef = React.useRef(
    new IntersectionObserver(([entry]) => setEntered(entry.isIntersecting), observerOptions),
  );

  React.useEffect(() => {

    const element = ref.current;
    const observer = observerRef.current;

    if (element) {
      observer.observe(element);
    }

    return () => observer.disconnect();
  }, []);

  return entered;
};