import {fetcher} from 'providers/fetcher';

export const getResources = async () => {

  return fetcher('/api/v2/resource');
};

export const deleteResource = async (fileName) => {

  return fetcher(
    `/api/v2/resource?fileName=${fileName}`,
    {method: 'delete'},
  );
};

export const uploadResource = async (data) => {

  const body = new FormData();

  for (const [key, value] of Object.entries(data)) {
    body.append(key, value);
  }

  return fetcher(
    '/api/v2/resource/upload',
    {
      headers: {'Content-Type': null},
      method: 'POST',
      body,
    },
  );
};