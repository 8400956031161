import {fetcher} from 'providers/fetcher';

export const getUser = ({
  email, userId,
}) => {

  return fetcher(
    '/api/v2/user/info',
    {
      method: 'GET',
      query: {
        email,
        userId,
      },
    },
  );
};

export const postUser = ({
  companyId,
  data,
  userId,
}) => {

  return fetcher('/api/v2/user/info', {
    method: 'POST',
    body: data,
    headers: { 'Content-Type': 'application/json' },
    query: {
      companyId,
      userId,
      email: data.email,
    },
  });
};

export const putUser = ({
  email,
  userId,
  ...data
}) => {

  return fetcher(`/api/v2/user/info?email=${email}`, {
    method: 'PUT',
    body: data,
    headers: { 'Content-Type': 'application/json' },
    query: { userId },
  });
};

export const getReferrals = (userReferralCode) => {

  return fetcher(`/api/v2/user/referral/${userReferralCode}`);

};

export const postEligibility = ({
  data,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/eligibility/${companyId}`,
    {
      method: 'POST',
      body: data,
      headers: { 'Content-Type': 'application/json' },
    },
  );
};

export const putEligibility = ({
  data,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/eligibility/${companyId}`,
    {
      method: 'PUT',
      body: data,
      headers: { 'Content-Type': 'application/json' },
    },
  );
};

export const putContractingDetails = ({
  data,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/info/${companyId}`,
    {
      method: 'PUT',
      body: data,
      headers: { 'Content-Type': 'application/json' },
    },
  );
};

export const getSignatories = ({companyId}) => {

  return fetcher(`/api/v2/company/signatories/${companyId}`);
};

export const postSignatories = ({
  companyId,
  data,
}) => {

  return fetcher(`/api/v2/company/signatories/${companyId}`,{
    method: 'POST',
    body: data,
  });
};

export const updateSignatories = ({
  companyId,
  data,
}) => {

  return fetcher(`/api/v2/company/signatories/${companyId}`,{
    method: 'PUT',
    body: data,
  });
};

export const allFieldsUploaded = ({
  userId,
  companyId,
}) => {

  return fetcher(
    `/api/v2/company/update/${companyId}/fieldsComplete`,
    {
      method: 'GET',
      query: {updatedBy: userId},
    },
  );
};

export const getImplementation = ({companyId}) => {

  return fetcher(`/api/v2/company/implementation/${companyId}`);
};

export const beginImplementation = ({
  updatedBy,
  companyId,
  implementationPlan,
}) => {

  return fetcher(
    `/api/v2/company/implementation/${companyId}`,
    {
      method: 'POST',
      body: {
        ...implementationPlan,
        updatedBy,
      },
    },
  );
};

export const updateImplementation = ({
  id,
  data,
  updatedBy,
  companyId,
})  => {

  return fetcher(
    `/api/v2/company/implementation/${companyId}`,
    {
      method: 'PUT',
      body: {
        id,
        data,
        updatedBy,
      },
    },
  );
};

export const getCompanyActions = ({companyId}) => {
  return fetcher(`/api/v2/company/actions/${companyId}`);
};

export const getRequiredEvidence = ({companyId}) => {

  return fetcher(`/api/v2/company/evidence/${companyId}`);
};

export const uploadEvidenceFile = ({
  companyId,
  ...data
}) => {

  const body = new FormData();

  for (const [key, value] of Object.entries(data)) {

    if (value === null || value === undefined) {
      continue;
    }

    body.append(key, value);
  }

  return fetcher(
    `/api/v2/company/evidence/${companyId}/upload`,
    {
      method: 'POST',
      headers: {'Content-Type': null},
      body,
    },
  );
};

export const putEvidenceData = ({
  companyId,
  data,
}) => {

  return fetcher(`/api/v2/company/evidence/${companyId}/submitted`, {
    method: 'PUT',
    body: data,
  });
};

export const deleteEvidenceData = ({
  companyId,
  evidenceFile,
  link,
  category,
  fileName,
}) => {

  return fetcher(`/api/v2/company/evidence/${companyId}/submitted`, {
    method: 'DELETE',
    body: {
      evidenceFile,
      link,
      category,
      fileName,
    },
  });
};

export const getCompanyEvidence = (companyId) => {

  return fetcher(`/api/v2/company/evidence/${companyId}/submitted`);
};

export const linkEvidence = ({
  companyId,
  data,
}) => {

  return fetcher(
    `/api/v2/company/evidence/${companyId}/submitted`,
    {
      method: 'POST',
      body: data,
    },
  );
};

export const postEvidenceLink = ({
  companyId,
  ...data
}) => {

  return fetcher(
    `/api/v2/company/evidence/${companyId}`,
    {
      method: 'POST',
      body: data,
    },
  );
};

export const getCompanyReports = ({companyId}) => {

  return fetcher(`/api/v2/company/report/${companyId}`);
};

export const resetPassword = ({email}) => {

  return fetcher(
    `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
    {
      method: 'POST',
      body: {
        email,
        'client_id': process.env.REACT_APP_AUTH0_CLIENT_ID,
        connection: 'Username-Password-Authentication',
      },
      removeCachingHeaders: true,
    },
  );
};

export const getCompanyUsers = ({companyId}) => {

  return fetcher(`/api/v2/company/users/${companyId}`);
};

export const linkUserToCompany = ({
  email,
  companyId,
  userType,
}) => {

  return fetcher(
    '/api/v2/user/company/invite',
    {
      method: 'POST',
      body: {
        email,
        companyId,
        userType,
      },
    },
  );
};

export const acceptUserLink = ({
  userId,
  companyId,
  accept,
}) => {

  return fetcher(
    '/api/v2/user/company/invite',
    {
      method: 'PUT',
      body: {
        userId,
        companyId,
        accept,
      },
    },
  );
};

export const beginCropJourney = ({
  userId, companyId,
}) => {

  return fetcher(
    `/api/v2/company/journey/${companyId}/crop`,
    {
      method: 'POST',
      query: {userId},
    },
  );
};

export const getCarbonJourneyInterests = ({companyId}) => {

  return fetcher('/api/v2/user/expressInterest', {query: {companyId}});

};

export const postCarbonJourneyInterest = ({
  companyId,
  requestedBy,
  dateCreated,
  programme,
  hectares,
  description,
  regenStarted,
}) => {

  return fetcher(
    '/api/v2/user/expressInterest',
    {
      method: 'POST',
      body: {
        companyId,
        requestedBy,
        dateCreated,
        programme,
        hectares,
        description,
        regenStarted,
      },
    },
  );
};

export const loadReports = ({
  companyId,
  typeFilter,
}) => {

  return fetcher(
    `/api/v2/company/report/${companyId}`,
    {
      method: 'GET',
      query: {typeFilter},
    },
  );
};

export const resendVerificationEmail = () => {

  return fetcher('/api/v2/user/verification/resend');
};