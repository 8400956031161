import {Colors} from 'appConstants';
import {TextButton} from 'components/buttons';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import React from 'react';

const Circle = (props) => (
  <Container
    css={`
      background: ${props.fillColor};
      border: 2px solid ${props.borderColor};
      width: 18px;
      height: 18px;
      border-radius: 50%;
    `}
  />
);

export const StatusTrackerSimple = (props) => {

  const items = [];

  let complete = true;
  for (let i = 0; i < props.items?.length; i++) {

    let current = false;
    if (complete && props.items[i].inProgress) {

      complete = false;
      current = true;
    }

    const text = props.items[i].text;
    const fillColor = (complete && !current && !props.notStarted)
      ? Colors.GREEN.LIME
      : Colors.TRANSPARENT.TRANSPARENT;
    const borderColor = ((complete || current) && !props.notStarted)
      ? Colors.GREEN.LIME
      : Colors.WHITE;
    const nextExists = i+1 < props.items.length;
    const nextColor = (nextExists && complete) ? Colors.GREEN.LIME : Colors.WHITE;

    items.push(
      <HorizontalContainer
        key={props.items[i].key}
        css={`
          align-items: center;
          gap: 8px;
        `}
      >
        <Circle
          fillColor={fillColor}
          borderColor={borderColor}
        />
        <TextButton
          text={text}
          css={`
            margin: 0 0;
            box-sizing: border-box;
            height: 18px;
          `}
          textCss={`
            line-height: 90%;
            font-weight: ${props.items[i].active ? '400' : '200'};
            ${props.items[i].active ? `border-bottom: ${Colors.GREEN.LIME} solid 1px;` : ''};
          `}
          onClick={props.items[i].onClick}
        />
      </HorizontalContainer>,
    );

    nextExists && items.push(
      <Container
        key={`${text}-line`}
        css={`
          margin-left: 8px;
          border: 1px solid ${nextColor};
          width: 0;
          height: 12px;
          line-height: 0;
        `}
      />,
    );
  }

  return (
    <Container
      css={`
        ${props.css};
      `}
    >

      {items}
    </Container>
  );
};