import {calculateFieldArea} from '@orizonagriculture/orizonutils';
import {TextStyles} from 'appConstants';
import {
  Button,
  Container,
  ModalContext,
  NotificationContext,
  Text,
} from 'components';
import {MapFieldsDrawNew} from 'compositeComponents/addFields/MapFieldsDrawNew';
import {MapFieldsDataForm} from 'compositeComponents/index';
import {UserContext} from 'context';
import {CompanyContext} from 'context/CompanyContext';
import PropTypes from 'prop-types';
import {
  postField,
  postMultiFields,
} from 'providers';
import React from 'react';

export const MapNewField = (props) => {

  const [section, setSection] = React.useState('map');
  const [drawnField, setDrawnField] = React.useState({});

  const notificationContext = React.useContext(NotificationContext);
  const modalContext = React.useContext(ModalContext);
  const {activeCompany} = React.useContext(CompanyContext);
  const {userInfo} = React.useContext(UserContext);

  const getFieldShape = (field) => {

    return field.shape === 'polygon'
      ? {
        fieldShape: field.shape,
        coordData: JSON.stringify(field.coords),
      }
      : {
        fieldShape: field.shape,
        coordData: JSON.stringify({
          radius: field.radius,
          center: field.center,
        }),
      };
  };

  const postMultipleFields = (drawnFields) => {

    const data = {
      updatedBy: userInfo.id,
      fields: [],
    };

    for (const item of Object.values(drawnFields)) {

      data.fields.push(getFieldShape(item));
    }

    return postMultiFields({
      data,
      companyId: activeCompany.id,
    })
      .then((res) => {

        if(!res.ok) {

          console.error('Unable to upload fields', res);
          notificationContext.setError('Unable to post fields');
          return;
        }

        setDrawnField([]);
        props.onComplete();
      })
      .catch((err) => {

        console.error('An error occurred posting multi fields', err);
        notificationContext.setError('An error occurred posting multi fields');
      });
  };

  const postSingleField = (formState) => {

    const fieldPolygon = Object.values(drawnField)[0];

    const data = {
      updatedBy: userInfo.id,
      farmId: formState.farmId,
      notIncluded: formState.notIncluded,
      notIncludedReason: formState.notIncludedReason,
      notIncludedTime: formState.notIncludedTime,
      eligibility: formState.eligibility,
      description: formState.description,
      name: formState.name,
      hectares: formState.hectares,
      mainActivity: formState.mainActivity,
      fieldPolygon: getFieldShape(fieldPolygon),
      soilData: formState.soilData,
    };

    return postField({
      data,
      companyId: activeCompany.id,
    })
      .then((res) => {

        if (!res.ok) {

          if (res.data?.errors?.includes('name-taken')) {

            notificationContext.setError('A field with this name is already associated with this farm\n Please enter a different name');
            return;
          }

          notificationContext.setError('Unable to post field');
          console.error('Unable to post field', res);
          return;
        }

        notificationContext.setMessage('Field complete');
        props.onComplete();
      })
      .catch((err) => {

        notificationContext.setError('An error occurred posting field');
        console.error('An error occurred posting field', err);
      });
  };

  const mappingComplete = (drawnField) => {

    setDrawnField(drawnField);

    if (Object.keys(drawnField).length === 1) {

      setSection('form');
      return;
    }

    modalContext.setState(
      <Container
        css={`
          padding: 16px;
          gap: 4px;
          align-items: center;
        `}
      >
        <Text
          text={'You are uploading multiple fields'}
          css={TextStyles.SMALL_HEADING}
        />
        <Text
          text={'After this, you will be need to fill out some information for each of these fields.'}
        />

        <Button
          text={'Continue'}
          onClick={() => {

            modalContext.close();
            return postMultipleFields(drawnField);
          }}
        />
      </Container>,
    );
  };

  const clear = () => {
    setSection('map');
  };

  const area = calculateFieldArea({
    coords: Object.values(drawnField)?.[0]?.coords,
    radius: Object.values(drawnField)?.[0]?.radius,
    internalPolys: Object.values(drawnField)?.[0]?.internalPolys,
  });

  return(
    <Container>

      {section === 'map' &&
        <MapFieldsDrawNew
          initialPolygons={props.initialFields}
          back={props.back}
          isEditable={false}
          onComplete={mappingComplete}
        />
      }

      {section === 'form' &&
        <MapFieldsDataForm
          disableForm={!props.active}
          submit={postSingleField}
          back={clear}
          farms={props.farms}
          area={area}
        />
      }
      
    </Container>
  );
};

MapNewField.propTypes = {
  active: PropTypes.bool,
  back: PropTypes.func,
  farms: PropTypes.array,
  initialFields: PropTypes.array,
  user: PropTypes.object,
  onComplete: PropTypes.func,
};