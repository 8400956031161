
import {
  Colors,
  displayDate,
} from 'appConstants';
import {Button} from 'components/buttons';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {Form} from 'components/form/Form';
import {FormContext} from 'components/form/FormContext';
import {FormInputLabel} from 'components/form/FormInputLabel';
import {FormInputTooltip} from 'components/form/FormInputTooltip';
import {
  Table,
  Text,
} from 'components/general';
import {ModalContext} from 'components/modal';
import PropTypes from 'prop-types';
import React from 'react';

export const AddLineItem = (props) => {

  const formContext = React.useContext(FormContext);
  const modalContext = React.useContext(ModalContext);

  const headings = [];

  if (!props.headings) {

    for (let i = 0; i < props.items?.length; i++) {

      const cur = props.items[i];

      if (cur?.label) {
        headings.push(cur.label);
      }

    }
  }

  if (props.headings) {

    for(const item of props.headings) {
      headings.push(item.label);
    }
  }

  const resetFormState = () => {

    const filteredFormState = {};
    for (const [key, value] of Object.entries(formContext.state)) {

      if (!(key.split('.')[0] === `${props.name}-li`)) {

        filteredFormState[key] = value;
      }
    }

    formContext.setState(filteredFormState);
  };

  const getRowValues = (items, object) => {

    const vals = [];
    for (const item of items) {

      if(!item.name) {
        continue;
      }

      //Get the display value:
      // DropDown:
      if(item.component?.displayName === 'DropDown') {

        const option = item.options.find((subItem) => subItem.value === object[item.name]);
        vals.push(option?.label);
        continue;
      }

      // Date
      if(item.component?.displayName === 'DatePicker') {

        vals.push(displayDate(object[item.name]));
        continue;
      }

      //MultiSelector
      if(item.component?.displayName === 'MultiSelector') {

        const selectedItems = object[item.name];
        vals.push(selectedItems.join(', '));
        continue;
      }

      // TextInput/TextArea
      vals.push(object[item.name] ?? '');
    }

    return vals;
  };

  const tableRows = [];
  if (props.showTable) {

    for (const object of props.value ?? formContext.state?.[props.name] ?? []) {

      if (props.headings) {

        tableRows.push(getRowValues(props.headings, object));
        continue;
      }

      tableRows.push(getRowValues(props.items, object));

    }
  }

  const finishedLine = (formState) => {

    modalContext.close();

    const values = {};

    for(const [key, value] of Object.entries(formState[`${props.name}-li`])) {
      values[key] = value;
    }

    delete formState[`${props.name}-li`];

    const valueArray = formContext.state[props.name] ?? [];

    valueArray.push(values);

    if (props.onChange) {

      props.onChange({
        name: props.name,
        value: valueArray,
      });

      // Clear temporary values added by modal from formContext.state
      formContext.setState(formState);

      return;
    }

    formContext.setState({
      ...formState,
      [props.name]: valueArray,
    });
  };

  const addItem = () => {

    modalContext.setState({
      onClose: resetFormState,
      content: (
        <Container
          css={`
            max-height: 60vh;
            width: 60vw;
            max-width: 60vw;
          `}
        >
          <Form
            title={props.formTitle}
            subTitle={props.formSubTitle}
            items={props.items}
            onSubmit={finishedLine}
            prependName={`${props.name}-li`}
            dynamicOpts={props.dynamicOpts}
          />
        </Container>
      ),
    });
  };

  const finishedEditLine = (formState, pos) => {

    const values = {};

    for(const [key, value] of Object.entries(formState[`${props.name}-li`])) {
      values[key] = value;
    }

    delete formState[`${props.name}-li`];

    const valueArray = formContext.state[props.name];

    valueArray[pos] = values;

    formContext.setState({
      ...formState,
      [props.name]: valueArray,
    });

    modalContext.close();
  };

  const showEditForm = (pos) => {

    modalContext.setState({
      onClose: resetFormState,
      content: (
        <Container
          css={`
            min-height: 60vh;
            min-width: 50vw;
          `}
        >
          <Form
            title={`Edit ${props.formTitle}`}
            items={props.items}
            onSubmit={(formState) => finishedEditLine(formState, pos)}
            prependName={`${props.name}-li`}
          />
        </Container>
      ),
    });
  };

  const editLine = (pos) => {

    const editItem = formContext.state[props.name][pos];

    const editValues = {};
    for (const [key, value] of Object.entries(editItem)) {
      editValues[`${props.name}-li.${key}`] = value;
    }

    formContext.setState((prev) => {

      return{
        ...prev,
        ...editValues,
      };
    });
    showEditForm(pos);
  };

  return(
    <Container
      css={`
        margin-top: 8px;

        ${props.css}
      `}
    >

      <HorizontalContainer
        css={`
          margin-bottom: ${!props.label && props.tooltip ? '8px' : ''};
        `}
      >

        {props.label &&
          <FormInputLabel
            text={props.label}
            optionalText={props.required === false}
            css={`
              margin-right: ${props.tooltip ? '12px' : '0'};
              margin-bottom: 4px;
            `}
          />
        }

        {props.tooltip &&
          <FormInputTooltip
            text={props.tooltip}
            css={`
              margin-right: -8px;
              margin-top: -4px;
            `}
          />
        }

      </HorizontalContainer>

      {props.showTable && tableRows.length > 0 &&
        <Table
          headings={headings}
          rows={tableRows}
          onRowClick={(!props.disabled && !props.value) ? editLine : null}
        />
      }

      {props.errorText &&
        <Text
          text={props.errorText}
          css={`
            color: ${Colors.RED};
            margin: 6px 0 0 4px;
          `}
        />
      }

      {props.hint &&
        <Text
          text={props.hint}
          css={`
            color: ${Colors.GREY.LIGHT};
            margin: 6px 0 0 4px;
          `}
        />
      }

      {!props.disabled &&
        <Button
          css={`
            margin-top: 8px;
            margin-bottom: 8px;
          `}
          onClick={addItem}
          text={props.buttonText}
        />
      }

      {tableRows.length === 0 && props.required &&
        <Text text={'(At least 1 required)'} />
      }
    </Container>
  );

};

AddLineItem.displayName = 'AddLineItem';

AddLineItem.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  dynamicOpts: PropTypes.object,
  errorText: PropTypes.string,
  formSubTitle: PropTypes.string,
  formTitle: PropTypes.string,
  headings: PropTypes.array,
  hint: PropTypes.string,
  invalid: PropTypes.bool,
  items: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  showTable: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

AddLineItem.defaultProps= {showTable: true};
