import {Colors} from 'appConstants';
import {Text} from 'components/general/Text';
import * as L from 'leaflet';
import React from 'react';
import {
  Circle,
  Marker,
  Polygon,
  Popup,
  Tooltip,
} from 'react-leaflet';

export const extractPolygon = ({layer}) => {

  const shape = {shape: 'none'};

  if (layer instanceof L.Circle) {

    const center = layer.getLatLng();
    shape.shape = 'circle';
    shape.radius = layer.getRadius();
    shape.center = {
      lat: center.lat,
      lng: center.lng,
    };
  }

  if (layer instanceof L.Polygon) {

    const latLngArray = layer.getLatLngs();

    const coords = Array.isArray(latLngArray[0][0])
      ? latLngArray[0][0]
      : latLngArray[0];

    const internalPolys = Array.isArray(latLngArray[0])
      ? latLngArray.slice(1)
      : null;

    shape.shape = 'polygon';
    shape.coords = coords;
    shape.internalPolys = internalPolys;
  }

  if(shape.shape !== 'none') {
    return shape;
  }
};

export const generateShapes = (shapes, overrideOptions={}, overridePolys) => {

  const polygons = [];

  for (const shape of (shapes ?? [])) {

    const overridden = overridePolys?.[shape.id];

    const pathOptions = shape.pathOptions ?? {};

    if (shape.fieldShape === 'polygon') {

      const process = (coordData) => {

        const latLngs = [];
        for (const latLng of coordData) {

          if (Array.isArray(latLng) && latLng.length === 2) {

            latLngs.push(latLng);
            continue;
          }

          if(Array.isArray(latLng)) {

            latLngs.push(process(latLng));
            continue;
          }

          latLngs.push([latLng.lat, latLng.lng]);
        }
        return latLngs;
      };

      const latLngs = overridden ? overridden.coords : process(shape.coordData);

      const internals = shape.internalPolys;
      let newShape = latLngs;

      if (internals) {
        newShape = [newShape, ...internals];
      }

      const poly =
        <Polygon
          key={shape.id}
          positions={newShape}
          eventHandlers={shape.eventHandlers}
          pathOptions={{
            color: Colors.BLUE.DARK,
            key: shape.id,
            weight: 1,
            pmIgnore: true,
            ...pathOptions,
            ...overrideOptions,
          }}
        >
          <Tooltip >
            <Text
              text={shape.name}
              css={`
                color: ${Colors.BLACK};
              `}
            />
          </Tooltip>
        </Polygon>;

      polygons.push(poly);
      continue;
    }

    if(shape.fieldShape === 'circle') {

      const circle =
        <Circle
          key={shape.id}
          center={shape.coordData.center}
          radius={shape.coordData.radius}
          eventHandlers={shape.eventHandlers}
          pathOptions={{
            color: Colors.BLUE.DARK,
            ...pathOptions,
            weight: 1,
            pmIgnore: true,
          }}
        >
          <Tooltip >{shape.name}</Tooltip>
        </Circle>;

      polygons.push(circle);
    }

    if(shape.fieldShape === 'point') {

      const point =
        <Marker
          position={shape.point}
          key={shape.id}
        >
          <Popup>{shape.info}</Popup>
        </Marker>;
      polygons.push(point);
    }

  }

  return polygons;
};