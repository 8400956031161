import {
  Colors,
  makeTransparent,
  TextStyles,
} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {CheckBox} from 'components/form/CheckBox';
import {FormContext} from 'components/form/FormContext';
import {FormInputLabel} from 'components/form/FormInputLabel';
import {
  Image,
  Text,
} from 'components/general';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    ${(props) => props.css};
`;

export const MultiSelectDropDown = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [filterText, setFilterText] = React.useState(null);

  const optionsContainerRef = React.useRef(null);
  const inputRef = React.useRef(null);

  const formContext = React.useContext(FormContext);

  const handleSelectValue = (changedName) => {

    const oldValue = props.value ?? formContext.state[props.name] ?? [];
    let newValue = oldValue.includes(changedName) ? oldValue.filter((item) => item !== changedName) : [...oldValue, changedName];

    if (newValue.length === 0)  {
      newValue = null;
    }

    if (props.onChange) {

      setTimeout(() => inputRef.current.focus(), 10);
      props.onChange({
        name: props.name,
        value: newValue,
      });
      return;
    }

    formContext.setState((prev) => ({
      ...prev,
      [props.name]: newValue,
    }));
    setTimeout(() => inputRef.current.focus(), 10);
  };

  const handleOpenDropdown = () => {

    setIsOpen(true);
    setFilterText('');
  };

  const handleCloseDropdown = () => {

    if (!optionsContainerRef.current) {
      return;
    }

    setIsOpen(false);
    setFilterText(null);
  };

  const handleFilterOptions = (e) => {

    let value = e.target.value;
    setFilterText(value ?? '');
    optionsContainerRef.current.scrollTop = 0;
  };

  const dropdownOptions = [];
  let displayText = [];
  const currentValue = props.value ?? formContext.state[props.name];

  // Create dropdown options
  for (const option of props.options) {

    const value = props.value
      ? props.value.includes(option.value) ?? false
      : formContext.state[props.name]?.includes(option.value) ?? false;

    if (value) {
      displayText.push(option.label);
    }

    if (filterText && !(String(option.label).toLowerCase().includes(filterText.toLowerCase()) || String(option.value).toLowerCase().includes(filterText.toLowerCase()))) {
      continue;
    }

    dropdownOptions.push(
      <Container
        onMouseDown={() => {

          handleSelectValue(option.value);
          setFilterText(null);
        }}
        css={`
            padding: 10px 12px;
            border-bottom: 1px solid ${Colors.TRANSPARENT.WHITE};
            margin: 0;
            cursor: pointer;

            &:hover {
                background-color: ${makeTransparent(Colors.GREEN.LIGHT, 0.1)};
            }

            &:last-of-type {
                border-bottom: none;
            }
        `}
        key={option.key ?? option.value}
      >
        <CheckBox
          label={option.label}
          value={value}
          onChange={() => {}} // TODO: Replace with more permanent solution - Prevents 'CheckBox' from adding 'undefined: true/false' to formContext.state
          key={option.key ?? option.value}
        />
      </Container>,
    );
  }

  if (displayText.length === props.options.length) {
    displayText = ['All'];
  }

  // Handle default values
  React.useEffect(() => {

    // If a value has already been set
    if (currentValue) {

      return;
    }

    // If a dynamic default has been set
    if (props.default) {

      formContext.setState((prev) => ({
        ...prev,
        [props.name]: props.default,
      }));
      return;
    }

    // Check whether an option in 'props.options' has been set as default
    for (const option of (props.options ?? [])) {

      if (option.default) {

        formContext.setState((prev) => ({
          ...prev,
          [props.name]: option.value,
        }));
        return;
      }
    }
  }, [props.default]);

  return (
    <Container
      css={`
          position: relative;
          flex: 1;
          ${props.css}
      `}
    >

      <HorizontalContainer
        css={`
            position: relative;
            padding: 0 4px;
            align-items: center;
            border-radius: 8px;
            height: 42px;
            transition: border-color 100ms;
            border: 2px solid ${Colors.TRANSPARENT.WHITE};
            background-color: ${Colors.TRANSPARENT.GREEN.DARK};
            ${isOpen ? 'border-bottom-left-radius: 0;' : ''}

            &:has(input:focus) {
                border-color: ${Colors.GREEN.LIME};
            }

            &:has(input:disabled) {
                background-color: ${Colors.TRANSPARENT.WHITE};
                &:has(input:not(:placeholder-shown)) label {
                    transform: translateY(-24px);
                }
            }

            ${props.inputContainerCss}
        `}
        onClick={props.onClick}
      >
        <Input
          value={filterText ?? displayText.join(', ') ?? formContext.state[props.name] ?? ''}
          onChange={handleFilterOptions}
          placeholder={props.placeholder ?? 'Please select'}
          onFocus={handleOpenDropdown}
          onBlur={handleCloseDropdown}
          disabled={props.disabled}
          ref={inputRef}
          css={`
              ${TextStyles.BODY}
              font-size: 18px; // Prevent font size from shrhinking because of 'clamp()'
              font-family: "Josefin Sans", sans-serif;
              color: ${Colors.WHITE};

              transform: translateY(2px);
              background-color: transparent;
              border: none;
              padding: 2px 0 0 4px;
              outline: none;
              width: 100%;

              &::placeholder {
                  color: ${Colors.GREY.TRANS_LIGHT};
              }
              &:not(:focus)::placeholder {
                  color: transparent;
              }
              &:focus ~ label, &:not(:placeholder-shown) ~ label {

                  pointer-events: auto;
                  transform: translate(2px, -16px);
                  color: ${Colors.GREEN.LIME};
                  font-size: 14px;

                  &:before {
                      position: absolute;
                      left: 0;
                      top: 7px;
                      content: "";
                      height: 4px;
                      width: 100%;
                      z-index: -1;
                      background-color: ${Colors.GREEN.DARK};
                      border-radius: 1px;
                  }
              }
              &:disabled {
                  background-color: transparent;
              }
          `}
        />

        <FormInputLabel
          text={props.label}
          optionalText={props.required === false}
          css={`
              color: ${Colors.GREY.LIGHT};
              padding: 0 4px;
              max-width: calc(100% - (24px + ${props.suffixImage ? '18px' : '0px'}));
              border-radius: 6px;

              position: absolute;
              top: 7px;
              transform: translateY(2px);
              pointer-events: none;
              transition-duration: 150ms;
              transition-property: color, font-size, transform;
              justify-self: start;

              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              &:hover {
                  overflow: visible;
                  white-space: normal;
                  background-color: ${Colors.GREEN.DARK};
              }

              ${props.labelCss}
          `}
        />

        <Image
          height={'16px'}
          width={'16px'}
          alt={'Dropdown arrow'}
          src={'ArrowDown'}
          onClick={() => setIsOpen((prev) => !prev)}
          css={`
              padding: 2px;
              margin-right: 2px;
              fill: ${Colors.WHITE};
              transition: transform 100ms;
              transform: rotate(${isOpen ? '-180deg' : '1deg'});
          `}
        />
      </HorizontalContainer>

      <Container
        ref={optionsContainerRef}
        css={`
            position: absolute;
            transform: translateY(42px);
            z-index: 9999;
            max-height: ${isOpen ? '240px' : '0'};
            max-width: 95%;
            pointer-events: none;
            overflow: hidden;
            overflow-y: ${isOpen ? 'auto' : 'hidden'};
            transition: max-height 100ms;

            ${props.dropdownOptionsContainerCss}
        `}
      >
        <Container
          css={`
              background-color: ${Colors.GREEN.OFF_DARK};
              border: 2px solid ${Colors.TRANSPARENT.WHITE};
              border-top: none;
              border-radius: 0 0 8px 8px;
              width: 100%;
              pointer-events: auto;
              transition: transform 100ms;
              transform: translateY(${isOpen ? '0' : '-105%'});
          `}
        >
          {dropdownOptions.length > 0 ? dropdownOptions : (
            <Text
              text={'No results'}
              css={`
                  padding: 0 16px;
                  color: ${Colors.GREY.TRANS_LIGHT};
              `}
            />
          )}
        </Container>
      </Container>

    </Container>
  );
};

MultiSelectDropDown.proptypes = {
  css: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  invalid: PropTypes.bool,
  options: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

MultiSelectDropDown.displayName = 'MultiSelectDropDown';