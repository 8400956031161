import {Colors} from 'appConstants';
import {
  Container,
  HorizontalContainer,
} from 'components/containers';
import {Image} from 'components/general/Image';
import {Text} from 'components/general/Text';
import React from 'react';

export const InfoGraphicText = (props) => {

  return (

    <HorizontalContainer
      css={`
        padding: 16px 0;
        border-bottom: 1px solid ${Colors.TRANSPARENT.WHITE};
        margin-right: 32px;
        align-items: center;
        ${props.css};
      `}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <Image
        height={props.imgHeight ?? '25px'}
        width={props.imgWidth ?? '25px'}
        src={props.src}
        alt={props.alt}
      />

      <Container
        css={`
          padding-left: 32px;
          max-width: 85%;
        `}
      >
        <Text
          css={`
            font-weight: 400;
            ${props.textCss}
          `}
          text={props.title}
        />

        <Text
          css={`
            color: ${Colors.WHITE};
            font-weight: 300;
            word-break: break-word;
          `}
          text={props.description}
        />
      </Container>

    </HorizontalContainer>
  );
};