import {
  Colors,
  processFormConfig,
  TextStyles,
} from 'appConstants';
import {FormContext} from 'components/form/index';
import {
  Button,
  Container,
  HorizontalContainer,
  Text,
} from 'components/index';
import {ScreenContext} from 'context';
import PropTypes from 'prop-types';
import React from 'react';
import {keysToObject} from 'scenes/profile/utils';

/**
 * Item props:
 *  component     JSX
 *  name:         string
 *  required      bool (true if not specifically false) || function(formState):bool
 *  pattern       string (matched to above regex list)
 *  minLength     number (only for strings and numbers)
 *  maxLength     number (only for strings and numbers)
 *  valid         function(formState):bool
 *  dynamicOpts   Form.props.dynamicOpts.componentName - this will get spread into the formConfig of the relevant component
 *
 *  Any config values provided as a function - that Form does not explicitly expect to be a function - will be resolved and forwarded to the component with: function(formState)
 */
export const Form = (props) => {

  const formItems = [];
  const formContext = React.useContext(FormContext);
  const {currentView} = React.useContext(ScreenContext);

  const [edited, setEdited] = React.useState(false);

  const submit = () => {

    setEdited(false);

    const mappedFormState = keysToObject(formContext.state);
    return props.onSubmit(mappedFormState);
  };

  const onBackClick = () => {

    const mappedFormState = keysToObject(formContext.state);
    return props.back(mappedFormState);
  };

  const checkVals = {
    requiredCompleted: true,
    invalid: false,
  };

  const populateUnacceptedValues = (dynamicProps, unacceptedValues) => {

    if (edited) {
      return dynamicProps;
    }

    const newDynamicProps = {...dynamicProps};

    for (const value of unacceptedValues) {

      if (newDynamicProps[value]) {

        newDynamicProps[value].valid = 'Too many characters';
        continue;
      }
      newDynamicProps[value] = {valid: 'Too many characters'};
    }

    return newDynamicProps;
  };

  let builtDynamicOpts = props.dynamicOpts;
  if (props.unacceptedValues) {
    builtDynamicOpts = populateUnacceptedValues(props.dynamicOpts ?? {}, props.unacceptedValues);
  }

  processFormConfig({
    items: props.items,
    formItems,
    formContext,
    dynamicOpts: builtDynamicOpts,
    disabled: props.disableInput,
    currentView,
    prependName: props.prependName,
    checkVals,
  });

  const completeAndValid = props.noneRequired || (checkVals.requiredCompleted && !checkVals.invalid);

  //Pre-populate values
  React.useEffect(() => {

    if (!props.prePopulateValues) {
      return;
    }

    const editValues = {};

    const getNames = (items, allNames) => {

      for (const item of items) {

        if (Array.isArray(item)) {

          getNames(item, allNames);
          continue;
        }

        if(item.isGroup) {

          getNames(item.items, allNames);
          continue;
        }

        allNames.push(item.name);
      }
    };

    const allNames = [];
    getNames(props.items, allNames);

    for (const [key, value] of Object.entries(props.prePopulateValues)) {

      if (!allNames.includes(key) || value === null) {
        continue;
      }
      editValues[key] = value;
    }

    formContext.setState((prev) => {

      return{
        ...prev,
        ...editValues,
      };
    });

  }, [props.items]);

  // Set edited to true on any form context state change
  React.useEffect(() => {
    setEdited(true);
  }, [formContext.state]);

  return(
    <Container
      css={`
        border: ${Colors.GREY.TRANS_LIGHT} solid 2px;
        border-radius: 10px;
        padding: 32px;
        flex: 1;
        ${props.css}
      `}
    >
      {props.title &&
        <Text
          text={props.title}
          css={`
            ${TextStyles.HEADING};
            padding-bottom: 16px;
          `}
        />
      }

      {props.subTitle &&
        <Text
          css={`
            padding-bottom: 16px;
          `}
          text={props.subTitle}
        />
      }

      {formItems}

      <HorizontalContainer
        css={`
          justify-content: center;
        `}
      >

        {props.back &&
          <Button
            text={props.backText ?? 'Back'}
            onClick={onBackClick}
            disabled={props.backDisabled}
            css={`
              width: fit-content;
            `}
          />
        }

        {props.onSubmit &&
          <Button
            text={props.submitText ?? 'Submit'}
            onClick={submit}
            disabled={!completeAndValid || props.disabled || !edited}
            css={`
              ${!completeAndValid && `background: ${Colors.GREY.LIGHT}`};
              width: fit-content;
              ${props.submitButtonCss};
            `}
          />
        }
      </HorizontalContainer>
    </Container>
  );
};

Form.propTypes = {
  back: PropTypes.func,
  backDisabled: PropTypes.bool,
  backText: PropTypes.string,
  css: PropTypes.string,
  disableInput: PropTypes.bool,
  disabled: PropTypes.bool,
  dynamicOpts: PropTypes.object,
  items: PropTypes.array,
  noneRequired: PropTypes.bool,
  prePopulateValues: PropTypes.object,
  prependName: PropTypes.string,
  subTitle: PropTypes.string,
  submitButtonCss: PropTypes.string,
  submitText: PropTypes.string,
  title: PropTypes.string,
  unacceptedValues: PropTypes.array,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  disabled: false,
  backDisabled: false,
  noneRequired: false,
};