import {UserTypes} from '@orizonagriculture/orizonutils';

export const UserTypeText = {
  [UserTypes.OWNER]: 'Farmer / Land Owner',
  [UserTypes.STAFF]: 'Farm Manager / Staff / Associate',
  [UserTypes.SIGNATORY]: 'Signatory to Contract',
  [UserTypes.CONSULTANT]: 'Agri Consultant / Representative',
  [UserTypes.ADMIN_USER]: 'Admin User',
  [UserTypes.ADMIN_USER_REMOVED]: 'Admin User Removed',
  'regenerativeFarmer': 'Regenerative farmer',
  'interestedFarmer': 'Farmer interested in conservation practices',
  'academia': 'Academia',
  'agriConsultant': 'Agri-consultant',
  'interested': 'Interested party',
  'other': 'Academic / Other',
};

export const userTypeOptions= [
  {
    value: UserTypes.OWNER,
    label: UserTypeText[UserTypes.OWNER],
  },
  {
    value: UserTypes.STAFF,
    label: UserTypeText[UserTypes.STAFF],
  },
  {
    value: UserTypes.CONSULTANT,
    label: UserTypeText[UserTypes.CONSULTANT],
  },
  {
    value: 'other',
    label: UserTypeText['other'],
  },
];