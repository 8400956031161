
import {Colors} from 'appConstants';
import {Text} from 'components';
import {
  Container,
  HorizontalContainer,
} from 'components/containers/Container';
import PropTypes from 'prop-types';
import React from 'react';

export const TabbedContainer = (props) => {

  const tabClick = (key) => {

    if(key === props.selected) {
      return;
    }

    props.tabChange(key);
  };

  const tabs = [];
  for (const item of props.tabs) {

    tabs.push(
      <Container
        key={item.key}
        onClick={() => tabClick(item.key)}
        css={`
          padding: 8px;
          border: 1px solid ${Colors.GREEN.LIME};
          background: ${props.selected === item.key ? Colors.GREEN.LIME : Colors.TRANSPARENT.TRANSPARENT};
          border-bottom: none;
          border-radius: 8px 8px 0 0;
          display: block;
          margin: 8px 8px 0;
          position: relative;
          flex: 0 1 fit-content;
          text-overflow: ellipsis;
          min-width: 36px;

          ${item.key === props.selected ? `
            min-width: fit-content;

            :before {
              content: "";
              position: absolute;

              background-color: transparent;
              bottom: 0;
              right: -16px;
              height: 8px;
              width: 16px;
              border-bottom-left-radius: 8px;
              box-shadow: -8px 0 0 0 ${Colors.GREEN.LIME};
            }

            :after {
              content: "";
              position: absolute;

              background-color: transparent;
              bottom: 0;
              left: -16px;
              height: 8px;
              width: 16px;
              border-bottom-right-radius: 8px;
              box-shadow: 8px 0 0 0 ${Colors.GREEN.LIME};
            }
          ` : ''}
        `}
      >
        <Text
          text={item.text}
          css={`
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            flex: 1;
          `}
        />
      </Container>,
    );
  }

  return(
    <Container
      css={`
        container-type: inline-size;
      `}
    >

      <HorizontalContainer
        css={`
          margin: 0 0 0 8px;
          max-width: calc(100% - 16px);
          overflow: hidden;
          overflow-x: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
        `}
      >
        {tabs}
      </HorizontalContainer>

      <Container
        css={`
          padding: clamp(8px, 2cqi, 20px);
          border: 1px solid ${Colors.GREEN.LIME};
          border-radius: 8px;
          ${props.css}
        `}
      >
        {props.children}
      </Container>
    </Container>
  );
};

TabbedContainer.propTypes = {
  css: PropTypes.string,
  selected: PropTypes.string,
  tabChange: PropTypes.func,
  tabs: PropTypes.array,
};