export const ProvinceOptions = [
  {
    value: 'EC',
    label: 'Eastern Cape',
  },
  {
    value: 'FS',
    label: 'Free State',
  },
  {
    value: 'GT',
    label: 'Gauteng',
  },
  {
    value: 'NL',
    label: 'KwaZulu-Natal',
  },
  {
    value: 'LP',
    label: 'Limpopo',
  },
  {
    value: 'MP',
    label: 'Mpumalanga',
  },
  {
    value: 'NC',
    label: 'Northern Cape',
  },
  {
    value: 'NW',
    label: 'North West',
  },
  {
    value: 'WC',
    label: 'Western Cape',
  },
];