import {
  Colors,
  makeTransparent,
} from 'appConstants';
import icon from 'assets/locationPin2.png';
import {Container} from 'components/containers';
import {FullScreenLoader} from 'components/general/FullScreenLoader';
import {MapGotoLocation} from 'components/general/maps/MapGotoLocation';
import {generateShapes} from 'components/general/maps/utils';
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import PropTypes from 'prop-types';
import React from 'react';
import VectorBasemapLayer from 'react-esri-leaflet/plugins/VectorBasemapLayer';
import {MapContainer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import MarkerClusterGroup from 'react-leaflet-cluster';

let defaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [60, 60],
  shadowSize: [60, 60],
  iconAnchor: [25, 45],// [positive is left , positive is up]
  shadowAnchor: [15, 55],
});

L.Marker.prototype.options.icon = defaultIcon;

export const Map = (props) => {

  const [isLoading, setIsLoading] = React.useState(!!props.gotoLocation);

  const polygons = generateShapes(props.shapes);
  
  let zoomTo = null;
  if (props.shapes?.length >= 1) {

    switch (props.shapes[0].fieldShape) {
      case 'polygon':
        zoomTo = props.shapes[0].coordData[0];
        if (Array.isArray(zoomTo) && zoomTo.length > 2) {
          zoomTo = zoomTo[0];
        }
        break;

      case 'circle':
        zoomTo = props.shapes[0].coordData.center;
        break;

      case 'point':
        zoomTo = props.shapes[0].point;
        break;
    }
  }

  return(
    <Container
      css={`
        .marker-cluster-small,
        .marker-cluster-medium,
        .marker-cluster-large {
          background-color: ${makeTransparent(Colors.GREEN.LIME, 0.8)};
        }

        .marker-cluster-small div,
        .marker-cluster-medium div,
        .marker-cluster-large div  {
          background-color: ${Colors.GREEN.LIME};
          box-shadow: 0 0 10px 2px ${makeTransparent(Colors.BLACK, 0.15)};
        }
      `}
    >

      <MapContainer
        style={{
          height: '650px',
          width: '100%',
        }}
        center={[0, 0]}
        zoom={2}
        maxZoom={20}
        scrollWheelZoom={props.canZoom}
      >
        <MapGotoLocation
          onLocated={() => {
            setIsLoading(false);
          }}
          zoom={props.initialZoom}
          zoomTo={props.initialLocation ?? zoomTo}
          findLocation={props.gotoLocation}
        />

        {props.groupMarkers &&
          <MarkerClusterGroup
            disableClusteringAtZoom={14}
            polygonOptions={{color: Colors.GREEN.LIME}}
            spiderLegPolylineOptions={{color: Colors.GREEN.LIME}}
            chunkedLoading={true}
          >
            {polygons}
          </MarkerClusterGroup>
        }

        {!props.groupMarkers && polygons}

        {props.mapExtras}

        <VectorBasemapLayer
          name={'ArcGIS:Imagery'}
          token={process.env.REACT_APP_ARCGIS_TOKEN}
        />

      </MapContainer>

      {isLoading && <FullScreenLoader />}
    </Container>
  );
};

Map.propTypes = {
  canZoom: PropTypes.bool,
  defaultLayer: PropTypes.string,
  gotoLocation: PropTypes.bool,
  initialZoom: PropTypes.number,
  mapExtras: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  shapes: PropTypes.array,
};

Map.defaultProps = {
  canZoom: true,
  gotoLocation: true,
};
