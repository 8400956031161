import {fetcher} from 'providers/fetcher';

export const getUsers = ({
  userStep,
  baseline,
} = {}) => {

  return fetcher(
    '/api/v2/admin/users',
    {
      method: 'GET',
      query: {
        userStep,
        baseline,
      },
    },
  );
};

export const getCompanies = ({baseline} = {}) => {

  return fetcher('/api/v2/admin/companies', {query: {baseline}});
};

export const farmsComplete = ({
  companyId, userId,
}) => {

  return fetcher(
    `/api/v2/admin/update/${companyId}/farmsComplete`,
    {
      method: 'POST',
      query: {updatedBy: userId},
    },
  );
};

export const sentContract = ({
  companyId,
  userId,
}) => {

  return fetcher(
    `/api/v2/admin/update/${companyId}/contractSent`,
    {
      method: 'POST',
      query: {updatedBy: userId},
    },
  );
};

export const finaliseContract = ({
  companyId,
  userId,
  file,
}) => {

  const body = new FormData();
  body.append('file', file);

  return fetcher(
    `/api/v2/admin/company/${companyId}/contract/upload`,
    {
      headers: {'Content-Type': null},
      method: 'POST',
      query: {updatedBy: userId},
      body,
    },
  );
};

export const verifyViability = ({
  companyId,
  value,
  userId,
}) => {

  return fetcher(
    `/api/v2/admin/viability/${companyId}`,
    {
      method: 'POST',
      query: {
        value,
        updatedBy: userId,
      },
    },
  );
};

export const getEligibility = (companyId) => {

  return fetcher(`/api/v2/company/eligibility/${companyId}`);
};

export const verifyEligibility = ({
  companyId,
  value,
  userId,
}) => {

  return fetcher(
    `/api/v2/admin/eligibility/${companyId}`,
    {
      method: 'POST',
      query: {
        value,
        updatedBy: userId,
      },
    },
  );
};

export const getCompany = (companyId) => {

  return fetcher(`/api/v2/company/info/${companyId}`);
};

export const getUserCompanies = (userId)  => {

  return fetcher(`/api/v2/user/companies/${userId}`);
};

export const getAllEvidenceVerifications = (evidenceId) => {

  return fetcher(`/api/v2/admin/evidence/verification/${evidenceId}`);
};

export const getCompanyPracticeData = ({
  companyId,
  noFilter,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/all`,
    {query: {noFilter}},
  );
};

export const uploadEvidenceVerification = ({
  evidenceId,
  ...data
}) => {

  const body = new FormData();

  for (const [key, value] of Object.entries(data)) {

    if (value === null || value === undefined) {
      continue;
    }
    body.append(key, value);
  }

  return fetcher(
    `/api/v2/admin/evidence/verification/${evidenceId}/upload`,
    {
      headers: {'Content-Type': null},
      method: 'POST',
      body,
    },
  );
};

export const postEvidenceVerification = ({
  evidenceId,
  ...data
}) => {

  return fetcher(
    `/api/v2/admin/evidence/verification/${evidenceId}`,
    {
      method: 'POST',
      body: data,
    },
  );
};

export const getFieldAndPracticeSeasons = (companyId, filter) => {

  return fetcher(`/api/v2/pd/${companyId}/fieldsAndSeasons`, {query: {filter}});
};

export const postNewEvidenceRequest = ({
  adminId,
  companyId,
  title,
  description,
}) => {

  return fetcher(
    '/api/v2/admin/evidence/request',
    {
      method: 'POST',
      body: {
        requestedBy: adminId,
        title,
        description,
        companyId,
      },
    },
  );
};

export const evidenceVerificationComplete = ({
  companyId,
  updatedBy,
}) => {

  return fetcher(
    `/api/v2/admin/update/${companyId}/evidenceComplete`,
    {
      method: 'POST',
      query:{updatedBy},
    },
  );
};

export const updateUserCompany = ({
  companyId,
  data,
  updatedBy,
}) => {

  return fetcher(
    `/api/v2/company/info/${companyId}`,
    {
      method: 'PUT',
      body: {
        ...data,
        updatedBy,
      },
    },
  );
};

export const updateFarm = ({
  updatedBy,
  companyId,
  data,
}) => {

  return fetcher(
    `/api/v2/company/farms/${companyId}`,
    {
      method: 'PUT',
      body: {
        updatedBy,
        ...data,
      },
    },
  );
};

export const uploadUserReport = (data) => {

  const body = new FormData();

  for (const [key, value] of Object.entries(data)) {

    if (value === null || value === undefined) {
      continue;
    }
    body.append(key, value);
  }

  return fetcher(
    '/api/v2/admin/upload/report',
    {
      headers: {'Content-Type': null},
      method: 'POST',
      body,
    },
  );
};

export const getCalcConsts = () => {

  return fetcher('/api/v2/constants');
};

export const updateCalcConsts = (data) => {

  return fetcher(
    '/api/v2/constants',
    {
      method: 'POST',
      body: data,
    },
  );
};

export const overrideFieldStatus = ({
  fieldId,
  seasonId,
  status,
}) => {

  return fetcher(
    `/api/v2/pd/${fieldId}/seasons/${seasonId}/override`,
    {
      method: 'PUT',
      query: {status},
    },
  );
};

export const getRothCData = (companyId) => {

  return fetcher(`/api/v2/admin/generate/${companyId}/rothC`);
};


export const getCompanyOverrides = ({companyId}) => {

  return fetcher(`/api/v2/pd/${companyId}/overrides`);
};

export const postCompanyOverride = ({
  companyId,
  data,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/overrides`,
    {
      method: 'POST',
      body: data,
    },
  );
};

export const putUserOverride = ({
  companyId,
  data,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/overrides`,
    {
      method: 'PUT',
      body: data,
    },
  );
};

export const deleteUserOverride = ({
  companyId,
  id,
}) => {

  return fetcher(
    `/api/v2/pd/${companyId}/overrides`,
    {
      method: 'DELETE',
      query: {id},
    },
  );
};

export const updateAdminRole = ({
  email,
  action,
}) => {
  
  return fetcher(
    '/api/v2/admin/access',
    {
      method: 'POST',
      body: {
        email,
        action,
      },
    },
  );
};

export const getAllEventsAsAdmin = ({pdType}) => {

  return fetcher(
    '/api/v2/admin/pd',
    {query: {pdType}},
  );
};

export const getAllFarmsAsAdmin = () => {

  return fetcher('/api/v2/admin/farms');
};

export const getAllFieldsAsAdmin = ({companyIds}={}) => {

  return fetcher('/api/v2/admin/fields', {query: {companyId: companyIds}});
};

export const getAllUsers = () => {

  return fetcher('/api/v2/admin/users');
};

export const uploadSoilSamples = (file) => {

  const body = new FormData();
  body.append('file', file);

  return fetcher(
    '/api/v2/admin/upload/samples',
    {
      headers: {'Content-Type': null},
      method: 'POST',
      body,
    },
  );
};

export const getAllSoilSamples = () => {

  return fetcher('/api/v2/samples');
};

export const assignManagingAdmin = ({
  adminId,
  companyIds,
}) => {

  return fetcher('/api/v2/admin/users', {
    method: 'post',
    body: {
      companyIds,
      adminId,
    },
  });
};

export const postEmailTemplate = ({
  data,
  userId,
}) => {

  return fetcher('/api/v2/admin/email/template', {
    method: 'POST',
    body: data,
    query: {userId},
  });
};

export const getEmailTemplates = () => {

  return fetcher('/api/v2/admin/email/template');
};

export const sendDynamicEmail = ({
  templateId,
  userIds,
}) => {

  return fetcher(
    '/api/v2/admin/email/send',
    {
      method: 'POST',
      body: {
        templateId,
        userIds,
      },
    },
  );
};

export const getNotes = ({companyId}) => {

  return fetcher(`/api/v2/admin/company/${companyId}/notes`);
};

export const putNote = ({
  companyId,
  data,
  userId,
}) => {

  return fetcher(
    `/api/v2/admin/company/${companyId}/notes`,
    {
      query: {userId},
      method: 'PUT',
      body: data,
    },
  );
};