import {Colors} from 'appConstants';
import {Button} from 'components/buttons/Button';
import PropTypes from 'prop-types';
import React from 'react';

export const LinkTextButton = (props) => {

  return(
    <a
      style={{textDecoration: 'none'}}
      href={props.link}
    >
      <Button
        {...props}
        css={`
          background: none;
          padding: unset;
          border-bottom: 1px solid ${Colors.TRANSPARENT.TRANSPARENT};
          &:hover {
            text-decoration: underline;
            text-decoration-color: ${Colors.GREEN.LIGHT};
          }
          
          ${props.css};
        `}
        textCss={`
          color: ${props.disabled ? Colors.GREY.LIGHT : Colors.WHITE};
          ${props.textCss};
        `}
        onClick={() => {}}
      />
    </a>
  );
};

LinkTextButton.propTypes = {
  css: PropTypes.string,
  disabled: PropTypes.bool,
  link: PropTypes.string,
  text: PropTypes.string,
  textCss: PropTypes.string,
};