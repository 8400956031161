import {Colors} from 'appConstants';
import {Container} from 'components/containers';
import React from 'react';
import styled from 'styled-components';

const standardSize = 30;
const thickness = 1;


const EmptySquare = styled(Container)`
  max-width: ${standardSize}px;
  min-width: ${standardSize}px;
  max-height: ${standardSize}px;
  min-height: ${standardSize}px;
  flex: 1;
`;

const borderShapes = {
  left: {
    empty: <EmptySquare key={'leftEmpty'} />,
    top: (
      <Container
        key={'leftTop'}
        css={`
          max-width: ${standardSize}px;
          max-height: ${standardSize}px;
          border-top-left-radius: 100%;
          border-top: ${thickness}px dashed ${Colors.GREY.TRANS_LIGHT};
          border-left: ${thickness}px dashed ${Colors.GREY.TRANS_LIGHT};
          flex: 1;
        `}
      />),
    wall: (
      <Container
        key={'leftWall'}
        css={`
          border-left: ${thickness}px dashed ${Colors.GREY.TRANS_LIGHT};
          flex: 1;
          flex-grow: 0;
          min-width: ${standardSize}px;
      `}
      />),
    bottom: (
      <Container
        key={'leftBottom'}
        css={`
          border-bottom-left-radius: 100%;
          max-width: ${standardSize}px;
          max-height: ${standardSize}px;
          border-left: ${thickness}px dashed ${Colors.GREY.TRANS_LIGHT};
          border-bottom: ${thickness}px dashed ${Colors.GREY.TRANS_LIGHT};
          flex: 1;
        `}
      />),
  },
  middle: {
    top: (
      <Container
        key={'middleTop'}
        css={`
          border-top: ${thickness}px dashed ${Colors.GREY.TRANS_LIGHT};
          flex: 1;
        `}
      />),
    empty: (
      <Container
        key={'middleEmpty'}
        css={`
          flex: 1;
        `}
      />),
  },
  right: {
    top: (
      <Container
        key={'rightTop'}
        css={`
          border-top-right-radius: 100%;
          border-top: ${thickness}px dashed ${Colors.GREY.TRANS_LIGHT};
          border-right: ${thickness}px dashed ${Colors.GREY.TRANS_LIGHT};
          max-width: ${standardSize}px;
          max-height: ${standardSize}px;
          flex: 1;
        `}
      />),
    wall: (
      <Container
        key={'rightWall'}
        css={`
          border-right: ${thickness}px dashed ${Colors.GREY.TRANS_LIGHT};
          flex: 1;
          flex-grow: 0;
          min-width: ${standardSize}px;
      `}
      />),
    bottom: (
      <Container
        key={'rightBottom'}
        css={`
          border-bottom-right-radius: 100%;
          border-bottom: ${thickness}px dashed ${Colors.GREY.TRANS_LIGHT};
          border-right: ${thickness}px dashed ${Colors.GREY.TRANS_LIGHT};
          max-width: ${standardSize}px;
          max-height: ${standardSize}px;
          flex: 1;
        `}
      />),
    empty: <EmptySquare key={'rightEmpty'} />,
  },
};

const patternArray = [
  [
    'empty',
    'top',
    'top',
  ],
  [
    'empty',
    'items',
    'wall',
  ],
  [
    'empty',
    'empty',
    'bottom',
  ],
];

const endArray = [
  [
    'empty',
    'top',
    'empty',
  ],
  [
    'empty',
    'items',
    'empty',
  ],
  [
    'empty',
    'empty',
    'empty',
  ],
];

export const StatusTracker = (props) => {

  let currentItemNumber = 0;
  const width = props.width;
  const numberOfItems = props.components?.length;
  let patternCnt = 0;

  const builtComponent = [];

  const addRow = (patternItems) => {

    for (let i = 0; i<patternItems.length; i++) {

      const arr = patternItems[i];

      const builtRow = [];

      //Left border:
      builtRow.push(borderShapes.left[arr[patternCnt%2 === 0 ? 0 : 2]]);

      //actual items:
      if (arr[1] === 'items') {

        //if it's an actual item:
        const insertItemArray = [];

        for (let i = 0; (i < width && currentItemNumber < props.components.length); i++) {

          insertItemArray.push(props.components[currentItemNumber]);
          currentItemNumber++;
        }

        builtRow.push(
          <Container
            key={`items-${currentItemNumber}`}
            css={`
              flex: 1;
              flex-direction: row;
              justify-content: space-evenly;
            `}
          >
            {insertItemArray}
          </Container>,
        );

      } else {

        //if its empty or a top
        builtRow.push(borderShapes.middle[arr[1]]);
      }

      //Right border:
      builtRow.push(borderShapes.right[arr[patternCnt%2 === 0 ? 2 : 0]]);

      //Add row to full component:
      builtComponent.push(
        <Container
          key={`row-${i}-${currentItemNumber}`}
          css={`
            flex-direction: row;
            width: 100%;
            margin-top: -1px;
          `}
        >
          {builtRow}
        </Container>,
      );
    }
  };

  if (props.components) {

    //Do all items except the last row
    while(currentItemNumber < (numberOfItems-width)) {

      addRow(patternArray);
      patternCnt++;
    }
    //Last row is built using different params
    addRow(endArray);
  }

  return (
    <Container
      css={`
        padding-right: 16px;
      `}
    >
      {builtComponent}
    </Container>
  );
};