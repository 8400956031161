import {Colors} from 'appConstants';
import {Text} from 'components/general';
import React from 'react';
export const NotificationContext = React.createContext({});

export const NotificationProvider = (props) => {

  const [state, setState] = React.useState(false);

  const updateState = (val, time = 4000) => {

    if (typeof val === 'string') {

      val = (
        <Text
          css={`
            background: ${Colors.GREEN.DARK};
            padding: 32px;
            border-radius: 8px;
            border: 4px solid ${Colors.GREEN.LIGHT};
            text-align: center;
          `}
          text={val}
        />
      );
    }
    setState(val);
    setTimeout(() => {
      setState(null);
    }, time);
  };

  const setError = (text, time = 4000) => {

    if (typeof text !== 'string') {

      console.error('Unable to set notification error for non string values');
      return;
    }

    if (typeof time !== 'number') {

      console.error('Unable to set notification error timer for non number values');
      return;
    }
    
    updateState(
      <Text
        text={text}
        css={`
          background: ${Colors.TRANSPARENT.ORANGE};
          padding: 32px;
          border-radius: 8px;
          border: 4px solid ${Colors.RED};
          text-align: center;
        `}
      />,
      time,
    );
  };

  const setMessage = (text, time = 4000) => {

    if (typeof text !== 'string') {

      console.error('Unable to set notification error for non string values');
      return;
    }

    if (typeof time !== 'number') {

      console.error('Unable to set notification error timer for non number values');
      return;
    }

    updateState(
      <Text
        text={text}
        css={`
          background: ${Colors.TRANSPARENT.GREEN.DARK};
          padding: 32px;
          border-radius: 8px;
          border: 4px solid ${Colors.GREEN.LIGHT};
          text-align: center;
        `}
      />,
      time,
    );
  };

  const clear = () => {
    setState(null);
  };

  return (
    <NotificationContext.Provider
      displayName={'Notification Context'}
      value={{
        state,
        setError,
        setMessage,
        clear,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};
