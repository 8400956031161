import {calculateFieldArea} from '@orizonagriculture/orizonutils';
import {
  Colors,
  TextStyles,
} from 'appConstants';
import {
  Accordion,
  Button,
  Container,
  HorizontalContainer,
  Map,
  Text,
} from 'components';
import {
  extractPolygon,
  generateShapes,
} from 'components/general/maps/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {FeatureGroup} from 'react-leaflet';
import { GeomanControls } from 'react-leaflet-geoman-v2';

export const MapFieldsDrawNew = (props) => {

  const [fieldPolygons, setFieldPolygons] = React.useState( {});
  const controlsRef = React.useRef();

  const back = () => {
    props.back();
  };

  const complete = () => {
    return props.onComplete(fieldPolygons);
  };

  const onShapeChange = (e) => {

    if (e.type === 'pm:create') {

      const layer = e.layer;
      const shape = extractPolygon({layer});

      if (shape) {

        setFieldPolygons((prev) => {

          return {
            ...prev,
            [layer._leaflet_id]: shape,
          };
        });
      }

      return;
    }

    const layers = controlsRef.current?.getLayers();

    const currentDrawn = {};

    if (layers) {

      layers.forEach((layer) => {

        const id = layer.options.key;
        const shape = extractPolygon({layer});

        if (shape) {
          currentDrawn[id] = shape;
        }
      });

      setFieldPolygons(currentDrawn);
    }
  };

  const onShapeDelete = (e) => {

    if (e.layers?._layers) {

      setFieldPolygons((prev) => {

        Object.keys(e.layers?._layers).forEach((key) => {
          delete prev[key];
        });
        return {...prev};
      });
    }

  };

  const polygons = generateShapes(props.initialPolygons, {pmIgnore: false}, fieldPolygons);

  const mapFeatures = (
    <FeatureGroup ref={controlsRef}>

      <GeomanControls
        options={{
          position: 'topleft',
          drawText: false,
          drawCircle: !props.isEditable,
          drawPolygon: !props.isEditable,
          drawCircleMarker: false,
          drawPolyline: false,
          drawMarker: false,
          drawRectangle: false,
          editMode: props.isEditable,
          dragMode: false,
          cutPolygon: props.canCut,
          rotateMode: false,
          removalMode: props.isEditable,
        }}
        globalOptions={{
          continueDrawing: false,
          allowSelfIntersection: false,
        }}
        onCreate={onShapeChange}
        onUpdate={onShapeChange}
        onMapCut={onShapeChange}
        onDelete={onShapeDelete}
      />

      {polygons}
      
    </FeatureGroup>
  );

  const numberFields = Object.keys(fieldPolygons).length;
  let area;
  if (Object.keys(fieldPolygons).length === 1) {

    area = calculateFieldArea({
      coords: Object.values(fieldPolygons)?.[0]?.coords,
      radius: Object.values(fieldPolygons)?.[0]?.radius,
    });
  }

  return (
    <Container>

      <Text
        text={'Map your field'}
        css={`
          ${TextStyles.HEADING};
        `}
      />

      <Accordion
        title={'Need help?'}
        css={`
          background: ${Colors.TRANSPARENT.WHITE};
          margin: 8px 0;
          padding: 8px;
        `}
      >
        <Text
          text={'\n - To change between "Cities & Towns" and "Satellite" views, select the icon in the top right hand corner \n \n - To map a circular field (eg. a pivot field) click the circle button in the bottom right hand corner. Then click the center of your field and drag outwards to the end of the field. \n \n - To map a field (with straight lines) click the pentagon button in the bottom right hand corner and mark your field boundary points. When completed click the "Finish" button.\n \n - To edit a mapped field click the pencil icon in the bottom right hand corner, when done, click save \n \n - To delete a mapped field, click the bin icon in the bottom right hand corner, click the field to be deleted, then click the save button \n \n - After mapping your field, click the "Continue" button at the bottom of the page \n'}
        />

        <Text
          text={'Please Note, fields from uploaded .kml files are not displayed here, they do not need to be mapped'}
          css={`
            padding-top: 16px;
          `}
        />

      </Accordion>


      {area &&
        <Text
          text={`This field is approximately ${area} hectares`}
        />
      }

      <Map
        mapExtras={mapFeatures}
        initialLocation={props.initialLocation}
        shapes={props.staticPolygons}
      />

      <HorizontalContainer
        css={`
          width: 100%;
          margin: 16px 0;
          justify-content: space-between;
        `}
      >

        <Button
          css={`
            margin: 0;
          `}
          text={'Back'}
          onClick={back}
        />

        <Button
          css={`
            margin: 0;
          `}
          text={'Continue'}
          disabled={numberFields === 0}
          onClick={complete}
        />

      </HorizontalContainer>

    </Container>
  );
};

MapFieldsDrawNew.propTypes = {
  back: PropTypes.func,
  canCut: PropTypes.bool,
  initialPolygons: PropTypes.array,
  isEditable: PropTypes.bool,
  onComplete: PropTypes.func,
};
