import React from 'react';

export const CompanyContext = React.createContext({
  activeCompany: {},
  setActiveCompany: () => {},
  userCompanies: [],
  setUserCompanies: () => {},
});

export const CompanyContextProvider = (props) => {

  const [activeCompany, setActiveCompany] = React.useState({});
  const [userCompanies, setUserCompanies] = React.useState([]);

  const setCompanies = (companies) => {

    setUserCompanies(companies);

    if(activeCompany.id) {

      const updatedCompany = companies.find((co) => co.id === activeCompany.id);
      setActiveCompany(updatedCompany);
      return;
    }

    setActiveCompany(companies[0]);
  };

  return (
    <CompanyContext.Provider
      value={{
        activeCompany,
        setActiveCompany,
        userCompanies,
        setUserCompanies: setCompanies,
      }}
      displayName={'Organisation context'}
    >
      {props.children}
    </CompanyContext.Provider>
  );
};